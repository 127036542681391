// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root {\n  /*\n   * Typography\n   * ======================================================================== */\n\n  --font-family-base: 'jaf-domus', sans-serif;\n  --notifications-bubble-font-size: var(--whitelabeling-notifications-bubble-font-size);\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  --max-content-width: 1000px;\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */\n\n  --screen-xs-min: 480px; /* Extra small screen / phone */\n  --screen-sm-min: 768px; /* Small screen / tablet */\n  --screen-md-min: 992px; /* Medium screen / desktop */\n  --screen-lg-min: 1200px; /* Large screen / wide desktop */\n\n  --primary-color: var(--whitelabeling-primary-color);\n  --primary-dark-color: var(--whitelabeling-primary-dark-color);\n  --primary-light-color: var(--whitelabeling-primary-light-color);\n\n  --secondary-color: var(--whitelabeling-secondary-color);\n  --secondary-light-color: var(--whitelabeling-secondary-light-color);\n\n  --main-header-color: var(--whitelabeling-main-header-color);\n\n  --white-color: #fff;\n  --white-smoke-color: #f6f6f7;\n  --black-color: #000;\n  --error-color: #f00;\n  --grey-color: #cac5cf;\n  --grey-light-color: #ebebeb;\n\n  --field-disabled-background: var(--whitelabeling-field-disabled-background);\n  --field-disabled-color: var(--whitelabeling-field-disabled-color);\n\n  --field-error-background: var(--whitelabeling-field-error-background);\n\n  --light-modal-bg: var(--whitelabeling-panel-color);\n\n  --green-color: #007b80;\n}\n\n.Z2TjQ {\n  padding-left: 20px;\n  padding-right: 20px;\n}\n\n.bd1bM {\n  margin: 0 auto;\n  max-width: 1000px;\n  max-width: var(--max-content-width);\n  padding: 0 0 40px;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Z2TjQ",
	"container": "bd1bM"
};
module.exports = ___CSS_LOADER_EXPORT___;
