/* eslint-disable import/prefer-default-export */

import {
  CHANGE_PENDING_TAGS,
  CHANGE_TAGS,
  CHANGE_PENDING_CONTENT_CATEGORIES,
  CHANGE_SEARCH,
  CHANGE_CONTENT_CATEGORIES,
  TOGGLE_NOTIFICATIONS_POPOVER,
  CHANGE_SEARCH_SESSION,
  CHANGE_IS_INITIAL_CONTENT_CATEGORIES_LOAD,
} from '../constants';

export function changeSearchField(search) {
  return {
    type: CHANGE_SEARCH,
    search,
  };
}

export function changeSearchSession(searchSessionId, searchPhrase) {
  return {
    type: CHANGE_SEARCH_SESSION,
    searchSession: {
      _id: searchSessionId,
      searchPhrase,
    },
  };
}

export function changeTagsField(tags) {
  return {
    type: CHANGE_TAGS,
    tags,
  };
}

export function changePendingTagsField(tags) {
  return {
    type: CHANGE_PENDING_TAGS,
    tags,
  };
}

export function changeContentCategoriesField(contentCategories) {
  return {
    type: CHANGE_CONTENT_CATEGORIES,
    contentCategories,
  };
}

export function changePendingContentCategoriesField(contentCategories) {
  return {
    type: CHANGE_PENDING_CONTENT_CATEGORIES,
    contentCategories,
  };
}

export function setIsInitialContentCategoriesLoad(
  isInitialContentCategoriesLoad,
) {
  return {
    type: CHANGE_IS_INITIAL_CONTENT_CATEGORIES_LOAD,
    isInitialContentCategoriesLoad,
  };
}

export function toggleNotificationsPopover(isNotificationsMenuOpened) {
  return {
    type: TOGGLE_NOTIFICATIONS_POPOVER,
    isNotificationsMenuOpened,
  };
}
