import {
  COURSE_DETAILS__GET_COURSE,
  COURSE_DETAILS__GET_COURSE_SUCCESS,
  COURSE_DETAILS__GET_COURSE_FAIL,
  COURSE_DETAILS__RESET_COURSE,
  COURSE_DETAILS__GET_RELATED_COURSES,
  COURSE_DETAILS__GET_RELATED_COURSES_SUCCESS,
  COURSE_DETAILS__GET_RELATED_COURSES_FAIL,
  COURSE_DETAILS__GET_TEXT_NOTES,
  COURSE_DETAILS__GET_TEXT_NOTES_SUCCESS,
  COURSE_DETAILS__GET_TEXT_NOTES_FAIL,
  COURSE_DETAILS__GET_VIDEO_NOTES,
  COURSE_DETAILS__GET_VIDEO_NOTES_SUCCESS,
  COURSE_DETAILS__GET_VIDEO_NOTES_FAIL,
  COURSE_DETAILS__GET_PUBLIC_NOTES_STATE,
  COURSE_DETAILS__GET_PUBLIC_NOTES_STATE_SUCCESS,
  COURSE_DETAILS__GET_PUBLIC_NOTES_STATE_FAIL,
  COURSE_DETAILS__TOGGLE_LIVESTREAM_CHAT,
  COURSE_DETAILS__RESET_COURSE_PROGRESS,
} from '../constants';

const initialState = {
  loading: false,
  course: {},
  relatedCourses: [],
  loadingRelatedCourses: false,
  totalRelatedCourses: 0,
  videoNotes: [],
  loadingVideoNotes: false,
  textNotes: [],
  loadingTextNotes: false,
  pubicNotesStatus: {},
  loadingPubicNotesStatus: false,
  isLiveStreamChatShown: false,
  courseWasReset: false,
};

export default function courseDetails(state = initialState, action) {
  switch (action.type) {
    case COURSE_DETAILS__GET_COURSE:
      return {
        ...state,
        loading: true,
      };
    case COURSE_DETAILS__GET_COURSE_SUCCESS: {
      const course = action.result.json;

      course.studyTimeEstimationMinutes =
        (course.studyTimeEstimation || 0) % 60;
      course.studyTimeEstimationHours =
        ((course.studyTimeEstimation || 0) -
          course.studyTimeEstimationMinutes) /
        60;

      course.timeMinutes = (course.time || 0) % 60;
      course.timeHours = ((course.time || 0) - course.timeMinutes) / 60;

      return {
        ...state,
        course,
        loading: false,
      };
    }
    case COURSE_DETAILS__GET_COURSE_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case COURSE_DETAILS__GET_RELATED_COURSES:
      return {
        ...state,
        loadingRelatedCourses: true,
      };
    case COURSE_DETAILS__GET_RELATED_COURSES_SUCCESS:
      return {
        ...state,
        relatedCourses: action.result.json,
        totalRelatedCourses: action.result.total,
        loadingRelatedCourses: false,
      };
    case COURSE_DETAILS__GET_RELATED_COURSES_FAIL:
      return {
        ...state,
        loadingRelatedCourses: false,
        error: action.error,
      };
    case COURSE_DETAILS__GET_TEXT_NOTES:
      return {
        ...state,
        loadingTextNotes: true,
      };
    case COURSE_DETAILS__GET_TEXT_NOTES_SUCCESS:
      return {
        ...state,
        textNotes: action.result.json,
        loadingTextNotes: false,
      };
    case COURSE_DETAILS__GET_TEXT_NOTES_FAIL:
      return {
        ...state,
        loadingTextNotes: false,
        error: action.error,
      };
    case COURSE_DETAILS__GET_VIDEO_NOTES:
      return {
        ...state,
        loadingVideoNotes: true,
      };
    case COURSE_DETAILS__GET_VIDEO_NOTES_SUCCESS:
      return {
        ...state,
        videoNotes: action.result.json,
        loadingVideoNotes: false,
      };
    case COURSE_DETAILS__GET_VIDEO_NOTES_FAIL:
      return {
        ...state,
        loadingVideoNotes: false,
        error: action.error,
      };
    case COURSE_DETAILS__GET_PUBLIC_NOTES_STATE:
      return {
        ...state,
        loadingPubicNotesStatus: true,
      };
    case COURSE_DETAILS__GET_PUBLIC_NOTES_STATE_SUCCESS:
      return {
        ...state,
        pubicNotesStatus: action.result.json,
        loadingPubicNotesStatus: false,
      };
    case COURSE_DETAILS__GET_PUBLIC_NOTES_STATE_FAIL:
      return {
        ...state,
        loadingPubicNotesStatus: false,
        error: action.error,
      };

    case COURSE_DETAILS__TOGGLE_LIVESTREAM_CHAT:
      return {
        ...state,
        isLiveStreamChatShown: !state.isLiveStreamChatShown,
      };

    case COURSE_DETAILS__RESET_COURSE_PROGRESS: {
      return { ...state, courseWasReset: !state.courseWasReset };
    }

    case COURSE_DETAILS__RESET_COURSE:
      return initialState;
    default:
      return state;
  }
}
