import { defineMessages } from 'react-intl';

export default defineMessages({
  code: {
    id: 'AddToDifferentMainGroupModal.code',
    defaultMessage: 'Code',
  },
  submit: {
    id: 'AddToDifferentMainGroupModal.submit',
    defaultMessage: 'Submit',
  },
  successMessage: {
    id: 'AddToDifferentMainGroupModal.successMessage',
    defaultMessage:
      'Please, check your inbox and confirm adding to another organization',
  },
  successInviteCompletionMessage: {
    id: 'AddToDifferentMainGroupModal.successInviteCompletionMessage',
    defaultMessage:
      'You have successfully joined to new organization, please sign in',
  },
  title: {
    id: 'AddToDifferentMainGroupModal.title',
    defaultMessage: 'Add account to the different organization',
  },
  userTip: {
    id: 'AddToDifferentMainGroupModal.userTip',
    defaultMessage:
      'After submitting the form, you will receive a confirmation email detailing how to finish joining to the desired organization.',
  },
});
