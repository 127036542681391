/* eslint-disable react/prop-types */

import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
// import { fieldInputPropTypes } from 'redux-form';
import withMuiStyles from '@material-ui/core/styles/withStyles';
import withStyles from 'isomorphic-style-loader/withStyles';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';
import Select /* , { Props } */ from 'react-select';
import cx from 'classnames';
// import { OptionProps } from 'react-select/src/components/Option';
// import { ControlProps } from 'react-select/src/components/Control';
// import { ValueProps } from 'react-select/src/components/SingleValue';
// import { MultiValueProps } from 'react-select/src/components/MultiValue';
// import { IndicatorProps } from 'react-select/src/components/indicators';
// import { InputProps as SelectInputProps } from 'react-select/src/components/Input';
// import { PlaceholderProps } from 'react-select/src/components/Placeholder';
// import { ValueContainerProps } from 'react-select/src/components/containers';

import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';

import SkholeIcon42 from '../statelessComponents/Icons/SkholeIcon42';

import s from './Autocomplete.css';

const messages = defineMessages({
  noResultFound: {
    id: 'Autocomplete.noResultFound',
    defaultMessage: 'No results found',
    description: 'No results found displayed in autocomplete form',
  },
  selectLabel: {
    id: 'Autocomplete.selectLabel',
    defaultMessage: 'Select ...',
    description: 'Select ... displayed in autocomplete form to select item',
  },
  itemsLabel: {
    id: 'Autocomplete.itemsLabel',
    defaultMessage: ' ({items} items)',
    description:
      '"Items" label displayed in  autocomplete form right after amount of total items',
  },
  startTypingNameHere: {
    id: 'Autocomplete.startTypingNameHere',
    defaultMessage: 'Start typing name here...',
    description:
      '"Start typing name here" label displayed in  autocomplete as default placeholder',
  },
});

export function Option(props) {
  return (
    <Tooltip
      title={props.data.tooltip || props.children}
      classes={{ tooltip: s.tooltip }}
    >
      <MenuItem
        buttonRef={props.innerRef}
        selected={props.isFocused}
        component="div"
        style={{
          fontWeight: props.isSelected ? 500 : 400,
          // ...props.getStyles('option', props),
        }}
        {...props.innerProps}
        className={cx(props.innerProps.className, 'react-select__option')}
        disabled={props.data.disabled}
      >
        <Typography noWrap>{props.children}</Typography>
      </MenuItem>
    </Tooltip>
  );
}
Option.propTypes = {
  // ...OptionProps,
};

export function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}
inputComponent.propTypes = {
  // ...SelectInputProps,
};

export const DropdownIndicator = withStyles(s)(() => (
  <>
    <SkholeIcon42 className={s.selectIcon} />
    {/* <div className={s.selectIcon} {...props.innerProps} /> */}
  </>
));
DropdownIndicator.propTypes = {
  // ...IndicatorProps,
};

export function Control(props) {
  return (
    <TextField
      fullWidth
      {...props.selectProps.textFieldProps}
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
          ...props.selectProps.inputProps,
        },
        ...props.selectProps.InputProps,
      }}
    />
  );
}
Control.propTypes = {
  // ...ControlProps,
};

export function Placeholder(props) {
  return (
    <Typography
      // className={props.selectProps.classes.placeholder}
      noWrap
      style={props.getStyles('placeholder', props)}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}
Placeholder.propTypes = {
  // ...PlaceholderProps,
};

export function SingleValue(props) {
  return (
    <Typography
      // className={props.selectProps.classes.singleValue}
      style={props.getStyles('singleValue', props)}
      noWrap
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}
SingleValue.propTypes = {
  // ...ValueProps,
};

export function ValueContainer(props) {
  return (
    <div
    // style={props.getStyles('valueContainer', props)}
    // className={props.selectProps.classes.valueContainer}
    >
      {props.children}
    </div>
  );
}
ValueContainer.propTypes = {
  // ...ValueContainerProps,
};

export function MultiValue(props) {
  let onClick;
  let tooltip;
  let avatar = _.get(props.selectProps, 'multiValueProps.avatar');

  if (_.get(props.selectProps, 'multiValueProps.onClick')) {
    onClick = () => props.selectProps.multiValueProps.onClick(props.data);
  }

  if (_.get(props.selectProps, 'multiValueProps.getTooltip')) {
    tooltip = props.selectProps.multiValueProps.getTooltip(props.data);
  }

  if (_.get(props.selectProps, 'multiValueProps.getAvatar')) {
    avatar = props.selectProps.multiValueProps.getAvatar(props.data);
  }

  const onDelete = event => {
    props.removeProps.onClick();
    props.removeProps.onMouseDown(event);
  };

  const chip = (
    <Chip
      tabIndex={-1}
      label={
        props.selectProps.getChipValue
          ? props.selectProps.getChipValue(props.data)
          : props.children
      }
      className={cx(props.selectProps.classes.chip, 'chip')}
      onDelete={onDelete}
      deleteIcon={
        props.selectProps.customDeleteIcon ? (
          <CloseIcon
            className={props.selectProps.deleteIconClass}
            style={props.getStyles('multiValueRemove', props)}
            onTouchEnd={event => {
              if (!props.disabled) {
                event.stopPropagation();
                onDelete(event);
              }
            }}
          />
        ) : (
          <CancelIcon style={props.getStyles('multiValueRemove', props)} />
        )
      }
      style={props.getStyles('multiValue', props)}
      {..._.get(props.selectProps, 'multiValueProps', {})}
      onClick={onClick || onDelete}
      onTouchEnd={onClick || onDelete}
      avatar={avatar}
    />
  );

  if (!tooltip) {
    return chip;
  }

  return <Tooltip title={tooltip}>{chip}</Tooltip>;
}
MultiValue.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  // ...MultiValueProps,
};

export function Menu(props) {
  return (
    <Paper
      square
      className={cx(props.selectProps.classes.paper, 'react-select__menu')}
      // style={props.getStyles('menu', props)}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}
Menu.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

export function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}
NoOptionsMessage.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

export const getPrimaryAutocompleteStyles = whitelabeling => ({
  input: base => ({
    ...base,
    color: whitelabeling.primaryColor,
  }),
  menu: base => ({
    ...base,
    borderRadius: 0,
  }),
  placeholder: base => ({
    ...base,
    color: whitelabeling.primaryColor,
  }),
  singleValue: (base, { isDisabled }) => ({
    ...base,
    color: isDisabled
      ? whitelabeling.fieldDisabledColor
      : whitelabeling.primaryColor,
  }),
  valueContainer: base => ({
    ...base,
    color: whitelabeling.primaryColor,
  }),
  clearIndicator: base => ({
    ...base,
    padding: '4px 2px 4px 8px',
  }),
  option: () => ({}),
  multiValue: () => ({}),
  multiValueLabel: () => ({}),
  multiValueRemove: () => ({}),
});

export const getTableFilterAutocompleteStyles = whitelabeling => ({
  input: base => ({
    ...base,
    color: whitelabeling.primaryColor,
  }),
  menu: base => ({
    ...base,
    borderRadius: 0,
  }),
  singleValue: base => ({
    ...base,
    color: whitelabeling.primaryColor,
  }),
  placeholder: base => ({
    ...base,
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.54)',
    transform: 'none',
    marginTop: 3,
    position: 'static',
  }),
  valueContainer: base => ({
    ...base,
    color: whitelabeling.primaryColor,
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
  }),
  clearIndicator: base => ({
    ...base,
    padding: '4px 2px 4px 8px',
  }),
  option: () => ({}),
});

export const styles = theme => ({
  root: {
    flexGrow: 1,
    maxHeight: 250,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '40px',
    },
    position: 'relative',
  },
  chip: {
    margin: theme.spacing(1 / 4),
    maxWidth: '98%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row-reverse',
      '& > span': {
        display: 'initial',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingLeft: '0',
        paddingRight: '6px',
      },
      '& > svg': {
        margin: '0',
      },
    },
  },
  input: {
    display: 'flex',
    padding: 0,

    '& > div': {
      display: 'flex',
      flexWrap: 'wrap',
    },
    '& > div:first-child': {
      width: '100%',
      maxWidth: 'calc(100% - 35px)',
      position: 'relative',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      '& > p': {
        flex: 1,
      },
    },
    '& > div:last-child': {
      position: 'absolute',
      zIndex: 1,
      right: 0,
      top: 'calc(100% - 32.5px)',
      paddingTop: '4px',
      minHeight: '30px',
    },
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    marginTop: theme.spacing(1),
    zIndex: 5,
    position: 'absolute',
    width: '100%',
  },
  divider: {
    height: theme.spacing(2),
  },
});

const noOptionsMessage = () => <FormattedMessage {...messages.noResultFound} />;

@withMuiStyles(styles)
@injectIntl
export default class Autocomplete extends React.Component {
  // static propTypes = {
  //   changeSearchField: PropTypes.func,
  //   value: PropTypes.shape({}),
  //   styles: PropTypes.shape({}),
  //   margin: PropTypes.oneOf('none', 'normal'),
  //   placeholder: PropTypes.oneOfType(PropTypes.node, PropTypes.string),
  //   inputPlaceholder: PropTypes.oneOfType(PropTypes.node, PropTypes.string),
  //   components: PropTypes.shape({}),
  //   // ...Props,
  //   ...StyledComponentProps,
  //   ...fieldInputPropTypes,
  // };

  // static defaultProps = {
  //   changeSearchField: () => null,
  //   value: null,
  //   margin: 'none',
  //   placeholder: <FormattedMessage {...messages.selectLabel} />,
  //   inputPlaceholder: '',
  //   styles: {},
  //   components: {},
  // };

  onInputChange = value => {
    if (this.props.changeSearchField) {
      this.props.changeSearchField(value);
    }
    return value;
  };

  render() {
    const {
      classes = {},
      input,
      margin = 'none',
      placeholder = <FormattedMessage {...messages.selectLabel} />,
      inputProps = {},
      InputProps = {},
      inputPlaceholder = '',
      InputLabelProps = {},
      meta: { error, touched },
      helperText = '',
      intl: { formatMessage },
      searchValue = '',
      components = {},
      options = [],
      totalAmount = 0,
      textFieldProps = {},
      ...other
    } = this.props;

    const defaultPlaceholder =
      inputPlaceholder || formatMessage(messages.startTypingNameHere);

    const placeholderVal = `${defaultPlaceholder} ${formatMessage(
      messages.itemsLabel,
      {
        items: totalAmount,
      },
    )}`;

    return (
      <div className={cx(classes.root, 'react-select__root')}>
        <Select
          classes={classes}
          placeholder={placeholderVal}
          noOptionsMessage={noOptionsMessage}
          options={options}
          inputValue={searchValue}
          onInputChange={this.onInputChange}
          value={input.value}
          onChange={value => {
            input.onChange(other?.isMulti ? value ?? [] : value);
            input.onBlur(other?.isMulti ? value ?? [] : value);
          }}
          onBlur={event => event.preventDefault()}
          textFieldProps={{
            label: placeholder,
            helperText: (touched && error) || helperText,
            error: !!(touched && error),
            InputLabelProps: {
              shrink: true,
              ...InputLabelProps,
            },
            margin,
            ...textFieldProps,
          }}
          InputProps={{
            ...InputProps,
          }}
          inputProps={inputProps}
          maxMenuHeight={250}
          components={{
            DropdownIndicator,
            IndicatorSeparator: () => null,
            Option,
            Control,
            NoOptionsMessage,
            Placeholder,
            SingleValue,
            MultiValue,
            ValueContainer,
            Menu,
            ...components,
          }}
          styles={{
            input: base => ({
              ...base,
              color: '#fff',
            }),
            menu: base => ({
              ...base,
              borderRadius: 0,
            }),
            placeholder: base => ({
              ...base,
              color: '#fff',
            }),
            singleValue: base => ({
              ...base,
              color: '#fff',
            }),
            valueContainer: base => ({
              ...base,
              color: '#fff',
            }),
            ...this.props.styles,
          }}
          margin
          {...other}
        />
      </div>
    );
  }
}
