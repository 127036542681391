import {
  GENERATE_OTP,
  GENERATE_OTP_SUCCESS,
  GENERATE_OTP_FAIL,
  RESET_OTP,
} from '../constants';

const initialState = {
  loading: false,
  loaded: false,
  otp: {},
};

export default function subgroups(state = initialState, action) {
  switch (action.type) {
    case GENERATE_OTP:
      return {
        ...state,
        loading: true,
      };
    case GENERATE_OTP_SUCCESS:
      return {
        ...state,
        otp: action.result.json,
        loading: false,
        loaded: true,
      };
    case GENERATE_OTP_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case RESET_OTP:
      return initialState;
    default:
      return state;
  }
}
