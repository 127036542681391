import { defineMessages } from 'react-intl';

export default defineMessages({
  onWindowRedirectWarningMessage: {
    id: 'General.unsavedFormLeave',
    defaultMessage: 'Continue ? Changes you made may not be saved.',
    description:
      'the message displayed to users when they trying to leave the page with unsaved changes',
  },
});
