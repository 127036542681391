import {
  RESET_LESSON,
  GET_LESSON,
  GET_LESSON_SUCCESS,
  GET_LESSON_FAIL,
  UPDATE_LESSON_UPLOADING_ATTACHMENT,
  EDIT_LESSON__SET_OPENED_EDITOR,
  EDIT_LESSON__GET_OPENED_EDITOR,
  EDIT_LESSON__GET_OPENED_EDITOR_SUCCESS,
  EDIT_LESSON__GET_OPENED_EDITOR_FAIL,
  EDIT_LESSON_TAKE_EDIT_CONTROL,
  EDIT_LESSON_TAKE_EDIT_CONTROL_SUCCESS,
  EDIT_LESSON_TAKE_EDIT_CONTROL_FAIL,
} from '../constants';

const initialState = {
  lesson: {},
  loading: false,
  uploadingAttachment: 0,
  openedEditor: {},
  loadingOpenedEditor: false,
  error: '',
  takingControl: false,
  takenControl: false,
  takingControlError: '',
};

export default function editLesson(state = initialState, action) {
  switch (action.type) {
    case GET_LESSON:
      return {
        ...state,
        loading: true,
      };
    case GET_LESSON_SUCCESS:
      return {
        ...state,
        lesson: action.result.json,
        loading: false,
        loadingOpenedEditor: true,
      };
    case GET_LESSON_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case UPDATE_LESSON_UPLOADING_ATTACHMENT:
      return {
        ...state,
        uploadingAttachment: action.uploadingAttachment,
      };
    case EDIT_LESSON__GET_OPENED_EDITOR:
      return {
        ...state,
        loadingOpenedEditor: true,
        error: '',
      };

    case EDIT_LESSON__GET_OPENED_EDITOR_SUCCESS:
      return {
        ...state,
        loadingOpenedEditor: false,
        openedEditor: action.result.status < 400 ? action.result.json : {},
      };

    case EDIT_LESSON__GET_OPENED_EDITOR_FAIL:
      return {
        ...state,
        loadingOpenedEditor: false,
        error: action.error,
      };

    case EDIT_LESSON__SET_OPENED_EDITOR:
      return {
        ...state,
        openedEditor: action.openedEditor,
        takenControl: false,
        takingControl: false,
        takingControlError: '',
      };

    case EDIT_LESSON_TAKE_EDIT_CONTROL:
      return {
        ...state,
        takingControl: true,
        takenControl: false,
        takingControlError: '',
      };

    case EDIT_LESSON_TAKE_EDIT_CONTROL_SUCCESS:
      return {
        ...state,
        takingControl: false,
        takenControl: true,
      };

    case EDIT_LESSON_TAKE_EDIT_CONTROL_FAIL:
      return {
        ...state,
        takingControl: false,
        takingControlError: action.error,
      };
    case RESET_LESSON:
      return initialState;
    default:
      return state;
  }
}
