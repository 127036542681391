import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import { Cookies } from 'react-cookie';
import _ from 'lodash';
import { Snackbar } from '@material-ui/core';
import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';
import moment from 'moment';
import messages from './messages';
import s from './MultipleOrganizationsModal.scss';
import { ModalCloseBtn } from '../statelessComponents/Buttons/ButtonsInModals';
import { getOrganizationAvatar } from '../../helpers';
import isLoggedIn from '../../helpers/checkAuth';

@withStyles(s)
export default class MultipleOrganizationsModal extends React.Component {
  static propTypes = {
    multipleAccountOrganizations: PropTypes.arrayOf({}).isRequired,
    onClose: PropTypes.func.isRequired,
    cookies: PropTypes.instanceOf(Cookies).isRequired,
    locale: PropTypes.string.isRequired,
    auth: PropTypes.object, // eslint-disable-line
  };

  static contextTypes = {
    cloudfrontUrl: PropTypes.string,
    fetch: PropTypes.func,
    cookiePrefix: PropTypes.string,
  };

  state = { chooseOrgError: '' };

  onOrganizationClick = accountId => {
    const { cookies, locale } = this.props;
    this.setState({ chooseOrgError: '' });
    this.context
      .fetch(`/api/accounts/${accountId}/switch-organization`, {
        method: 'POST',
      })
      .then(res =>
        res.json().then(token => {
          if (!res.ok) {
            this.setState({ chooseOrgError: token.message });
            return;
          }
          cookies.remove(`${this.context.cookiePrefix}chooseMainGroupToken`, {
            path: '/',
            domain: window.App.cookieDomain,
          });
          cookies.set(`${this.context.cookiePrefix}token`, token.token, {
            path: '/',
            domain: window.App.cookieDomain,
            maxAge: token.maxAge,
          });
          window.location = `/${locale}`;
        }),
      );
  };

  handleCloseErrorSnackbar = () => {
    this.setState({ chooseOrgError: '' });
  };

  render() {
    const { multipleAccountOrganizations, onClose, auth } = this.props;
    const { chooseOrgError } = this.state;
    let modalContent;
    if (!multipleAccountOrganizations || !multipleAccountOrganizations.length) {
      modalContent = (
        <div>
          <Typography variant="h5" component="h1" gutterBottom>
            <FormattedMessage {...messages.noAccounts} />
          </Typography>
        </div>
      );
    } else {
      modalContent = (
        <>
          <Typography variant="h5" component="h1" gutterBottom>
            {!isLoggedIn(auth) ? (
              <FormattedMessage
                {...messages.titleMultipleOrganization}
                values={{
                  firstName: _.get(
                    multipleAccountOrganizations,
                    '[0].firstName',
                  ),
                }}
              />
            ) : (
              <FormattedMessage {...messages.organizationWorkspacesTitle} />
            )}
          </Typography>
          <List className={s.orgsList}>
            {multipleAccountOrganizations.map(account => {
              const isAddToNewOrganizationConfirmationRequired =
                !account.activated && account.addToNewOrganizationToken;

              const isInvitationConfirmationToOtherOrganizationRequired =
                !account.activated && account.invitationToken;

              const logoUrl = getOrganizationAvatar(
                account.mainGroup,
                this.context.cloudfrontUrl,
              );

              const isSelected =
                isLoggedIn(auth) && account._id === auth.user._id;

              const isOrganizationDisabled =
                isAddToNewOrganizationConfirmationRequired ||
                isInvitationConfirmationToOtherOrganizationRequired ||
                account.removed;

              return (
                <div className={s.orgItemWrapper}>
                  <ListItem
                    className={
                      !isOrganizationDisabled
                        ? s.orgListItemActive
                        : s.orgListItemDisable
                    }
                    disabled={isOrganizationDisabled}
                    button
                    onClick={() =>
                      isSelected
                        ? _.noop()
                        : this.onOrganizationClick(account._id)
                    }
                    selected={isSelected}
                  >
                    <img
                      src={logoUrl}
                      alt={_.get(account, 'mainGroup.name')}
                      className={s.orgLogo}
                    />
                    <ListItemText
                      primary={_.get(account, 'mainGroup.name')}
                      classes={{
                        root: s.orgTitleTextWrapper,
                        primary: s.orgTitleTextPrimary,
                      }}
                    />
                    <Typography
                      valiant="subtitle1"
                      gutterBottom
                      className={s.orgListItemMessage}
                    >
                      {isAddToNewOrganizationConfirmationRequired && (
                        <>
                          <FormattedMessage
                            {...messages.signupMultipleOrganizationConfirmationMsg}
                          />
                          <br />
                          <FormattedMessage
                            {...messages.signupMultipleOrganizationConfirmationTip}
                          />
                        </>
                      )}
                      {isInvitationConfirmationToOtherOrganizationRequired && (
                        <FormattedMessage
                          {...messages.signupMultMainGroupInvitationErr}
                        />
                      )}
                      {!!account.removed && !account.mainGroup?.trial && (
                        <FormattedMessage
                          {...messages.accountIsArchivedMessage}
                          values={{
                            mainGroupName: _.get(account, 'mainGroup.name'),
                          }}
                        />
                      )}
                      {!!account.removed && account.mainGroup?.trial && (
                        <FormattedMessage
                          {...messages.trialAccountIsArchivedMessage}
                          values={{
                            startTrialDate: moment(account.createDate).format(
                              'YYYY-MM-DD',
                            ),
                            endTrialDate: moment(account.removed).format(
                              'YYYY-MM-DD',
                            ),
                          }}
                        />
                      )}
                    </Typography>
                  </ListItem>
                </div>
              );
            })}
          </List>
          <Snackbar
            open={!!chooseOrgError}
            onClose={this.handleCloseErrorSnackbar}
            autoHideDuration={3000}
            message={chooseOrgError}
          />
        </>
      );
    }
    return (
      <div className={s.orgsContainer}>
        {modalContent}
        <ModalCloseBtn
          fitMobileLandscapeFullWidth
          id="choose-organization-modal-close-btn"
          data-test="choose-organization-modal-close-btn"
          onClick={onClose}
        />
      </div>
    );
  }
}
