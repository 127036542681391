import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import withStyles from 'isomorphic-style-loader/withStyles';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';
import {
  Field,
  reduxForm,
  SubmissionError,
  propTypes as reduxFormPropTypes,
} from 'redux-form';
import { connect } from 'react-redux';
import MUICheckbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import MenuItem from '@material-ui/core/MenuItem';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import { withCookies, Cookies } from 'react-cookie';

import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import * as tourActions from '../../actions/tours';
import RequestChangeMainGroup from '../AddToDifferentMainGroupModal/AddToDifferentMainGroupModal';
import HelpButton from '../statelessComponents/Buttons/HelpButton';
import TextField from '../statelessComponents/FormFields/TextField';
import SkholeIcon42 from '../statelessComponents/Icons/SkholeIcon42';
import TooltipButton from '../statelessComponents/Tooltip/TooltipButton';
import Tour from './Tour';

import s from './Register.scss';
import textFieldStyles from '../styles/TextField.scss';
import modalStyles from '../styles/Modal.scss';
import dropdownStyles from '../styles/DropDown.scss';
import validate from './registerValidation';
import asyncValidate from './asyncEmailValidation';

import messages from './registerMessages';

const IconComponent = () => (
  <SkholeIcon42 className={dropdownStyles.selectIcon} />
);

@withCookies
@withStyles(s)
@withStyles(textFieldStyles)
@withStyles(modalStyles)
@withStyles(dropdownStyles)
@injectIntl
@connect(
  state => ({
    initialValues: state.registration.data,
    user: state.auth.user,
  }),
  dispatch =>
    bindActionCreators(
      {
        toggleTour: tourActions.toggleTour,
      },
      dispatch,
    ),
)
@reduxForm({
  form: 'registrationForm',
  enableReinitialize: true,
  validate,
  asyncValidate,
  asyncBlurFields: ['email', 'code'],
})
@connect(state => ({
  form: state.form.registrationForm,
}))
export default class Register extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    openSignIn: PropTypes.func,
    query: PropTypes.object, // eslint-disable-line
    form: PropTypes.object, // eslint-disable-line
    ...reduxFormPropTypes,
    intl: intlShape.isRequired,
    cookies: PropTypes.instanceOf(Cookies),
    integrationPersonIdVerificationEnabled: PropTypes.bool,
  };

  static defaultProps = {
    query: {},
    openSignIn: () => null,
    integrationPersonIdVerificationEnabled: false,
    cookies: new Cookies(),
  };

  static contextTypes = {
    fetch: PropTypes.func,
    organizationSlug: PropTypes.string,
    whitelabeling: PropTypes.shape({}),
  };

  constructor(props) {
    super(props);

    this.state = {
      isSubmitted: false,
      isJoinMainGroupOpen: false,
      isInvitationLoading: false,
      invitationError: '',
      isInvitedMultiOrganizationUser: false,
    };
  }

  componentDidMount() {
    const {
      query,
      change,
      toggleTour,
      membershipIdVerificationEnabled,
    } = this.props;

    const fields = [
      'code',
      'email',
      'firstName',
      'lastName',
      'isHaka',
      'isSport',
      'isOffice365',
      'education',
      'gender',
      'yearBirth',
      'profession',
    ];
    _.each(fields, field => {
      if (_.has(query, field)) {
        change(field, query[field]);
      }
    });

    if (_.get(query, 'token')) {
      this.getInvitedUser(query.token);
    } else if (!membershipIdVerificationEnabled) {
      toggleTour('register', true);
    }
  }

  onYearBirthEntered = menuEl => {
    if (_.get(this.props, 'form.values.yearBirth')) {
      return;
    }

    const el = menuEl.querySelector('[data-value="1985"]');

    if (!el) {
      return;
    }

    menuEl.scrollTop = el.offsetTop; // eslint-disable-line no-param-reassign
  };

  onJoinMainGroupModalClose = closeRegister => {
    const { onClose, openSignIn } = this.props;
    const { isInvitedMultiOrganizationUser } = this.state;

    if (isInvitedMultiOrganizationUser) {
      this.toggleJoinMainGroup(false);
      onClose();
      return openSignIn();
    }

    if (closeRegister) {
      this.toggleJoinMainGroup(false);
      return onClose();
    }
    return this.toggleJoinMainGroup(false);
  };

  getInvitedUser = token => {
    const {
      membershipIdVerificationEnabled,
      change,
      form: { values },
    } = this.props;
    const b2cMembershipId = _.get(values, 'b2cMembershipId');

    this.setState({
      invitationError: '',
      isInvitationLoading: true,
    });

    return this.context
      .fetch(`/api/auth/get-invited-user-by-token/${token}`)
      .then(res =>
        res.json().then(resJson => {
          if (!res.ok) {
            this.setState({
              invitationError: resJson && resJson.message,
            });
          } else {
            _.each(resJson, (value, key) => {
              change(key, value);
            });

            if (
              membershipIdVerificationEnabled &&
              !b2cMembershipId &&
              resJson.code
            ) {
              change('b2cMembershipId', resJson.code);
            }
          }

          const updatedState = {
            isInvitationLoading: false,
          };
          if (resJson.isInMultipleOrganizations) {
            updatedState.isJoinMainGroupOpen = true;
            updatedState.isInvitedMultiOrganizationUser = true;
          }

          this.setState(updatedState);
        }),
      )
      .catch(err => {
        this.setState({
          invitationError: err && err.message,
          isInvitationLoading: false,
        });
      });
  };

  submit = data => {
    const fieldsToOmit = ['privacy', 'confirmPassword'];

    if (data.invitationToken) {
      fieldsToOmit.push('code');
    }

    const preparedData = _.omit(data, fieldsToOmit);

    return this.context
      .fetch('/api/auth/sign-up', {
        method: 'POST',
        body: JSON.stringify(preparedData),
      })
      .catch(err => {
        throw new SubmissionError({ _error: err && err.message });
      })
      .then(res =>
        res.json().then(resJson => {
          if (!res.ok) {
            if (resJson.message) {
              throw new SubmissionError({ _error: resJson.message });
            }
            throw new SubmissionError(resJson);
          }
          return resJson;
        }),
      )
      .then(() => {
        this.setState({ isSubmitted: true });
        const { initialValues } = this.props;

        const isSocialSignUp = !!(
          initialValues &&
          (initialValues.facebookId ||
            initialValues.googleId ||
            _.has(data, 'isOffice365') ||
            data.isSport)
        );

        if (isSocialSignUp) {
          return true;
        }

        return this.logIn(_.pick(data, ['email', 'password']));
      });
  };

  logIn = data => {
    const { cookies, onClose } = this.props;

    cookies.remove(`${this.context.cookiePrefix}token`, {
      path: '/',
      domain: window.App.cookieDomain,
    });

    return this.context
      .fetch('/api/auth/login', {
        method: 'POST',
        body: JSON.stringify(data),
      })
      .catch(err => {
        throw new SubmissionError({ _error: err && err.message });
      })
      .then(res =>
        res.json().then(resJson => {
          if (!res.ok) {
            if (resJson.message) {
              throw new SubmissionError({ _error: resJson.message });
            }
            throw new SubmissionError(resJson);
          }
          return resJson;
        }),
      )
      .then(token => {
        cookies.set(`${this.context.cookiePrefix}token`, token.token, {
          path: '/',
          maxAge: token.maxAge,
          domain: window.App.cookieDomain,
        });

        window.location = window.location.href.split('?')[0];
      })
      .then(() => {
        onClose();
      });
  };

  handleCloseSnackbar = isSocialSignUp => {
    const { onClose, openSignIn } = this.props;
    this.setState({ isSubmitted: false });

    if (isSocialSignUp) {
      onClose();
      openSignIn();
    }
  };

  toggleJoinMainGroup = isJoinMainGroupOpen => {
    this.setState({ isJoinMainGroupOpen });
  };

  renderPrivacy = ({ input, label, secondaryLabel, lang, ...props }) => (
    <div
      className={classNames(s.checkContainer, {
        [s.link]: secondaryLabel,
      })}
    >
      <MUICheckbox
        classes={{
          root: s.checkField,
        }}
        checked={input.value}
        onChange={input.onChange}
        name={input.name}
        {...props}
        id="register-agree-terms-and-conditions-checkbox"
      />
      <div className={s.privacyLinkWrapper}>
        <a
          href={
            _.get(this.context, `whitelabeling.termsUrl.${lang}`) ||
            window.App.privacyUrl[lang]
          }
          htmlFor="privacy"
          target="_blank"
          className={s.privacyLink}
          rel="noreferrer"
        >
          {label}
        </a>
        {secondaryLabel}
        {props.meta.touched && props.meta.error && (
          <span className={s.checkError}>{props.meta.error}</span>
        )}
      </div>
    </div>
  );

  render() {
    const {
      handleSubmit,
      submitting,
      error,
      onClose,
      initialValues,
      intl,
      form: { values, asyncErrors },
      toggleTour,
      membershipIdVerificationEnabled,
      integrationPersonIdVerificationEnabled,
    } = this.props;
    const noOwnTermsUrl =
      _.get(this.context, 'organizationSlug') &&
      !_.get(this.context, `whitelabeling.termsUrl.${intl.locale}`);

    const {
      isSubmitted,
      isJoinMainGroupOpen,
      isInvitationLoading,
      invitationError,
      isInvitedMultiOrganizationUser,
    } = this.state;
    const isSocialSignUp = !!(
      initialValues &&
      (initialValues.facebookId ||
        initialValues.googleId ||
        _.has(values, 'isOffice365') ||
        values.isSport)
    );
    const isInvited = _.get(values, 'invitationToken', false);
    const emailExists = !!_.get(asyncErrors, 'email');

    return (
      <div
        className={classNames(modalStyles.paper, s.registerModal)}
        id="register-modal"
      >
        <Typography variant="h4" component="h1" className={s.lead}>
          <FormattedMessage {...messages.registrationTitle} />
        </Typography>
        <div className={s.invitationLoadingWrapper}>
          {isInvitationLoading && (
            <LinearProgress
              variant="indeterminate"
              classes={{ colorPrimary: s.invitationLoading }}
            />
          )}
        </div>
        <form noValidate>
          <div className={s.fields}>
            <div className={s.row}>
              {!membershipIdVerificationEnabled && (
                <div id="sign-up-code" className={s.codeWrapper}>
                  <Field
                    name="code"
                    type="text"
                    placeholder={intl.formatMessage(messages.codeLabel)}
                    component={TextField}
                    normalize={value =>
                      value &&
                      !isInvited &&
                      value
                        .toUpperCase()
                        .replace(/[^ILWERTYUPASDFGHKZXCVNM2345679]+/g, '')
                        .substr(0, 6)
                    }
                    className={s.fieldCode}
                    classes={{
                      root: s.fieldsGeneral,
                    }}
                    onChange={() => toggleTour('register', false)}
                    InputProps={{
                      classes: {
                        root: textFieldStyles.textField,
                        input: textFieldStyles.textFieldInput,
                        focused: textFieldStyles.textFieldFocused,
                        disabled: textFieldStyles.textFieldDisabled,
                        error: textFieldStyles.textFieldError,
                      },
                    }}
                    margin="dense"
                    disabled={isInvited}
                  />
                </div>
              )}
              {membershipIdVerificationEnabled && (
                <div data-test="membership-id" className={s.codeWrapper}>
                  <Field
                    name="b2cMembershipId"
                    type="text"
                    placeholder={intl.formatMessage(messages.b2cMembershipId)}
                    component={TextField}
                    className={s.fieldCode}
                    classes={{
                      root: s.fieldsGeneral,
                    }}
                    InputProps={{
                      classes: {
                        root: textFieldStyles.textField,
                        input: textFieldStyles.textFieldInput,
                        focused: textFieldStyles.textFieldFocused,
                        disabled: textFieldStyles.textFieldDisabled,
                        error: textFieldStyles.textFieldError,
                      },
                    }}
                    margin="dense"
                    disabled={isInvited}
                  />
                </div>
              )}

              <div className={s.fieldsGeneral}>
                <Field
                  name="email"
                  type="email"
                  placeholder={intl.formatMessage(messages.emailLabel)}
                  component={TextField}
                  InputProps={{
                    margin: 'dense',
                    classes: {
                      root: textFieldStyles.textField,
                      input: textFieldStyles.textFieldInput,
                      focused: textFieldStyles.textFieldFocused,
                      disabled: textFieldStyles.textFieldDisabled,
                      error: textFieldStyles.textFieldError,
                    },
                  }}
                  disabled={isSocialSignUp || isInvited}
                  normalize={value => value && value.toLowerCase()}
                  fullWidth
                />
                {emailExists && !membershipIdVerificationEnabled && (
                  <ButtonBase onClick={() => this.toggleJoinMainGroup(true)}>
                    <Typography variant="button" color="inherit">
                      <FormattedMessage {...messages.joinMainGroup} />
                    </Typography>
                  </ButtonBase>
                )}
                {emailExists && membershipIdVerificationEnabled && (
                  <ButtonBase className={s.alignLeft} disabled>
                    <Typography variant="button" color="inherit">
                      <FormattedMessage {...messages.pleaseContractSupport} />
                    </Typography>
                  </ButtonBase>
                )}
              </div>

              <Field
                name="firstName"
                type="text"
                placeholder={intl.formatMessage(messages.firstNameLabel)}
                component={TextField}
                margin="dense"
                classes={{
                  root: s.fieldsGeneral,
                }}
                InputProps={{
                  classes: {
                    root: textFieldStyles.textField,
                    input: textFieldStyles.textFieldInput,
                    focused: textFieldStyles.textFieldFocused,
                    disabled: textFieldStyles.textFieldDisabled,
                    error: textFieldStyles.textFieldError,
                  },
                }}
              />
              <Field
                name="lastName"
                type="text"
                placeholder={intl.formatMessage(messages.lastNameLabel)}
                component={TextField}
                classes={{
                  root: s.fieldsGeneral,
                }}
                InputProps={{
                  classes: {
                    root: textFieldStyles.textField,
                    input: textFieldStyles.textFieldInput,
                    focused: textFieldStyles.textFieldFocused,
                    disabled: textFieldStyles.textFieldDisabled,
                    error: textFieldStyles.textFieldError,
                  },
                }}
                margin="dense"
              />
              <Field
                name="profession"
                type="text"
                placeholder={
                  _.get(
                    this.context,
                    `whitelabeling.professionInputLabel.${intl.locale}`,
                  ) || intl.formatMessage(messages.professionLabel)
                }
                component={TextField}
                margin="dense"
                classes={{
                  root: s.fieldsGeneral,
                }}
                InputProps={{
                  classes: {
                    root: textFieldStyles.textField,
                    input: textFieldStyles.textFieldInput,
                    focused: textFieldStyles.textFieldFocused,
                    disabled: textFieldStyles.textFieldDisabled,
                    error: textFieldStyles.textFieldError,
                  },
                }}
              />
              <Field
                name="education"
                type="text"
                component={TextField}
                select
                classes={{
                  root: dropdownStyles.dropdownField,
                }}
                SelectProps={{
                  IconComponent,
                  displayEmpty: true,
                  classes: {
                    selectMenu: dropdownStyles.selectMenu,
                  },
                  SelectDisplayProps: {
                    'data-test': 'education-menu',
                  },
                }}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    root: dropdownStyles.formControlInput,
                    input: dropdownStyles.textFieldInput,
                    focused: textFieldStyles.textFieldFocused,
                    disabled: textFieldStyles.textFieldDisabled,
                    error: dropdownStyles.textFieldError,
                  },
                }}
                required
                margin="none"
                data-test="education-dropdown"
              >
                <MenuItem disabled value="">
                  {intl.formatMessage(messages.educationTypeTitle)}
                </MenuItem>
                <MenuItem value="vocational">
                  <FormattedMessage {...messages.schoolLabel} />
                </MenuItem>
                <MenuItem value="realUniversity">
                  <FormattedMessage {...messages.realUniversityLabel} />
                </MenuItem>
                <MenuItem value="university">
                  <FormattedMessage {...messages.universityLabel} />
                </MenuItem>
                <MenuItem value="college">
                  <FormattedMessage {...messages.collegeLabel} />
                </MenuItem>
                <MenuItem value="other">
                  <FormattedMessage {...messages.otherUniversityLabel} />
                </MenuItem>
              </Field>
              <Field
                name="gender"
                type="text"
                component={TextField}
                select
                classes={{
                  root: dropdownStyles.dropdownField,
                }}
                SelectProps={{
                  IconComponent,
                  displayEmpty: true,
                  classes: {
                    // icon: dropdownStyles.selectIcon,
                    selectMenu: dropdownStyles.selectMenu,
                  },
                  SelectDisplayProps: {
                    'data-test': 'gender-menu',
                  },
                }}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    root: dropdownStyles.formControlInput,
                    input: dropdownStyles.textFieldInput,
                    focused: textFieldStyles.textFieldFocused,
                    disabled: textFieldStyles.textFieldDisabled,
                    error: dropdownStyles.textFieldError,
                  },
                }}
                required
                margin="none"
                data-test="gender-dropdown"
              >
                <MenuItem disabled value="">
                  {intl.formatMessage(messages.genderTypeTitle)}
                </MenuItem>
                <MenuItem value="male">
                  <FormattedMessage {...messages.maleLabel} />
                </MenuItem>
                <MenuItem value="female">
                  <FormattedMessage {...messages.femaleLabel} />
                </MenuItem>
                <MenuItem value="other">
                  <FormattedMessage {...messages.otherGenderLabel} />
                </MenuItem>
              </Field>
              <Field
                name="yearBirth"
                type="text"
                component={TextField}
                select
                classes={{
                  root: dropdownStyles.dropdownField,
                }}
                SelectProps={{
                  IconComponent,
                  displayEmpty: true,
                  MenuProps: {
                    onEntered: this.onYearBirthEntered,
                    classes: {
                      paper: dropdownStyles.heightModal,
                    },
                  },
                  classes: {
                    // icon: dropdownStyles.selectIcon,

                    selectMenu: dropdownStyles.selectMenu,
                  },
                  SelectDisplayProps: {
                    'data-test': 'year-birth-menu',
                  },
                }}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    root: dropdownStyles.formControlInput,
                    input: dropdownStyles.textFieldInput,
                    focused: textFieldStyles.textFieldFocused,
                    disabled: textFieldStyles.textFieldDisabled,
                    error: dropdownStyles.textFieldError,
                  },
                }}
                required
                margin="none"
                data-test="year-birth-dropdown"
              >
                <MenuItem disabled value="">
                  {intl.formatMessage(messages.yearTypeTitle)}
                </MenuItem>
                {_.range(1900, new Date().getFullYear() + 1)
                  .reverse()
                  .map(year => (
                    <MenuItem value={`${year}`} key={year}>
                      <span>{year}</span>
                    </MenuItem>
                  ))}
              </Field>
              {!isSocialSignUp && [
                <Field
                  name="password"
                  type="password"
                  key="password"
                  placeholder={intl.formatMessage(messages.passwordLabel)}
                  component={TextField}
                  classes={{
                    root: s.fieldsGeneral,
                  }}
                  InputProps={{
                    classes: {
                      root: textFieldStyles.textField,
                      input: textFieldStyles.textFieldInput,
                      focused: textFieldStyles.textFieldFocused,
                      disabled: textFieldStyles.textFieldDisabled,
                      error: textFieldStyles.textFieldError,
                    },
                  }}
                  margin="dense"
                  fullWidth
                />,
                <Field
                  name="confirmPassword"
                  type="password"
                  key="cofirmPassword"
                  placeholder={intl.formatMessage(
                    messages.confirmPasswordLabel,
                  )}
                  component={TextField}
                  classes={{
                    root: s.fieldsGeneral,
                  }}
                  InputProps={{
                    classes: {
                      root: textFieldStyles.textField,
                      input: textFieldStyles.textFieldInput,
                      focused: textFieldStyles.textFieldFocused,
                      disabled: textFieldStyles.textFieldDisabled,
                      error: textFieldStyles.textFieldError,
                    },
                  }}
                  margin="dense"
                />,
              ]}
              {integrationPersonIdVerificationEnabled && (
                <Field
                  name="integrationPersonId"
                  type="text"
                  placeholder={intl.formatMessage(messages.personIdLabel)}
                  component={TextField}
                  margin="dense"
                  classes={{
                    root: s.fieldsGeneral,
                  }}
                  InputProps={{
                    classes: {
                      root: textFieldStyles.textField,
                      input: textFieldStyles.textFieldInput,
                      focused: textFieldStyles.textFieldFocused,
                      disabled: textFieldStyles.textFieldDisabled,
                      error: textFieldStyles.textFieldError,
                    },
                  }}
                />
              )}
            </div>
          </div>
          <div className={s.checkBoxWrapper}>
            <Field
              name="privacy"
              type="checkbox"
              component={this.renderPrivacy}
              label={
                noOwnTermsUrl
                  ? intl.formatMessage(messages.skholePrivacyAcceptText)
                  : intl.formatMessage(messages.privacyAcceptText)
              }
              secondaryLabel={
                noOwnTermsUrl && intl.formatMessage(messages.skholeServiceText)
              }
              lang={intl.locale}
            />
          </div>
          {invitationError && (
            <Typography gutterBottom color="error">
              {invitationError}
            </Typography>
          )}
          {error && (
            <Typography gutterBottom color="error">
              {error}
            </Typography>
          )}
          <Button
            id="register-modal-signup-btn"
            color="secondary"
            type="submit"
            margin="dense"
            size="large"
            variant="contained"
            onClick={handleSubmit(this.submit)}
            disabled={submitting}
            className={s.signUpBtn}
          >
            <FormattedMessage {...messages.submitButtonText} />
          </Button>
          <Snackbar
            id="register-modal-signup-success-message"
            open={isSubmitted}
            onClose={() => this.handleCloseSnackbar(isSocialSignUp)}
            autoHideDuration={3000}
            message={<FormattedMessage {...messages.successSignUpMessage} />}
          />
        </form>
        <div className={modalStyles.closeModalBtn}>
          <TooltipButton message="closeButton">
            <IconButton onClick={onClose}>
              <CloseIcon className={modalStyles.closeIcon} />
            </IconButton>
          </TooltipButton>
        </div>
        {!membershipIdVerificationEnabled &&
          !isInvited &&
          !this.state.isInvitationLoading && (
            <HelpButton
              tour="register"
              tourComponent={Tour}
              classes={{ button: s.helpButton }}
              initialIsTourOpen
              inverted
            />
          )}
        <Modal open={isJoinMainGroupOpen} disableBackdropClick>
          <RequestChangeMainGroup
            onClose={this.onJoinMainGroupModalClose}
            email={_.get(values, 'email', '')}
            fetch={this.context.fetch}
            isInvitedMultiOrganizationUser={isInvitedMultiOrganizationUser}
            code={values.code}
            invitationToken={
              isInvitedMultiOrganizationUser ? values.invitationToken : ''
            }
          />
        </Modal>
      </div>
    );
  }
}
