import { defineMessages } from 'react-intl';

export default defineMessages({
  saveLabel: {
    id: 'Buttons.saveLabel',
    defaultMessage: 'Save',
  },
  addNewLabel: {
    id: 'Buttons.addNewLabel',
    defaultMessage: 'Add new',
  },
});
