export default () =>
  navigator.serviceWorker.getRegistrations().then(registrations => {
    registrations.forEach(registration => {
      console.info('Sw unregistering...', registration);
      if (registration.active) {
        registration.active.postMessage({ action: 'unregister' });
      }

      if (registration.installing) {
        registration.installing.postMessage({ action: 'unregister' });
      }

      if (registration.waiting) {
        registration.waiting.postMessage({ action: 'unregister' });
      }
    });
  });
