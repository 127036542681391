/* eslint-disable import/prefer-default-export */

import _ from 'lodash';

import {
  LOGOUT_AUTH,
  LOGOUT_AUTH_SUCCESS,
  LOGOUT_AUTH_FAIL,
  SAVE_AUTH,
  SAVE_FAVORITE_COURSES,
  SAVE_FOLLOWED_DISCUSSIONS,
  GET_MY_CERTIFICATES,
  GET_MY_CERTIFICATES_SUCCESS,
  GET_MY_CERTIFICATES_FAIL,
  RESET_MY_CERTIFICATES,
  GET_MY_COURSES,
  GET_MY_COURSES_SUCCESS,
  GET_MY_COURSES_FAIL,
  RESET_MY_COURSES,
  GET_STATISTIC_INFO_REQUEST,
  GET_STATISTIC_INFO_SUCCESS,
  GET_STATISTIC_INFO_FAIL,
  GET_DASHBOARD_DATA,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_DASHBOARD_DATA_FAIL,
  RESET_DASHBOARD_DATA,
} from '../constants';
import { buildQuery } from '../helpers';
import isLoggedIn from '../helpers/checkAuth';

export function isAuthLoaded(globalState) {
  return globalState.auth && _.has(globalState.auth, '_id');
}

export function logout() {
  return {
    types: [LOGOUT_AUTH, LOGOUT_AUTH_SUCCESS, LOGOUT_AUTH_FAIL],
    promise: fetch =>
      fetch('/api/auth/logout', {
        method: 'POST',
      }),
  };
}

export function saveAuth(user) {
  return {
    type: SAVE_AUTH,
    payload: user,
  };
}

export function saveFavoriteCourses(favoriteCourses) {
  return {
    type: SAVE_FAVORITE_COURSES,
    favoriteCourses,
  };
}

export function saveFollowedDiscussions(followedDiscussions) {
  return {
    type: SAVE_FOLLOWED_DISCUSSIONS,
    followedDiscussions,
  };
}

export function loadAuth() {
  return async (dispatch, getState, { fetch }) =>
    fetch(`/api/auth`, {
      method: 'GET',
    })
      .then(res =>
        res.json().then(resJson => {
          if (!res.ok) {
            throw new Error(resJson);
          }
          return resJson;
        }),
      )
      .catch(console.error)
      .then(user => {
        if (!process.env.BROWSER) {
          return user;
        }

        return user;
      })
      .then(user => _.has(user, '_id') && dispatch(saveAuth(user)));
}

export function loadFavoriteCourses() {
  return async (dispatch, getState, { fetch }) =>
    fetch('/api/auth/favorite-courses', {
      method: 'GET',
    })
      .then(res =>
        res.json().then(resJson => {
          if (!res.ok) {
            throw new Error(resJson);
          }
          return resJson;
        }),
      )
      .catch(console.error)
      .then(courses => dispatch(saveFavoriteCourses(courses)));
}

export function loadFollowedDiscussions() {
  return async (dispatch, getState, { fetch }) =>
    fetch('/api/auth/followed-discussions', {
      method: 'GET',
    })
      .then(res =>
        res.json().then(resJson => {
          if (!res.ok) {
            throw new Error(resJson);
          }
          return resJson;
        }),
      )
      .catch(console.error)
      .then(discussions => dispatch(saveFollowedDiscussions(discussions)));
}

export function getMyCertificates() {
  return {
    types: [
      GET_MY_CERTIFICATES,
      GET_MY_CERTIFICATES_SUCCESS,
      GET_MY_CERTIFICATES_FAIL,
    ],
    promise: fetch =>
      fetch('/api/auth/certificates', {
        method: 'GET',
      }),
  };
}

export function resetMyCertificates() {
  return {
    type: RESET_MY_CERTIFICATES,
  };
}

export function getMyCourses(params = { page: 1, perPage: 5 }) {
  const query = buildQuery(params);
  return {
    types: [GET_MY_COURSES, GET_MY_COURSES_SUCCESS, GET_MY_COURSES_FAIL],
    promise: fetch =>
      fetch(`/api/auth/courses?${query}`, {
        method: 'GET',
      }),
  };
}

export function resetMyCourses() {
  return {
    type: RESET_MY_COURSES,
  };
}

export function getStatisticInfo(params) {
  const query = buildQuery(params);
  return {
    types: [
      GET_STATISTIC_INFO_REQUEST,
      GET_STATISTIC_INFO_SUCCESS,
      GET_STATISTIC_INFO_FAIL,
    ],
    promise: fetch =>
      fetch(`/api/auth/statistic-info?${query}`, {
        method: 'GET',
      }),
  };
}

export function getDashboardData(params) {
  const query = buildQuery(params);
  return {
    types: [
      GET_DASHBOARD_DATA,
      GET_DASHBOARD_DATA_SUCCESS,
      GET_DASHBOARD_DATA_FAIL,
    ],
    promise: fetch =>
      fetch(`/api/auth/dashboard-data?${query}`, {
        method: 'GET',
      }),
  };
}

export function toggleListMode(listMode) {
  return async (dispatch, getState, { fetch }) => {
    const { auth } = getState();

    if (!isLoggedIn(auth)) {
      return;
    }

    await fetch('/api/auth', {
      method: 'PUT',
      body: JSON.stringify({
        listMode,
      }),
    }).catch(console.error);
  };
}

export function resetDashboardData() {
  return {
    type: RESET_DASHBOARD_DATA,
  };
}
