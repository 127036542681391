// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IejxN > div {\n  flex-wrap: nowrap;\n}\n\n.WmJO7 {\n  max-height: 100px;\n  overflow-y: auto;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "IejxN",
	"list": "WmJO7"
};
module.exports = ___CSS_LOADER_EXPORT___;
