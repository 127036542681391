import {
  GET_DASHBOARD_DISCUSSIONS_REQUEST,
  GET_DASHBOARD_DISCUSSIONS_SUCCESS,
  GET_DASHBOARD_DISCUSSIONS_FAIL,
  RESET_DASHBOARD_DISCUSSIONS,
} from '../constants';

const initialState = {
  loading: false,
  loaded: false,
  followedDiscussions: [],
  total: 0,
};

export default function followedDiscussions(state = initialState, action) {
  switch (action.type) {
    case GET_DASHBOARD_DISCUSSIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_DASHBOARD_DISCUSSIONS_SUCCESS:
      return {
        ...state,
        followedDiscussions: action.result.json,
        total: action.result.total,
        loading: false,
        loaded: true,
      };
    case GET_DASHBOARD_DISCUSSIONS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error,
      };
    case RESET_DASHBOARD_DISCUSSIONS:
      return initialState;
    default:
      return state;
  }
}
