import {
  GET_MAIN_GROUP_TRIAL_INFO,
  GET_MAIN_GROUP_TRIAL_INFO_SUCCESS,
  GET_MAIN_GROUP_TRIAL_INFO_FAIL,
  RESET_MAIN_GROUP_TRIAL_INFO,
} from '../constants';

const initialState = {
  loading: false,
  loaded: false,
  trialMainGroupInfo: {
    trialExpiredPageContent: {
      'en-US': '',
      'sv-SE': '',
      'fi-FI': '',
    },
  },
  loadingAll: false,
};

export default function trialMainGroupInfo(state = initialState, action) {
  switch (action.type) {
    case GET_MAIN_GROUP_TRIAL_INFO:
      return {
        ...state,
        loading: true,
      };
    case GET_MAIN_GROUP_TRIAL_INFO_SUCCESS:
      return {
        ...state,
        trialMainGroupInfo: action.result.json,
        total: action.result.total,
        loading: false,
        loaded: true,
      };
    case GET_MAIN_GROUP_TRIAL_INFO_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case RESET_MAIN_GROUP_TRIAL_INFO:
      return initialState;
    default:
      return state;
  }
}
