// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jazIZ{background-color:#fff;-webkit-box-shadow:0 3px 5px -1px rgba(0,0,0,.2),0 5px 8px 0 rgba(0,0,0,.14),0 1px 14px 0 rgba(0,0,0,.12);box-shadow:0 3px 5px -1px rgba(0,0,0,.2),0 5px 8px 0 rgba(0,0,0,.14),0 1px 14px 0 rgba(0,0,0,.12);-webkit-box-sizing:border-box;box-sizing:border-box;left:50%;padding:60px 32px;position:absolute;top:50%;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%);width:80%}@media only screen and (max-width: 767px){.jazIZ{display:flex;flex-direction:column;height:100%;padding:40px 20px;width:100% !important}}@media only screen and (max-width: 767px)and (orientation: landscape){.jazIZ{padding:10px 20px}}@media(min-width: 768px)and (max-width: 991px){.jazIZ{width:80% !important}}@media(min-width: 768px)and (max-width: 991px)and (min-height: 375px)and (max-height: 411px){.jazIZ{height:100%;overflow:auto;padding:10px 20px 0}}@media(orientation: landscape)and (max-width: 823px){.jazIZ{bottom:0;height:100%;left:0;padding:10px 20px;position:fixed;right:0;top:0;-webkit-transform:unset;transform:unset;width:100% !important}}@media(min-width: 768px)and (max-width: 991px){.jazIZ.zIIld{width:initial !important}}.e7U0i{padding:0}@media only screen and (max-width: 767px){.e7U0i{height:100%;width:100% !important}}.vkkcv{align-items:center;display:flex;flex:1 1;justify-content:center}.zIIld{display:flex}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paper": "jazIZ",
	"loading": "zIIld",
	"paddingNone": "e7U0i",
	"loadingWrapper": "vkkcv"
};
module.exports = ___CSS_LOADER_EXPORT___;
