import {
  // CHANGE_ON_LINE,
  // SET_IS_NEW_VERSION_AVAILABLE,
  // UPDATE_SW_STATE,
  // TOGGLE_IS_COURSE_DOWNLOADING,
  CHANGE_PWA_OFF_ON_LINE,
} from '../constants';

const initialState = {
  // offline: false,
  pwaOffOnline: false,
  // isNewVersionAvailable: false,
  // swState: '',
  // isCourseDownloading: false,
};

export default function online(state = initialState, action) {
  switch (action.type) {
    // case CHANGE_ON_LINE:
    //   return {
    //     ...state,
    //     offline: action.offline,
    //   };
    case CHANGE_PWA_OFF_ON_LINE:
      return {
        ...state,
        turnedOffPwaOffline: action.turnedOffPwaOffline,
      };
    // case SET_IS_NEW_VERSION_AVAILABLE:
    //   return {
    //     ...state,
    //     isNewVersionAvailable: action.isNewVersionAvailable,
    //   };
    // case UPDATE_SW_STATE:
    //   return {
    //     ...state,
    //     swState: action.swState,
    //   };
    // case TOGGLE_IS_COURSE_DOWNLOADING:
    //   return {
    //     ...state,
    //     isCourseDownloading: action.isCourseDownloading,
    //   };
    default:
      return state;
  }
}
