import {
  GET_QUESTIONS,
  GET_QUESTIONS_SUCCESS,
  GET_QUESTIONS_FAIL,
  RESET_QUESTIONS,
} from '../constants';

export default function questions(
  state = { loading: false, loaded: false, questions: [], total: 0 },
  action,
) {
  switch (action.type) {
    case GET_QUESTIONS:
      return {
        ...state,
        loading: true,
      };
    case GET_QUESTIONS_SUCCESS:
      return {
        ...state,
        questions: action.result.json,
        total: action.result.total,
        loading: false,
        loaded: true,
      };
    case GET_QUESTIONS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case RESET_QUESTIONS:
      return {
        ...state,
        loaded: false,
        questions: [],
      };
    default:
      return state;
  }
}
