import React from 'react';
import { Icon } from './index';

const SkholeIcon47 = Icon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    enableBackground="new 0 0 40 40"
    version="1.1"
    viewBox="0 0 40 40"
    xmlSpace="preserve"
  >
    <path
      d="M35 30.5c-2-1.9-2.8-5.7-3.6-9.6C30 14.6 28.5 7.5 21.2 6.7V4.4c0-.7-.6-1.2-1.2-1.2s-1.2.6-1.2 1.2v2.3c-7.3.8-8.8 7.9-10.2 14.2-.8 3.9-1.6 7.7-3.6 9.6-.4.4-.5.9-.3 1.4.2.5.6.8 1.2.8h9.5c.4 2.6 2.1 4.2 4.6 4.2s4.2-1.6 4.6-4.2h9.5c.5 0 1-.3 1.2-.8.2-.5 0-1-.3-1.4zm-15 3.9c-1.3 0-1.9-.8-2.1-1.7h4.2c-.2.9-.8 1.7-2.1 1.7zm-3.5-4.3H8.4c1.4-2.4 2-5.5 2.7-8.7 1.5-7.4 3-12.2 8.9-12.3 5.9.1 7.4 4.9 8.9 12.3.7 3.2 1.3 6.4 2.7 8.7H16.5z"
      className="st1"
    />
  </svg>,
);

export default SkholeIcon47;
