import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

const messages = defineMessages({
  required: { id: 'General.Required', defaultMessage: 'Required' },
  eightCharts: {
    id: 'General.PasswordMustHaveAtLeastEightCharacters',
    defaultMessage: 'Password must have at least eight characters',
  },
  passwordStrength: {
    id: 'General.PasswordsMustUse4Types',
    defaultMessage:
      'Passwords must use the four available character types: lowercase letters, uppercase letters, numbers, and symbols',
  },
  matchPassword: {
    id: 'General.PasswordsMustMatch',
    defaultMessage: 'Passwords must match',
  },
});
const validate = values => {
  const errors = {};
  const passHasNumber =
    values && values.password && /\d/g.test(values.password);
  const passHasUpperCase =
    values && values.password && /[A-Z]/g.test(values.password);
  const passHasLowerCase =
    values && values.password && /[a-z]/g.test(values.password);
  const passHasSpecSymbols =
    values &&
    values.password &&
    /[^a-zA-Z0-9ÅåÄäÖöŽžŠš]/g.test(values.password);

  const passStrength =
    passHasNumber + passHasUpperCase + passHasLowerCase + passHasSpecSymbols ||
    0;

  if (passStrength < 4) {
    errors.password = <FormattedMessage {...messages.passwordStrength} />;
  }

  if (!values.password || !values.password.trim()) {
    errors.password = <FormattedMessage {...messages.required} />;
  }

  if (values.password && values.password.length < 8) {
    errors.password = <FormattedMessage {...messages.eightChars} />;
  }

  if (!values.confirmPassword || !values.confirmPassword.trim()) {
    errors.confirmPassword = <FormattedMessage {...messages.required} />;
  }

  if (values.password !== values.confirmPassword) {
    errors.confirmPassword = <FormattedMessage {...messages.matchPassword} />;
  }

  return errors;
};

export default validate;
