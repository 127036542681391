import React, { useContext } from 'react';
import _ from 'lodash';
import withStyles from 'isomorphic-style-loader/withStyles';

import { Interweave } from 'interweave';
import ContextProvider from '../../context';
import s from './Footer.scss';

const Footer = () => {
  const context = useContext(ContextProvider);

  const footer = _.get(context, 'whitelabeling.footerHtml');

  if (!footer) {
    return null;
  }

  return <Interweave tagName="div" className={s.root} content={footer} />;
};

export default withStyles(s)(Footer);
