import React, { useContext } from 'react';
import _ from 'lodash';
import Tour from 'reactour';
import NoSsr from '@material-ui/core/NoSsr';
import withStyles from 'isomorphic-style-loader/withStyles';

import ContextProvider from '../../context';

import s from './index.scss';

const CustomTour = props => {
  const { whitelabeling } = useContext(ContextProvider);
  return (
    <NoSsr>
      <Tour
        onAfterOpen={() => {
          document.body.classList.add('hiddenOverflow');
        }}
        onBeforeClose={() => {
          document.body.classList.remove('hiddenOverflow');
        }}
        rounded={4}
        startAt={0}
        maskClassName={s.maskClassName}
        showNavigationNumber={_.get(props, 'steps.length') !== 1}
        showNumber={_.get(props, 'steps.length') !== 1}
        showButtons={_.get(props, 'steps.length') !== 1}
        disableDotsNavigation={_.get(props, 'steps.length') === 1}
        accentColor={_.get(whitelabeling, 'primaryDarkColor')}
        {...props}
      />
    </NoSsr>
  );
};

CustomTour.propTypes = {
  ...Tour.propTypes,
};

export default withStyles(s)(CustomTour);
