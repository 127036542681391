import {
  GET_QUIZ_STATISTICS,
  GET_QUIZ_STATISTICS_SUCCESS,
  GET_QUIZ_STATISTICS_FAIL,
  RESET_QUIZ_STATISTICS,
} from '../constants';

export default function quizStatistics(
  state = { loading: false, quizStatistics: [], total: 0, loaded: false },
  action,
) {
  switch (action.type) {
    case GET_QUIZ_STATISTICS:
      return {
        ...state,
        loading: true,
      };
    case GET_QUIZ_STATISTICS_SUCCESS:
      return {
        ...state,
        quizStatistics: action.result.json,
        total: action.result.total,
        loaded: true,
        loading: false,
      };
    case GET_QUIZ_STATISTICS_FAIL:
      return {
        ...state,
        error: action.error,
        loaded: true,
        loading: false,
      };
    case RESET_QUIZ_STATISTICS:
      return {
        ...state,
        loaded: false,
        quizStatistics: [],
      };
    default:
      return state;
  }
}
