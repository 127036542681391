import { defineMessages } from 'react-intl';

export default defineMessages({
  codeLabel: {
    id: 'Register.code',
    defaultMessage: 'Code',
    description:
      'Teacher or sign up code label displayed on the registration form',
  },
  emailLabel: {
    id: 'Register.email',
    defaultMessage: 'Email',
    description: 'Email label displayed on the registration form',
  },
  firstNameLabel: {
    id: 'Register.firstName',
    defaultMessage: 'First name',
    description: 'First name label displayed on the registration form',
  },
  lastNameLabel: {
    id: 'Register.lastName',
    defaultMessage: 'Last name',
    description: 'Last name label displayed on the registration form',
  },
  professionLabel: {
    id: 'General.professionLabel',
    defaultMessage: 'Profession',
    description: 'Profession label displayed in edit profile page',
  },
  passwordLabel: {
    id: 'Register.password',
    defaultMessage: 'Password',
    description: 'Password label displayed on the registration form',
  },
  confirmPasswordLabel: {
    id: 'Register.confirmPassword',
    defaultMessage: 'Confirm password',
    description: 'Confirm password label displayed on the registration form',
  },
  submitButtonText: {
    id: 'Register.submitButtonText',
    defaultMessage: 'Sign up',
    description: 'Submit button text on the registration form',
  },
  successSignUpMessage: {
    id: 'Register.successSignUpMessage',
    defaultMessage: 'Thank you! Now you can sign in with your credentials',
    description:
      'Text displayed in snackbar on the registration form after success submit',
  },
  registrationTitle: {
    id: 'Register.registrationTitle',
    defaultMessage: 'Registration',
    description: ' Registration displayed as title on the registration modal',
  },
  privacyAcceptText: {
    id: 'Register.privacyAcceptText',
    defaultMessage: 'I agree to the Terms and Conditions',
    description:
      'Text "I agree to the Terms and Conditions" in registration form  on the bottom before sign-up button',
  },
  skholePrivacyAcceptText: {
    id: 'Register.skholePrivacyAcceptText',
    defaultMessage: "I agree to Skhole's Terms and Conditions",
  },
  skholeServiceText: {
    id: 'Register.skholeServiceText',
    defaultMessage: 'This service is provided by Skhole',
  },
  joinMainGroup: {
    id: 'Register.joinMainGroup',
    defaultMessage: 'Click here to join main group',
  },
  educationTypeTitle: {
    id: 'General.educationTypeTitle',
    defaultMessage: 'Education Level',
    description: 'Menu title education displayed in edit profile page',
  },
  schoolLabel: {
    id: 'General.schoolLabel',
    defaultMessage: 'Vocational School',
    description: 'Label school displayed in edit profile dropdawn page',
  },
  realUniversityLabel: {
    id: 'General.realUniversityLabel',
    defaultMessage: 'University',
    description:
      'Label real university displayed in edit profile dropdawn page',
  },
  universityLabel: {
    id: 'General.universityLabel',
    defaultMessage: 'University of Applied Scieces',
    description: 'Label university displayed in edit profile dropdawn page',
  },
  collegeLabel: {
    id: 'General.collegeLabel',
    defaultMessage: 'College degree',
    description: 'Label college displayed in edit profile dropdawn page',
  },
  otherUniversityLabel: {
    id: 'General.otherUniversityLabel',
    defaultMessage: 'Other',
    description: 'Label other displayed in edit profile dropdawn page',
  },
  genderTypeTitle: {
    id: 'General.genderTypeTitle',
    defaultMessage: 'Gender',
    description: 'Menu title gender displayed in edit profile page',
  },
  maleLabel: {
    id: 'General.maleLabel',
    defaultMessage: 'Male',
    description: 'Menu male gender displayed in edit profile page',
  },
  femaleLabel: {
    id: 'General.femaleLabel',
    defaultMessage: 'Female',
    description: 'Menu female gender displayed in edit profile page',
  },
  otherGenderLabel: {
    id: 'General.otherGenderLabel',
    defaultMessage: 'Other',
    description: 'Menu other gender displayed in edit profile page',
  },
  yearTypeTitle: {
    id: 'General.yearTypeTitle',
    defaultMessage: 'Year of Birth',
    description: 'Label year of birth displayed in edit profile drapdawn page',
  },
  emailIsTakenByGroupleader: {
    id: 'Register.emailIsTakenByGroupleader',
    defaultMessage: 'Please ask Skhole to change organization of this user',
  },
  b2cMembershipId: {
    id: 'Register.b2cMembershipId',
    defaultMessage: 'Membership id',
  },
  pleaseContractSupport: {
    id: 'Register.pleaseContractSupport',
    defaultMessage:
      'Please contact customer service cs@skhole.fi to resolve issue.',
  },
  personIdLabel: {
    id: 'Register.personIdLabel',
    defaultMessage: 'Person id',
  },
});
