import {
  GET_STUDIED_LESSONS,
  GET_STUDIED_LESSONS_SUCCESS,
  GET_STUDIED_LESSONS_FAIL,
  RESET_STUDIED_LESSONS,
} from '../constants';

export default function studiedLessons(
  state = { loading: false, studiedLessons: [], total: 0 },
  action,
) {
  switch (action.type) {
    case GET_STUDIED_LESSONS:
      return {
        ...state,
        loading: true,
      };
    case GET_STUDIED_LESSONS_SUCCESS:
      return {
        ...state,
        studiedLessons: action.result.json,
        total: action.result.total,
        loading: false,
      };
    case GET_STUDIED_LESSONS_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case RESET_STUDIED_LESSONS:
      return {
        ...state,
        studiedLessons: [],
      };
    default:
      return state;
  }
}
