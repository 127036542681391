import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import history from '../../history';

function isLeftClickEvent(event) {
  return event.button === 0;
}

function isModifiedEvent(event) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

export default class Link extends React.Component {
  static propTypes = {
    to: PropTypes.string.isRequired,
    children: PropTypes.node,
    onClick: PropTypes.func,
    noModification: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    onClick: null,
    children: null,
    noModification: false,
    disabled: false,
  };

  static contextTypes = {
    locale: PropTypes.string,
    organizationSlug: PropTypes.string,
  };

  getTo = () => {
    const { disabled, noModification } = this.props;
    let { to } = this.props;
    const { locale, organizationSlug } = this.context;

    if (disabled) {
      return '#';
    }

    if (!to.startsWith('/') || noModification) {
      return to;
    }

    if (to === '/') {
      to = '';
    }

    return `/${locale}${organizationSlug ? `/${organizationSlug}` : ''}${to}`;
  };

  handleClick = event => {
    const { onClick } = this.props;
    if (onClick) {
      onClick(event);
    }

    if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
      return;
    }

    if (event.defaultPrevented === true) {
      return;
    }

    event.preventDefault();

    if (history.location.pathname === this.getTo()) {
      return;
    }

    history.push(this.getTo());
  };

  render() {
    const { children, ...props } = this.props;
    return (
      <a
        href={this.getTo()}
        {..._.omit(props, ['to', 'noModification'])}
        onClick={this.handleClick}
        ref={node => {
          // eslint-disable-next-line react/no-unused-class-component-methods
          this.link = node;
        }}
      >
        {children}
      </a>
    );
  }
}
