import {
  GET_USER_COURSE_COMPLETED_LESSONS,
  GET_USER_COURSE_COMPLETED_LESSONS_SUCCESS,
  GET__USER_COURSE_COMPLETED_LESSONS_FAIL,
  RESET__USER_COURSE_COMPLETED_LESSONS,
} from '../constants';

const initialState = {
  loading: false,
  userCourseCompletedLessons: [],
  total: 0,
};

export default function attemptsResults(state = initialState, action) {
  switch (action.type) {
    case GET_USER_COURSE_COMPLETED_LESSONS:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_COURSE_COMPLETED_LESSONS_SUCCESS:
      return {
        ...state,
        userCourseCompletedLessons: action.result.json,
        total: action.result.total,
        loading: false,
      };
    case GET__USER_COURSE_COMPLETED_LESSONS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case RESET__USER_COURSE_COMPLETED_LESSONS:
      return initialState;
    default:
      return state;
  }
}
