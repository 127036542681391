import { defineMessages } from 'react-intl';

export default defineMessages({
  about: {
    id: 'navigation.about',
    defaultMessage: 'About',
    description: 'About link in header',
  },
  login: {
    id: 'navigation.login',
    defaultMessage: 'Log in',
    description: 'Log in link in header',
  },
  or: {
    id: 'navigation.separator.or',
    defaultMessage: 'or',
    description: 'Last separator in list, lowercase "or"',
  },
  signup: {
    id: 'navigation.signup',
    defaultMessage: 'Sign up',
    description: 'Sign up link in header',
  },
  logout: {
    id: 'navigation.logout',
    defaultMessage: 'Log out',
    description: 'Log out link in header',
  },
  backToAdmin: {
    id: 'navigation.backToAdmin',
    defaultMessage: 'Back to admin',
    description: 'Back to admin link in header',
  },
  enLabel: {
    id: 'navigation.enLabel',
    defaultMessage: 'In English',
    description: 'EN link in header',
  },
  fiLabel: {
    id: 'navigation.fiLabel',
    defaultMessage: 'Suomeksi',
    description: 'FI link in header',
  },
  seLabel: {
    id: 'navigation.seLabel',
    defaultMessage: 'På svenska',
    description: 'SE link in header',
  },
  languagesBtn: {
    id: 'navigation.languagesBtn',
    defaultMessage: 'Language',
    description: 'Languages link in header',
  },
  groupNameLabel: {
    id: 'navigation.groupNameLabel',
    defaultMessage: 'Group name here',
    description: 'Group name label in header',
  },
  usersLabel: {
    id: 'navigation.usersLabel',
    defaultMessage: 'Users',
    description: 'Users link in header',
  },
  classroomLabel: {
    id: 'navigation.classroomLabel',
    defaultMessage: 'Classroom',
    description: 'Classroom link in header',
  },
  authorsLabel: {
    id: 'navigation.authorsLabel',
    defaultMessage: 'Authors',
    description: 'Classroom link in header',
  },
  tagsLabel: {
    id: 'navigation.tagsLabel',
    defaultMessage: 'Tags',
    description: 'Tags link in header',
  },
  quizzesLabel: {
    id: 'navigation.quizzesLabel',
    defaultMessage: 'Quizzes',
    description: 'Quizzes link in header',
  },
  statisticsLabel: {
    id: 'navigation.statisticsLabel',
    defaultMessage: 'Statistics',
    description: 'Statistics link in header',
  },
  profileLabel: {
    id: 'navigation.profileLabel',
    defaultMessage: 'Profile',
    description: 'Profile link in header',
  },
  myStatisticsLabel: {
    id: 'navigation.myStatisticsLabel',
    defaultMessage: 'My statistic',
    description: 'My statistic link in header',
  },
  tasksLabel: {
    id: 'navigation.tasksLabel',
    defaultMessage: 'Tasks',
    description: 'Tasks link in header',
  },
  myGroupLabel: {
    id: 'navigation.myGroupLabel',
    defaultMessage: 'My group',
    description: 'Navigation menu label for user ',
  },
  adminLabel: {
    id: 'navigation.adminLabel',
    defaultMessage: 'Admin',
    description: 'Navigation menu label for admin ',
  },
  customerService: {
    id: 'navigation.customerService',
    defaultMessage: 'Customer service',
  },
  moreOptions: {
    id: 'navigation.moreOptions',
    defaultMessage: 'More...',
  },
  notifications: {
    id: 'navigation.notifications',
    defaultMessage: 'Notifications',
  },
  profile: {
    id: 'navigation.profile',
    defaultMessage: 'Profile',
  },
  trialUser: {
    id: 'navigation.trialUser',
    defaultMessage: 'Trial user',
  },
  trialUserTooltip: {
    id: 'navigation.trialUserTooltip',
    defaultMessage:
      '{days} {days, plural, one {day} other {days}} of trial left',
  },
  trialOrderBtn: {
    id: 'navigation.trialOrderBtn',
    defaultMessage: 'Subscribe now',
  },
  trialOrderBtnHref: {
    id: 'navigation.trialOrderBtnHref',
    defaultMessage: 'https://www.skhole.fi/subscribe',
  },
  groupsLabel: {
    id: 'navigation.groupsLabel',
    defaultMessage: 'Groups & Registration codes',
  },
  organizationsLabel: {
    id: 'navigation.organizationsLabel',
    defaultMessage: 'Organizations',
  },
  organizationInfoLabel: {
    id: 'navigation.organizationInfoLabel',
    defaultMessage: 'Organization info',
  },
  adminToolsLabel: {
    id: 'navigation.adminToolsLabel',
    defaultMessage: 'Admin Tools',
  },
  organizationWorkspacesBtn: {
    id: 'navigation.organizationWorkspacesBtn',
    defaultMessage: 'Organization workspaces',
  },
});
