// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cubmi{display:flex;flex-direction:column;gap:12px}.o8wDK{justify-content:flex-start;margin-top:20px;max-width:300px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "cubmi",
	"verificationMethodBtn": "o8wDK"
};
module.exports = ___CSS_LOADER_EXPORT___;
