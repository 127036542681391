import {
  GET_ASSIGNMENT_STATISTICS,
  GET_ASSIGNMENT_STATISTICS_SUCCESS,
  GET_ASSIGNMENT_STATISTICS_FAIL,
  RESET_ASSIGNMENT_STATISTICS,
} from '../constants';

const initialState = {
  loading: false,
  assignmentStatistics: [],
  total: 0,
  loaded: false,
};

export default function assignmentStatistics(state = initialState, action) {
  switch (action.type) {
    case GET_ASSIGNMENT_STATISTICS:
      return {
        ...state,
        loading: true,
      };
    case GET_ASSIGNMENT_STATISTICS_SUCCESS:
      return {
        ...state,
        assignmentStatistics: action.result.json,
        total: action.result.total,
        loaded: true,
        loading: false,
      };
    case GET_ASSIGNMENT_STATISTICS_FAIL:
      return {
        ...state,
        error: action.error,
        loaded: true,
        loading: false,
      };
    case RESET_ASSIGNMENT_STATISTICS:
      return initialState;
    default:
      return state;
  }
}
