import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { intlShape, injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';

import withStyles from 'isomorphic-style-loader/withStyles';
import { Interweave } from 'interweave';
import isLoggedIn from '../../../helpers/checkAuth';

import s from './TrialAboutToExpireSnackbar.css';

const messages = defineMessages({
  expiringDeal: {
    id: 'navigation.expiringTrial',
    defaultMessage:
      'Your subscription will expire soon (<b>{expirationDate}</b>)',
  },
});

@injectIntl
@withStyles(s)
@connect(state => ({
  auth: state.auth,
}))
export default class TrialAboutToExpireSnackbar extends React.Component {
  static propTypes = {
    auth: PropTypes.object,  // eslint-disable-line
    intl: intlShape.isRequired,
  };

  state = {
    isShown: false,
  };

  componentDidMount() {
    this.checkExpirationDate(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      (isLoggedIn(this.props.auth) && !isLoggedIn(nextProps.auth)) ||
      (_.get(this, 'props.auth.user._id') &&
        _.get(nextProps, 'auth.user._id') &&
        _.get(this, 'props.auth.user._id') !==
          _.get(nextProps, 'auth.user._id'))
    ) {
      this.checkExpirationDate(nextProps);
    }

    if (
      (!isLoggedIn(this.props.auth) && isLoggedIn(nextProps.auth)) ||
      (_.get(this, 'props.auth.user._id') &&
        _.get(nextProps, 'auth.user._id') &&
        _.get(this, 'props.auth.user._id') !==
          _.get(nextProps, 'auth.user._id'))
    ) {
      this.checkExpirationDate(nextProps);
    }
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ isShown: false });
  };

  checkExpirationDate = props => {
    const expirationDate = _.get(props, 'auth.user.expirationDate');
    if (!expirationDate) {
      return;
    }

    const days = moment()
      .add(4, 'days')
      .diff(moment(expirationDate), 'days');

    const isShown = days > 0 && days <= 10;

    this.setState({ isShown });
  };

  render() {
    const {
      auth,
      intl: { formatMessage },
    } = this.props;
    const { isShown } = this.state;

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={isShown}
        onClose={this.handleClose}
        message={
          <Interweave
            tagName="div"
            content={formatMessage(messages.expiringDeal, {
              expirationDate: moment(_.get(auth, 'user.expirationDate')).format(
                'DD.MM.YYYY',
              ),
            })}
          />
        }
        classes={{ root: s.root }}
        action={[
          <IconButton color="inherit" onClick={this.handleClose}>
            <CloseIcon />
          </IconButton>,
        ]}
      />
    );
  }
}
