import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

// external-global styles must be imported in your JS.

import ContextType from '../ContextType';

export default class ContextProvider extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    context: PropTypes.shape({
      params: PropTypes.shape({}),
      path: PropTypes.string,
      locale: PropTypes.string,
      route: PropTypes.shape({}),
      router: PropTypes.shape({}),
      query: PropTypes.shape({}),
    }),
    tour: PropTypes.string,
  };

  static defaultProps = {
    context: {
      params: {},
    },
    tour: '',
  };

  static contextTypes = ContextType;

  static childContextTypes = {
    params: PropTypes.shape({}),
    path: PropTypes.string,
    locale: PropTypes.string,
    route: PropTypes.shape({}),
    router: PropTypes.shape({}),
    query: PropTypes.shape({}),
    tour: PropTypes.string,
    headerRef: PropTypes.node,
    ...ContextType,
  };

  getChildContext() {
    return {
      params: get(this.props.context, 'params'),
      path: get(this.props.context, 'path'),
      locale: get(this.props.context, 'locale'),
      route: get(this.props.context, 'route'),
      router: get(this.props.context, 'router'),
      query: get(this.props.context, 'query'),
      tour: this.props.tour,
      headerRef: this.headerRef,
      ...this.context,
    };
  }

  render() {
    return this.props.children;
  }
}
