import {
  GET_TAGS,
  GET_TAGS_SUCCESS,
  GET_TAGS_FAIL,
  RESET_TAGS,
} from '../constants';

const initialState = {
  wereEmpty: true,
  loading: false,
  loaded: false,
  tags: [],
  total: 0,
};

export default function tags(state = initialState, action) {
  switch (action.type) {
    case GET_TAGS:
      return {
        ...state,
        loading: true,
      };
    case GET_TAGS_SUCCESS:
      return {
        ...state,
        tags: action.result.json,
        wereEmpty: !action.result.json?.length,
        total: action.result.total,
        loading: false,
        loaded: true,
      };
    case GET_TAGS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case RESET_TAGS:
      return { ...initialState, wereEmpty: state.wereEmpty };
    default:
      return state;
  }
}
