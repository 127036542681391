import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import _ from 'lodash';
import request from 'superagent';
import filesize from 'filesize';
import {
  FormattedMessage,
  FormattedHTMLMessage,
  injectIntl,
  intlShape,
} from 'react-intl';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InsertPhoto from '@material-ui/icons/InsertPhoto';

import { Interweave } from 'interweave';
import s from './styles.scss';

import messages from './messages';

const allowedMimeTypes = {
  png: 'image/png',
  jpg: 'image/jpeg',
};
const maximumAttachmentSize = 2 * 1024 * 1024;

@injectIntl
@withStyles(s)
export default class ImageAdd extends React.Component {
  static propTypes = {
    modifier: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    editorState: PropTypes.object.isRequired, // eslint-disable-line
    intl: intlShape.isRequired,
    apiEndpointUrl: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
  };

  static contextTypes = {
    cloudfrontUrl: PropTypes.string,
  };

  state = {
    showDialog: false,
    snackbarText: '',
    progress: 0,
  };

  request = null;

  componentWillUnmount() {
    if (this.request) {
      this.request.abort();
    }
  }

  toggleDialog = showDialog => {
    this.setState({ showDialog });
  };

  selectFile = () => {
    this.input.click();
  };

  handleCloseSnackbar = () => {
    this.setState({ snackbarText: '' });
  };

  uploadAttachment = e => {
    e.preventDefault();
    const { files } = e.target;
    if (!files.length) return;

    const {
      intl: { formatMessage, formatHTMLMessage },
      editorState,
      onChange,
      modifier,
      apiEndpointUrl,
    } = this.props;

    const file = files[0];

    if (file.size > maximumAttachmentSize) {
      this.setState({
        snackbarText: formatHTMLMessage(messages.maximumFileSizeError, {
          size: filesize(maximumAttachmentSize),
        }),
      });
      return;
    }

    if (!_.values(allowedMimeTypes).includes(file.type)) {
      this.setState({
        snackbarText: formatHTMLMessage(messages.allowedTypesError, {
          types: _.keys(allowedMimeTypes).join(', '),
        }),
      });
      return;
    }

    this.setState({
      snackbarText: '',
      progress: 0,
    });

    this.request = request
      .post(apiEndpointUrl)
      .withCredentials()
      .attach('file', file)
      .on('progress', ({ direction, percent }) => {
        if (direction === 'upload') {
          this.setState({ progress: percent > 90 ? 90 : percent });
        }

        if (direction === 'download') {
          this.setState({ progress: percent > 90 ? 90 : percent });
        }
      });

    this.request
      .then(({ body }) => {
        this.setState({
          snackbarText: formatMessage(messages.uploadedMessage),
        });

        const url = `${this.context.cloudfrontUrl}/${_.get(
          body,
          'path',
          'default.svg',
        )}`;

        return onChange(
          modifier(editorState, url, { alt: body.name, width: '100' }),
        );
      })
      .then(() => {
        this.input.value = '';
        this.setState({ progress: 0 });

        this.request = null;
        this.toggleDialog(false);
      })
      .catch(err => {
        const snackbarText =
          _.get(err, 'response.body.message') || (err && err.message);
        console.error(snackbarText);
        this.request = null;
        this.setState({
          progress: 0,
          snackbarText,
        });
      });
  };

  render() {
    const { showDialog, snackbarText, progress } = this.state;
    const { disabled } = this.props;

    return (
      <div>
        <input
          type="file"
          id="content-image"
          className={s.hidden}
          accept={_.values(allowedMimeTypes).join(',')}
          onChange={this.uploadAttachment}
          disabled={disabled}
          ref={input => {
            this.input = input;
          }}
        />
        <Button
          onClick={() => this.toggleDialog(true)}
          size="small"
          variant="outlined"
          className={s.editorButton}
          disabled={disabled}
        >
          <InsertPhoto />
          <FormattedMessage {...messages.editorButton} />
        </Button>

        <Dialog
          open={showDialog}
          onClose={() => this.toggleDialog(false)}
          disableBackdropClick={!!progress}
          disableEscapeKeyDown={!!progress}
          classes={{ paper: s.dialog }}
        >
          <DialogTitle classes={{ root: s.dialogTitle }}>
            <FormattedMessage {...messages.dialogTitle} />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography color="inherit">
                <FormattedHTMLMessage
                  {...messages.allowedFormats}
                  values={{ formats: _.keys(allowedMimeTypes).join(', ') }}
                />
              </Typography>
              <Typography color="inherit">
                <FormattedHTMLMessage
                  {...messages.maximumFileSize}
                  values={{ size: filesize(maximumAttachmentSize) }}
                />
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={!!progress}
              onClick={() => this.toggleDialog(false)}
            >
              <FormattedMessage {...messages.closeDialogButton} />
            </Button>
            <Button
              onClick={this.selectFile}
              className={s.selectFile}
              disabled={!!progress}
              color="primary"
              variant="contained"
              autoFocus
            >
              <FormattedMessage {...messages.uploadButton} />
              {!!progress && (
                <CircularProgress
                  size={24}
                  className={s.buttonProgress}
                  variant="static"
                  value={progress}
                />
              )}
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={!!snackbarText}
          onClose={this.handleCloseSnackbar}
          autoHideDuration={2000}
          message={<Interweave content={snackbarText} />}
        />
      </div>
    );
  }
}
