import React from 'react';
import { Icon } from './index';

const FacebookIcon = Icon(
  <svg
    viewBox="0 0 9 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="home" stroke="none" strokeWidth="1">
      <g
        id="Home,-Desktop-normal,-iPad-Landscape,-login"
        transform="translate(-597.000000, -513.000000)"
      >
        <g id="Group-5" transform="translate(446.000000, 116.000000)">
          <g id="Group" transform="translate(78.000000, 386.000000)">
            <g id="Group-7" transform="translate(73.000000, 4.000000)">
              <path
                d="M1.8,25 L5.4,25 L5.4,16 L8.0718,16 L8.4,12.4 L5.5386,12.4 L5.5386,10.9606 C5.5386,10.2592 6.0066,10.0954 6.3348,10.0954 L8.3544,10.0954 L8.3544,7.0114 L5.5734,7 C2.487,7 1.7856,9.3004 1.7856,10.7728 L1.7856,12.4 L0,12.4 L0,16 L1.8,16 L1.8,25 Z"
                id="Page-1"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>,
);

export default FacebookIcon;
