/* eslint-disable import/prefer-default-export */

import { IntlProvider } from 'react-intl';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import {
  SET_LOCALE_START,
  SET_LOCALE_SUCCESS,
  SET_LOCALE_ERROR,
} from '../constants';

let getMessages;
if (!process.env.BROWSER) {
  getMessages = require('../helpers/getMessages').default; // eslint-disable-line global-require
}

function getIntlFromState(state) {
  const intl = (state && state.intl) || {};
  const { initialNow, locale, messages } = intl;
  const localeMessages = (messages && messages[locale]) || {};
  const provider = new IntlProvider({
    initialNow,
    locale,
    messages: localeMessages,
    defaultLocale: 'en-US',
  });
  return provider.getChildContext().intl;
}

export function getIntl() {
  return (dispatch, getState) => getIntlFromState(getState());
}

export function setLocale({ locale, organizationSlug, redirect = true }) {
  return async (dispatch, getState, { fetch }) => {
    await dispatch(showLoading());
    await dispatch({
      type: SET_LOCALE_START,
      payload: {
        locale,
      },
    });

    try {
      let data = [];
      if (process.env.BROWSER) {
        const req = await fetch(`/locales/${locale}`);
        data = await req.json();
      } else {
        data = await getMessages(locale);
      }

      const messages = data.reduce((msgs, msg) => {
        msgs[msg.id] = msg.message; // eslint-disable-line no-param-reassign
        return msgs;
      }, {});
      await dispatch({
        type: SET_LOCALE_SUCCESS,
        payload: {
          locale,
          messages,
        },
      });
      await dispatch(hideLoading());

      // remember locale for every new request
      if (process.env.BROWSER) {
        const maxAge = 3650 * 24 * 3600; // 10 years in seconds
        document.cookie = `lang=${locale};domain=${window.App.cookieDomain};path=/;max-age=${maxAge}`;
        if (redirect) {
          window.location = `/${locale}${
            organizationSlug ? `/${organizationSlug}` : ''
          }`;
        }
      }

      // return bound intl instance at the end
      return getIntlFromState(getState());
    } catch (error) {
      console.error(error);
      await dispatch({
        type: SET_LOCALE_ERROR,
        payload: {
          locale,
          error,
        },
      });
      await dispatch(hideLoading());
      return null;
    }
  };
}
