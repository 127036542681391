import React from 'react';
import { Icon } from './index';

const SkholeIcon49 = Icon(
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 40 40"
    style={{ enableBackground: 'new 0 0 40 40' }}
    xmlSpace="preserve"
  >
    <g>
      <path
        className="st1"
        d="M35.7,5.6c0-0.7-0.6-1.3-1.3-1.3L22,4.2c-0.4,0-0.7,0.1-0.9,0.4L4.5,21.1c-0.2,0.2-0.4,0.6-0.4,0.9
          c0,0.3,0.1,0.7,0.4,0.9l12.5,12.5c0.3,0.3,0.6,0.4,0.9,0.4s0.7-0.1,0.9-0.4l16.6-16.6c0.2-0.2,0.4-0.6,0.4-0.9L35.7,5.6z M18,32.7
          L7.3,22L22.6,6.8l10.5,0.1l0.1,10.5L18,32.7z"
      />
      <path
        className="st1"
        d="M25.7,9.4c-1.3,0-2.5,0.5-3.5,1.4c-1.9,1.9-1.9,5,0,6.9c1,1,2.2,1.4,3.5,1.4c1.2,0,2.5-0.5,3.5-1.4
          c1.9-1.9,1.9-5,0-6.9C28.3,9.9,27,9.4,25.7,9.4z M27.4,15.9c-0.9,0.9-2.3,0.9-3.2,0c-0.9-0.9-0.9-2.3,0-3.2c0.4-0.4,1-0.7,1.6-0.7
          c0.6,0,1.2,0.2,1.6,0.7C28.3,13.5,28.3,15,27.4,15.9z"
      />
    </g>
  </svg>,
);

export default SkholeIcon49;
