import React from 'react';
import { Icon } from './index';

const SkholeIcon07 = Icon(
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 40 40"
    style={{ enableBackground: 'new 0 0 40 40' }}
    xmlSpace="preserve"
  >
    <path
      className="st1"
      d="M34,9.8h-5.5c-0.7,0-1.2,0.6-1.2,1.2v21.6h-3V6.1c0-0.7-0.6-1.2-1.2-1.2h-5.5c-0.7,0-1.2,0.6-1.2,1.2v26.5h-3
      V15.9c0-0.7-0.6-1.2-1.2-1.2H6.6c-0.7,0-1.2,0.6-1.2,1.2v18c0,0.7,0.6,1.2,1.2,1.2h1H12h5.5H23h5.5h4.8H34c0.7,0,1.2-0.6,1.2-1.2V11
      C35.2,10.4,34.7,9.8,34,9.8z M7.8,32.7V17.2h3v15.5H7.8z M18.8,32.7V7.4h3v25.3H18.8z M29.8,32.7V12.3h3v20.4H29.8z"
    />
  </svg>,
);

export default SkholeIcon07;
