/* eslint-disable import/prefer-default-export */

import { CHANGE_PWA_OFF_ON_LINE } from '../constants';

export function togglePwaOffOnline(online) {
  return {
    type: CHANGE_PWA_OFF_ON_LINE,
    turnedOffPwaOffline: !online,
  };
}
