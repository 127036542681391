export default theme => ({
  popoverContent: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  popover: {
    borderRadius: 4,
    marginTop: 20,
    overflow: 'initial',
    '&::before': {
      display: 'block',
      content: '""',
      position: 'absolute',
      border: '10px solid transparent',
      borderBottom: '15px solid #fff',
    },
  },
  languagePopover: {
    borderRadius: 4,
    marginTop: 20,
    overflow: 'initial',
    '&::before': {
      display: 'block',
      content: '""',
      position: 'absolute',
      right: 60,
      top: -20,
      border: '10px solid transparent',
      borderBottom: '15px solid #fff',
    },
  },
  groupMenuItem: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: 24,
      paddingLeft: 24,
    },
  },
  groupMenuItemText: {
    fontSize: 17,
    color: theme.colors.primary,
    fontWeight: 'normal',
    paddingRight: 16,
    paddingLeft: 16,
    marginTop: 0,
    marginBottom: 0,
  },
});
