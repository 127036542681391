import React from 'react';
import { defineMessages } from 'react-intl';
// import { defineMessages } from 'react-intl';
import Layout from '../../components/Layout';
import NotAuthorized from './NotAuthorized';

const messages = defineMessages({
  notAuthorizedMessage: {
    id: 'NotAuthorized.notAuthorizedMessage',
    defaultMessage: 'Authorization is required',
  },
});

export default function action(context) {
  let title = 'Authorization is required';

  if (context && context.title) {
    title = context.title;
  } else if (context && context.intl) {
    title = context.intl.formatMessage(messages.notAuthorizedMessage);
  }

  return {
    // chunks: ['not-found'],
    title,
    component: (
      <Layout context={context}>
        <NotAuthorized title={title} />
      </Layout>
    ),
    status: (context && context.status) || 401,
  };
}
