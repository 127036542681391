// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".p5VSN{align-items:center;display:flex;margin-bottom:10px}@media(max-width: 767px){.p5VSN{flex-wrap:wrap}}.p5VSN>div{margin-left:10px;width:400px}@media(max-width: 767px){.p5VSN>div{margin-left:0;width:100%}}.Af7lz{margin-right:10px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogContent": "p5VSN",
	"addLinkBtn": "Af7lz"
};
module.exports = ___CSS_LOADER_EXPORT___;
