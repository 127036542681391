/* eslint-disable import/prefer-default-export */

import { SET_REGISTRATION_DATA, RESET_REGISTRATION_DATA } from '../constants';

export function setRegistrationForm(data) {
  return {
    type: SET_REGISTRATION_DATA,
    data,
  };
}

export function resetRegistrationForm() {
  return {
    type: RESET_REGISTRATION_DATA,
  };
}
