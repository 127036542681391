/* eslint-disable import/prefer-default-export */

import _ from 'lodash';

import { TOGGLE_TOUR } from '../constants';

import { loadAuth } from './auth';

export function toggleTour(name, isShown) {
  return async (dispatch, getState, { fetch }) => {
    const { auth } = getState();
    const isHelpButtonSeen = _.get(auth, `user.isHelpButtonSeen`, {});

    if (_.get(auth, 'user') && !isHelpButtonSeen[name] && isShown === false) {
      await fetch('/api/auth', {
        method: 'PUT',
        body: JSON.stringify({
          isHelpButtonSeen: { ...isHelpButtonSeen, [name]: true },
        }),
      })
        .then(() => dispatch(loadAuth()))
        .catch(console.error);
    }

    return dispatch({
      type: TOGGLE_TOUR,
      isShown,
      name,
    });
  };
}
