import {
  GET_COURSE_REVIEW,
  GET_COURSE_REVIEW_SUCCESS,
  GET_COURSE_REVIEW_FAIL,
} from '../constants';

const initialState = { loading: false, review: null, error: null };

export default function getCourseReview(state = initialState, action) {
  switch (action.type) {
    case GET_COURSE_REVIEW:
      return {
        ...initialState,
        loading: true,
      };
    case GET_COURSE_REVIEW_SUCCESS:
      return {
        ...state,
        review: action.result.json,
        loading: false,
      };
    case GET_COURSE_REVIEW_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
