import { logout } from '../actions/auth';

export const cleanCookies = ({
  cookiePrefix,
  cookiesInstance,
  cookieNamesToRemove,
}) => {
  cookieNamesToRemove.forEach(item =>
    cookiesInstance.remove(`${cookiePrefix}${item}`, {
      path: '/',
      domain: window.App.cookieDomain,
    }),
  );
};

export const logoutUser = async ({
  dispatch,
  cookiePrefix,
  cookiesInstance,
}) => {
  await dispatch(logout());

  cleanCookies({
    cookiePrefix,
    cookiesInstance,
    cookieNamesToRemove: [
      'refreshToken',
      'token',
      'tempAdminToken',
      'contentTypeSwitchers',
      'isDashboardShown',
      'chooseMainGroupToken',
    ],
  });
};
