import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import SvgIcon from '@material-ui/core/SvgIcon';

import ContextProvider from '../../../context';

import s from './Icons.scss';

export const VideoNoteIcon = withStyles(s)(() => (
  <div className={s.videoNoteIcon} />
));

export const Icon = svg => {
  const IconWithStyle = ({
    color,
    style,
    disabled,
    defaultColor,

    ...rest
  }) => {
    const { whitelabeling } = useContext(ContextProvider);
    const getColor = c => {
      if (c === 'primary') {
        return whitelabeling.primaryColor;
      }

      return c;
    };

    return (
      <SvgIcon
        style={{
          ...style,
          color: disabled ? getColor(defaultColor) : getColor(color),
        }}
        {...rest}
      >
        {svg}
      </SvgIcon>
    );
  };

  IconWithStyle.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object, // eslint-disable-line
    disabled: PropTypes.bool,
    defaultColor: PropTypes.string,
  };

  IconWithStyle.defaultProps = {
    color: 'primary',
    style: {},
    disabled: false,
    defaultColor: '',
  };
  return IconWithStyle;
};

Icon.propTypes = {
  svg: PropTypes.element.isRequired,
};

export const EditIcon = withStyles(s)(({ disabled }) => {
  const SkholeIcon32 = Icon(
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 40 40"
      style={{ enableBackground: 'new 0 0 40 40' }}
      xmlSpace="preserve"
    >
      <path
        className="st1"
        d="M36.4,9.5l-6-6c-0.5-0.5-1.3-0.5-1.8,0l-4.2,4.2L7.5,24.6c0,0,0,0,0,0c-0.1,0.1-0.2,0.2-0.2,0.3
    c0,0,0,0.1,0,0.1c0,0,0,0,0,0L4.3,34c-0.1,0.4,0,0.9,0.3,1.3c0.2,0.2,0.6,0.4,0.9,0.4c0.1,0,0.3,0,0.4-0.1l8.9-2.9c0,0,0,0,0,0
    c0,0,0.1,0,0.1,0c0.1-0.1,0.2-0.1,0.3-0.2c0,0,0,0,0,0l16.9-16.9l4.2-4.2C36.9,10.8,36.9,10,36.4,9.5z M7.5,32.5L9,27.8l3.1,3.1
    L7.5,32.5z M14.4,29.7l-4.2-4.2l15.1-15.1l4.2,4.2L14.4,29.7z M31.3,12.9L27,8.6l2.5-2.5l4.2,4.2L31.3,12.9z"
      />
    </svg>,
  );
  return (
    <SkholeIcon32 className={s.editIcon} color={disabled ? '' : 'primary'} />
  );
});

export const RemoveIcon = withStyles(s)(({ disabled }) => {
  const SkholeIcon13 = Icon(
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 40 40"
      style={{ enableBackground: 'new 0 0 40 40' }}
      xmlSpace="preserve"
    >
      <g>
        <path
          className="st1"
          d="M31,7.7h-0.6V5.2c0-0.7-0.6-1.2-1.2-1.2H10.8c-0.7,0-1.2,0.6-1.2,1.2v2.4H9c-0.7,0-1.2,0.6-1.2,1.2v3.7
      c0,0.6,0.5,1.2,1.1,1.2l0.7,16.4c0,3.2,2.6,5.8,5.8,5.8h9.3c3.2,0,5.8-2.6,5.8-5.8L31,13.9h0c0.7,0,1.2-0.6,1.2-1.2V8.9
      C32.2,8.2,31.7,7.7,31,7.7z M10.2,10.2h0.6h18.3h0.6v1.2H10.2V10.2z M12.1,6.5h15.8v1.2H12.1V6.5z M27.9,30.2
      c0,1.8-1.5,3.3-3.3,3.3h-9.3c-1.8,0-3.3-1.5-3.3-3.4l-0.7-16.3h17.1L27.9,30.2z"
        />
        <path
          className="st1"
          d="M15.4,28.6c0.7,0,1.2-0.6,1.2-1.2v-9.9c0-0.7-0.6-1.2-1.2-1.2s-1.2,0.6-1.2,1.2v9.9
      C14.2,28.1,14.7,28.6,15.4,28.6z"
        />
        <path
          className="st1"
          d="M20,28.6c0.7,0,1.2-0.6,1.2-1.2v-9.9c0-0.7-0.6-1.2-1.2-1.2s-1.2,0.6-1.2,1.2v9.9C18.8,28.1,19.3,28.6,20,28.6
      z"
        />
        <path
          className="st1"
          d="M24.6,28.6c0.7,0,1.2-0.6,1.2-1.2v-9.9c0-0.7-0.6-1.2-1.2-1.2s-1.2,0.6-1.2,1.2v9.9
      C23.3,28.1,23.9,28.6,24.6,28.6z"
        />
      </g>
    </svg>,
  );
  return (
    <SkholeIcon13 className={s.removeIcon} color={disabled ? '' : 'primary'} />
  );
});

export const CopyIcon = withStyles(s)(({ disabled, color }) => {
  const SkholeIcon31 = Icon(
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 40 40"
      style={{ enableBackground: 'new 0 0 40 40' }}
      xmlSpace="preserve"
    >
      <path
        className="st1"
        d="M35.5,10.2h-9.8V4.4c0-0.7-0.6-1.2-1.2-1.2h-11c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0.1c0,0-0.1,0-0.1,0
    c-0.1,0.1-0.3,0.1-0.4,0.2l-9,9c-0.1,0.1-0.2,0.2-0.2,0.4c0,0,0,0.1,0,0.1c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0.1,0,0.1V28
    c0,0.7,0.6,1.2,1.2,1.2h9.8V35c0,0.7,0.6,1.2,1.2,1.2h20c0.7,0,1.2-0.6,1.2-1.2V11.4C36.8,10.7,36.2,10.2,35.5,10.2z M12.2,7.4v4.7
    H7.5L12.2,7.4z M5.8,26.8V14.7h7.8c0.7,0,1.2-0.6,1.2-1.2V5.7h8.5v5.2l-8.6,8.6c-0.1,0.1-0.2,0.2-0.2,0.4c0,0,0,0.1,0,0.1
    c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0.1,0,0.1v6.4H5.8z M23.2,14.4v4.7h-4.7L23.2,14.4z M34.2,33.8H16.8V21.7h7.8c0.7,0,1.2-0.6,1.2-1.2
    v-7.8h8.5V33.8z"
      />
    </svg>,
  );
  return (
    <SkholeIcon31
      className={s.copyIcon}
      color={disabled ? '' : color || 'primary'}
    />
  );
});

export const ViewIcon = withStyles(s)(({ disabled }) => {
  const SkholeIcon33 = Icon(
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 40 40"
      style={{ enableBackground: 'new 0 0 40 40' }}
      xmlSpace="preserve"
    >
      <g>
        <path
          className="st1"
          d="M36.9,19.5c-0.3-0.4-6.4-10.6-16.7-10.6S3.8,19.1,3.6,19.5c-0.2,0.4-0.2,0.9,0,1.3c0.3,0.4,6.4,10.6,16.7,10.6
      s16.4-10.2,16.7-10.6C37.2,20.4,37.2,19.9,36.9,19.5z M20.2,28.9c-7.5,0-12.7-6.6-14.1-8.8c1.5-2.1,6.7-8.8,14.1-8.8
      s12.7,6.6,14.1,8.8C32.9,22.3,27.7,28.9,20.2,28.9z"
        />
        <path
          className="st1"
          d="M20.2,13.7c-3.6,0-6.5,2.9-6.5,6.5s2.9,6.5,6.5,6.5s6.5-2.9,6.5-6.5S23.8,13.7,20.2,13.7z M20.2,24.2
      c-2.2,0-4-1.8-4-4s1.8-4,4-4s4,1.8,4,4S22.5,24.2,20.2,24.2z"
        />
      </g>
    </svg>,
  );
  return (
    <SkholeIcon33
      defaultColor="#c3c6ce"
      disabled={disabled}
      className={s.viewIcon}
    />
  );
});
