import {
  PODCAST_LESSON_SET_EXPANDED_LESSON,
  PODCAST_LESSON_SET_NEXT_LESSON,
  PODCAST_LESSON_SET_IS_LOADING,
  PODCAST_LESSON_RESET,
  PODCAST_LESSON_SET_PLAY_PODCAST_AUDIO,
} from '../constants';

const initialState = {
  isPodcastLessonLoading: false,
  openedPodcastLesson: null,
  playPodcastAudio: false,
  shouldScrollIntoView: false,
  nextLesson: {},
};

export default function podcastLesson(state = initialState, action) {
  switch (action.type) {
    case PODCAST_LESSON_SET_EXPANDED_LESSON:
      return {
        ...state,
        openedPodcastLesson: action.openedPodcastLesson,
        playPodcastAudio: action.playPodcastAudio,
        shouldScrollIntoView: action.shouldScrollIntoView,
      };
    case PODCAST_LESSON_SET_NEXT_LESSON:
      return {
        ...state,
        nextLesson: action.data,
      };
    case PODCAST_LESSON_SET_IS_LOADING:
      return {
        ...state,
        isPodcastLessonLoading: action.isLoading,
      };
    case PODCAST_LESSON_SET_PLAY_PODCAST_AUDIO:
      return {
        ...state,
        playPodcastAudio: action.playPodcastAudio,
      };
    case PODCAST_LESSON_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
