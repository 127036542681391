import React from 'react';
import { defineMessages } from 'react-intl';
import Layout from '../../components/Layout';
import NotFound from './NotFound';

const messages = defineMessages({
  notFoundTitle: {
    id: 'NotFound.notFoundTitle',
    defaultMessage: 'Page Not Found',
    description: 'Title displayed in not found page',
  },
});

export default function action(context) {
  let title = 'Page Not Found';

  if (context && context.title) {
    title = context.title;
  } else if (context && context.intl) {
    title = context.intl.formatMessage(messages.notFoundTitle);
  }

  return {
    // chunks: ['not-found'],
    title,
    component: (
      <Layout context={context}>
        <NotFound title={title} />
      </Layout>
    ),
    status: (context && context.status) || 404,
  };
}
