// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dqbUQ{height:26px;width:27px}.nklKf{height:25px;width:25px}.oRVzb{height:26px;width:30px}.P83ws{height:26px;width:30px}.zGa_d{height:24px;width:24px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"copyIcon": "dqbUQ",
	"editIcon": "nklKf",
	"viewIcon": "oRVzb",
	"removeIcon": "P83ws",
	"videoNoteIcon": "zGa_d"
};
module.exports = ___CSS_LOADER_EXPORT___;
