import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import React from 'react';
import PropTypes from 'prop-types';
import Fab from '@material-ui/core/Fab';
import cx from 'classnames';

import { connect } from 'react-redux';

import withStyles from 'isomorphic-style-loader/withStyles';
import { bindActionCreators } from 'redux';
import * as tourActions from '../../../../actions/tours';
import { isTourShown } from '../../../../helpers';

import s from './HelpButton.scss';
import QuestionSolidIcon from '../../Icons/QuestionSolidIcon';
import TooltipButton from '../../Tooltip/TooltipButton';

@withWidth()
@withStyles(s)
@connect(
  (state, props) => ({
    auth: state.auth,
    isTourOpen: isTourShown(state, props.tour),
  }),
  dispatch =>
    bindActionCreators(
      {
        toggleTour: tourActions.toggleTour,
      },
      dispatch,
    ),
)
export default class Index extends React.Component {
  static propTypes = {
    auth: PropTypes.object, // eslint-disable-line
    tour: PropTypes.string.isRequired,
    tourComponent: PropTypes.node.isRequired,
    width: PropTypes.number,
    inverted: PropTypes.bool,
    classes: PropTypes.shape({
      button: PropTypes.shape({}),
    }),
    isTourOpen: PropTypes.bool,
    toggleTour: PropTypes.func,
  };

  static defaultProps = {
    inverted: false,
    classes: {},
    isTourOpen: false,
    width: null,
    toggleTour: () => false,
  };

  render() {
    const TourComponent = this.props.tourComponent;
    const {
      width,
      inverted,
      classes,
      isTourOpen,
      tour,
      toggleTour,
      ...rest
    } = this.props;

    if (!isWidthUp('sm', width)) {
      return null;
    }
    return (
      <>
        <div className={s.helpModalBtn}>
          <TooltipButton message="helpButton">
            <Fab
              classes={{
                root: cx(s.mobileViewHelpButton, classes.button),
              }}
              onClick={() => toggleTour(tour, true)}
              size="small"
            >
              <QuestionSolidIcon
                color={inverted ? '#fff' : 'primary'}
                className={s.helpIcon}
              />
            </Fab>
          </TooltipButton>
        </div>
        <TourComponent
          isOpen={isTourOpen}
          onRequestClose={() => toggleTour(tour, false)}
          {...rest}
        />
      </>
    );
  }
}
