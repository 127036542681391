import { defineMessages } from 'react-intl';

const messages = defineMessages({
  modalTitle: {
    id: 'TwoFaSetupModal.modalTitle',
    defaultMessage: 'Two-Factor Authentication (2FA) verification',
  },
  verifyCodeLabel: {
    id: 'TwoFaSetupModal.verifyCodeLabel',
    defaultMessage: 'Verification Code',
  },
  saveBtnTooltip: {
    id: 'TwoFaSetupModal.saveBtnTooltip',
    defaultMessage: 'Verify',
  },

  successSnackbarText: {
    id: 'TwoFaSetupModal.successSnackbarText',
    defaultMessage: 'Code has been successfully verified',
  },
  keepYourAccountSecureText: {
    id: 'TwoFaSetupModal.keepYourAccountSecureText',
    defaultMessage: 'To keep your account secure, we verify your identity.',
  },
  callToActionText: {
    id: 'TwoFaSetupModal.callToActionText',
    defaultMessage:
      'Enter the code generated by your <strong>authenticator app</strong>.',
  },
  accountLabel: {
    id: 'TwoFaSetupModal.accountLabel',
    defaultMessage: 'Account',
  },

  chooseAnotherTwoFaMethodBtnLabel: {
    id: 'TwoFaSetupModal.chooseAnotherTwoFaMethodBtnLabel',
    defaultMessage: 'Choose another verification method',
  },
});

export default messages;
