import moment from 'moment';

import {
  GET_SUBGROUP,
  GET_SUBGROUP_SUCCESS,
  GET_SUBGROUP_FAIL,
  GET_SUBGROUP_TEACHERS,
  GET_SUBGROUP_TEACHERS_SUCCESS,
  GET_SUBGROUP_TEACHERS_FAIL,
  SET_SUBGROUP,
  RESET_SUBGROUP,
} from '../constants';

export default function editSubgroup(
  state = { loading: false, loadingTeachers: false, group: {} },
  action,
) {
  switch (action.type) {
    case GET_SUBGROUP:
      return {
        ...state,
        loading: true,
      };
    case GET_SUBGROUP_SUCCESS: {
      const group = action.result.json;

      if (group && group.expirationDate) {
        group.expirationDate = moment(group.expirationDate).format(
          'YYYY-MM-DD',
        );
      }

      return {
        ...state,
        group,
        loading: false,
      };
    }
    case GET_SUBGROUP_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_SUBGROUP_TEACHERS:
      return {
        ...state,
        loadingTeachers: true,
      };
    case GET_SUBGROUP_TEACHERS_SUCCESS: {
      const teachers = action.result.json;

      return {
        ...state,
        group: {
          ...state.group,
          teachers,
        },
        loadingTeachers: false,
      };
    }
    case GET_SUBGROUP_TEACHERS_FAIL:
      return {
        ...state,
        loadingTeachers: false,
      };
    case SET_SUBGROUP:
      return {
        ...state,
        group: action.subgroup,
      };
    case RESET_SUBGROUP:
      return {
        ...state,
        group: {},
      };
    default:
      return state;
  }
}
