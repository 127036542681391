import _ from 'lodash';
import isMobileUserAgent from '../helpers/checkMobileUserAgent';
import {
  userRole,
  INTERCOM_DEFAULT_HORIZONTAL_PADDING,
  INTERCOM_DEFAULT_VERTICAL_PADDING,
} from '../constants';
import getIntercomPaddings from '../helpers/getIntercomPaddings';

export default {
  boot: (user, whitelabeling) => {
    setTimeout(() => {
      /* eslint-disable */
      (function () {
        var w = window; var ic = w.Intercom;
        if (typeof ic === "function") {
          ic('reattach_activator');
          ic('update', intercomSettings);
        } else {
          var d = document;
          var i = function () {
            i.c(arguments)
          };
          i.q = []; i.c = function (args) { i.q.push(args) };
          w.Intercom = i;
          function l() {
            var s = d.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = `https://widget.intercom.io/widget/${window.App.intercomId}`;
            var x = d.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);
          }
          l();
        }
      })();
      /* eslint-enable */

      window.intercomSettings = {
        app_id: window.App.intercomId,
        language_override: window.App.lang.slice(0, 2),
        hide_default_launcher: false,
      };

      if (typeof window !== 'undefined') {
        const currentURL = window.location.pathname;

        const matchingPosition = getIntercomPaddings(currentURL);

        if (matchingPosition) {
          window.intercomSettings.horizontal_padding =
            matchingPosition.HORIZONTAL_PADDING;
          window.intercomSettings.vertical_padding =
            matchingPosition.VERTICAL_PADDING;
        } else {
          window.intercomSettings.horizontal_padding = INTERCOM_DEFAULT_HORIZONTAL_PADDING;
          window.intercomSettings.vertical_padding = INTERCOM_DEFAULT_VERTICAL_PADDING;
        }

        const intercomActionColor = _.get(whitelabeling, 'intercomActionColor');
        const intercomBackgroundColor = _.get(
          whitelabeling,
          'intercomBackgroundColor',
        );

        if (intercomActionColor) {
          window.intercomSettings.action_color = intercomActionColor;
        }

        if (intercomBackgroundColor) {
          window.intercomSettings.background_color = intercomBackgroundColor;
        }
      }

      if (!user || !user.id) {
        window.Intercom('boot');
        return;
      }

      const isUserAdmin =
        document.cookie.indexOf('tempAdminToken') !== -1 ||
        user.role === userRole.ADMIN;

      if (isUserAdmin) {
        window.intercomSettings.hide_default_launcher = true;
        window.Intercom('boot');
        return;
      }

      window.intercomSettings.user_id = user.id;
      window.intercomSettings.email = user.email;
      window.intercomSettings.name = `${user.firstName} ${user.lastName}`;
      window.intercomSettings.user_hash = user.hmac; // HMAC using SHA-256
      const companyId = _.get(user, 'mainGroup.pipedriveId');
      const companyName = _.get(user, 'mainGroup.name');

      if (companyId) {
        window.intercomSettings.company = {
          id: companyId,
          name: companyName,
        };
      }

      window.Intercom('boot');
    }, 5000);
  },
  rebootMobile: () => {
    const isMobile = isMobileUserAgent();
    if (isMobile) {
      window.intercomSettings.hide_default_launcher = false;
      if (!_.isFunction(window.Intercom)) {
        return;
      }
      window.Intercom('update', window.intercomSettings);
    }
  },
  setNewLang: lang => {
    window.intercomSettings.language_override = lang;
    if (!_.isFunction(window.Intercom)) {
      return;
    }
    window.Intercom('update');
  },
  showMessenger: () => {
    if (!_.isFunction(window.Intercom)) {
      return;
    }
    window.Intercom('show');
  },
  hideMessenger: () => {
    if (!_.isFunction(window.Intercom)) {
      return;
    }
    window.Intercom('hide');
  },
  getVisitorId: () => window.Intercom('getVisitorId'),
  update: () => window.Intercom('update'),
  changeSettings: ({
    language_override = 'en-US', // eslint-disable-line camelcase
    user_id = '', // eslint-disable-line camelcase
    // user_hash: INSERT_HMAC_VALUE_HERE // HMAC using SHA-256
    hide_default_launcher = true, // eslint-disable-line camelcase
    ...rest
  }) => {
    window.intercomSettings = {
      ...window.intercomSettings,
      language_override, // eslint-disable-line camelcase
      user_id, // eslint-disable-line camelcase
      hide_default_launcher, // eslint-disable-line camelcase
      ...rest,
    };
  },
  changeWidgetPosition: path => {
    const matchingPosition = getIntercomPaddings(path);

    if (matchingPosition) {
      window.intercomSettings.horizontal_padding =
        matchingPosition.HORIZONTAL_PADDING;
      window.intercomSettings.vertical_padding =
        matchingPosition.VERTICAL_PADDING;
    } else {
      window.intercomSettings.horizontal_padding = INTERCOM_DEFAULT_HORIZONTAL_PADDING;
      window.intercomSettings.vertical_padding = INTERCOM_DEFAULT_VERTICAL_PADDING;
    }

    const intercomEl = document.getElementsByClassName(
      'intercom-lightweight-app-launcher',
    );

    if (intercomEl.length > 0) {
      const firstElement = intercomEl[0];

      firstElement.style.transition =
        'bottom 0.5s ease-in-out, right 0.5s ease-in-out';
    }

    if (!_.isFunction(window.Intercom)) {
      return;
    }
    window.Intercom('update');
  },
  hideWidget: () => {
    window.intercomSettings.hide_default_launcher = true;

    if (!_.isFunction(window.Intercom)) {
      return;
    }

    window.Intercom('update');
  },
  hideWidgetButton: () => {
    if (window.intercomSettings.hide_default_launcher) return;
    document.getElementById('intercom-container').style.display = 'none';
  },
  displayWidgetButton: () => {
    if (window.intercomSettings.hide_default_launcher) return;
    document.getElementById('intercom-container').style.display = 'block';
  },
  logout: () => {
    if (_.isFunction(window.Intercom)) {
      window.Intercom('shutdown');
    }

    if (window.intercomSettings) {
      delete window.intercomSettings.user_id;
      delete window.intercomSettings.email;
      delete window.intercomSettings.name;
      delete window.intercomSettings.user_hash;
      const isRoot =
        location && // eslint-disable-line no-restricted-globals
        [
          '/',
          '/en-US',
          '/fi-FI',
          '/sv-SE',
          '/en-US',
          '/fi-FI',
          '/sv-SE',
        ].includes(location.pathname); // eslint-disable-line no-restricted-globals
      window.intercomSettings.hide_default_launcher = isRoot
        ? false
        : isMobileUserAgent();
    }

    if (!_.isFunction(window.Intercom)) {
      return;
    }

    window.Intercom('boot', {
      app_id: window.intercomSettings.app_id,
    });
    window.Intercom('update', {
      app_id: window.intercomSettings.app_id,
    });
  },
  login: user => {
    if (!user || !user._id) {
      return;
    }

    if (!_.isFunction(window.Intercom)) {
      return;
    }

    if (user.role === userRole.ADMIN) {
      window.Intercom('shutdown');
      window.intercomSettings.hide_default_launcher = true;
    } else {
      window.intercomSettings.user_id = user.id;
      window.intercomSettings.email = user.email;
      window.intercomSettings.name = `${user.firstName} ${user.lastName}`;
      window.intercomSettings.user_hash = user.hmac; // HMAC using SHA-256
      const companyId = _.get(user, 'mainGroup.pipedriveId');
      const companyName = _.get(user, 'mainGroup.name');

      if (companyId) {
        window.intercomSettings.company = {
          id: companyId,
          name: companyName,
        };
      }
    }
    window.Intercom('update', window.intercomSettings);
  },
};
