import React from 'react';
import { Icon } from './index';

const SkholeIcon01 = Icon(
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 40 40"
    style={{ enableBackground: 'new 0 0 40 40' }}
    xmlSpace="preserve"
  >
    <path
      className="st1"
      d="M22.5,1.9c-8.8,0-16,7.2-16,16c0,3.2,0.9,6.1,2.5,8.6c-0.2,0.1-0.4,0.3-0.5,0.4l-6,6c-1.4,1.4-1.4,3.6,0,5
	c0.7,0.7,1.6,1,2.5,1s1.8-0.3,2.5-1l6-6c0.2-0.2,0.3-0.3,0.4-0.5c2.5,1.6,5.4,2.5,8.6,2.5c8.8,0,16-7.2,16-16S31.3,1.9,22.5,1.9z
	 M22.5,29.9c-6.6,0-12-5.4-12-12s5.4-12,12-12s12,5.4,12,12S29.1,29.9,22.5,29.9z"
    />
  </svg>,
);

export default SkholeIcon01;
