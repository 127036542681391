import {
  GET_GROUP_TASKS,
  GET_GROUP_TASKS_SUCCESS,
  GET_GROUP_TASKS_FAIL,
  RESET_GROUP_TASKS,
} from '../constants';

export default function groupTasks(
  state = { loading: false, loaded: false, groupTasks: [], total: 0 },
  action,
) {
  switch (action.type) {
    case GET_GROUP_TASKS:
      return {
        ...state,
        loading: true,
      };
    case GET_GROUP_TASKS_SUCCESS:
      return {
        ...state,
        groupTasks: action.result.json,
        total: action.result.total,
        loading: false,
        loaded: true,
      };
    case GET_GROUP_TASKS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case RESET_GROUP_TASKS:
      return {
        ...state,
        loaded: false,
        groupTasks: [],
      };
    default:
      return state;
  }
}
