import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import classNames from 'classnames';
import Modal from '@material-ui/core/Modal';
import CircularProgress from '../CircularProgress';

import s from './modal.scss';

@withStyles(s)
class CustomModal extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    isOpen: PropTypes.bool.isRequired,
    padding: PropTypes.bool,
    isLoaderOn: PropTypes.bool,
    disableBackdropClick: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClose: PropTypes.func,
    classes: PropTypes.shape({
      paper: PropTypes.string,
    }),
  };

  static defaultProps = {
    disableBackdropClick: true,
    padding: true,
    isLoaderOn: false,
    width: '',
    onClose: () => {},
    classes: {},
  };

  static childContextTypes = {
    onEscapePress: PropTypes.func,
  };

  state = {
    isEscapeDisabled: false,
  };

  getChildContext() {
    return {
      onEscapePress: this.setOnEscapePress,
    };
  }

  onEscapeKeyHandler = () => {
    this.checkAndCloseForm();
    this.setState({ isEscapeDisabled: true });
  };

  setOnEscapePress = check => {
    this.checkAndCloseForm = check;
  };

  checkAndCloseForm = () => true;

  handleModalClose = () => {
    this.checkAndCloseForm();
  };

  render() {
    const {
      children,
      isOpen,
      classes = {},
      padding = true,
      width = '80%',
      isLoaderOn,
      disableBackdropClick,
      ...rest
    } = this.props;

    const { isEscapeDisabled } = this.state;

    return (
      <Modal
        open={isOpen}
        onClose={this.handleModalClose}
        onEscapeKeyDown={this.onEscapeKeyHandler}
        disableEscapeKeyDown={isEscapeDisabled}
        disableBackdropClick={disableBackdropClick}
        {...rest}
      >
        <div
          className={classNames(
            { [classes.paper]: !!classes.paper },
            { [s.loading]: isLoaderOn },
            s.paper,
            { [s.paddingNone]: !padding && !isLoaderOn },
          )}
          style={{ width }}
        >
          {isLoaderOn ? (
            <div className={s.loadingWrapper}>
              <CircularProgress size={100} />
            </div>
          ) : (
            children
          )}
        </div>
      </Modal>
    );
  }
}

export default function SkholeModal(props) {
  return props.isOpen || props.keepMounted ? <CustomModal {...props} /> : null;
}

SkholeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  keepMounted: PropTypes.bool,
};

SkholeModal.defaultProps = {
  keepMounted: false,
};
