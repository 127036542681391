import {
  SAVE_AUTH,
  LOGOUT_AUTH,
  SAVE_FAVORITE_COURSES,
  SAVE_FOLLOWED_DISCUSSIONS,
  GET_MY_CERTIFICATES,
  GET_MY_CERTIFICATES_SUCCESS,
  GET_MY_CERTIFICATES_FAIL,
  RESET_MY_CERTIFICATES,
  GET_MY_COURSES,
  GET_MY_COURSES_SUCCESS,
  GET_MY_COURSES_FAIL,
  GET_STATISTIC_INFO_REQUEST,
  GET_STATISTIC_INFO_SUCCESS,
  GET_STATISTIC_INFO_FAIL,
  RESET_MY_COURSES,
  GET_DASHBOARD_DATA,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_DASHBOARD_DATA_FAIL,
  RESET_DASHBOARD_DATA,
} from '../constants';

export default function auth(
  state = {
    user: null,
    coursesIsLoaded: false,
    favoriteCourses: [],
    certificates: [],
    followedDiscussions: [],
    loadingCertificates: false,
    courses: [],
    totalCourses: 0,
    loadingCourses: false,
    loadingInfo: false,
    statistic: {},
    dashboardData: {},
    loadingDashboardData: false,
    loadedDashboardData: false,
  },
  action,
) {
  switch (action.type) {
    case SAVE_AUTH:
      return {
        ...state,
        user: action.payload,
      };
    case SAVE_FAVORITE_COURSES:
      return {
        ...state,
        favoriteCourses: action.favoriteCourses,
      };
    case SAVE_FOLLOWED_DISCUSSIONS:
      return {
        ...state,
        followedDiscussions: action.followedDiscussions,
      };
    case LOGOUT_AUTH:
      return {
        ...state,
        user: null,
      };
    case GET_MY_CERTIFICATES:
      return {
        ...state,
        loadingCertificates: true,
      };
    case GET_MY_CERTIFICATES_SUCCESS:
      return {
        ...state,
        certificates: action.result.json,
        loadingCertificates: false,
      };
    case GET_MY_CERTIFICATES_FAIL:
      return {
        ...state,
        loadingCertificates: false,
        error: action.error,
      };
    case RESET_MY_CERTIFICATES:
      return {
        ...state,
        certificates: [],
        loadingCertificates: false,
      };
    case GET_MY_COURSES:
      return {
        ...state,
        loadingCourses: true,
      };
    case GET_MY_COURSES_SUCCESS:
      return {
        ...state,
        courses: [].concat(state.courses, action.result.json),
        totalCourses: action.result.total,
        loadingCourses: false,
        coursesIsLoaded: true,
      };
    case GET_MY_COURSES_FAIL:
      return {
        ...state,
        loadingCourses: false,
        error: action.error,
        coursesIsLoaded: true,
      };
    case RESET_MY_COURSES:
      return {
        ...state,
        courses: [],
        coursesIsLoaded: false,
      };
    case GET_STATISTIC_INFO_REQUEST:
      return {
        ...state,
        loadingInfo: true,
      };
    case GET_STATISTIC_INFO_SUCCESS:
      return {
        ...state,
        statistic: action.result.json,
        loadingInfo: false,
      };
    case GET_STATISTIC_INFO_FAIL:
      return {
        ...state,
        loadingInfo: false,
        error: action.error,
      };
    case GET_DASHBOARD_DATA:
      return {
        ...state,
        loadingDashboardData: true,
        loadedDashboardData: false,
      };
    case GET_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        dashboardData: action.result.json,
        loadingDashboardData: false,
        loadedDashboardData: true,
      };
    case GET_DASHBOARD_DATA_FAIL:
      return {
        ...state,
        loadingDashboardData: false,
        error: action.error,
      };
    case RESET_DASHBOARD_DATA:
      return {
        ...state,
        dashboardData: [],
      };
    default:
      return state;
  }
}
