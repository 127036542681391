import 'whatwg-fetch';
import _ from 'lodash';
import cookie from 'cookie';
// if some problem wiil be on IE9, instead of all polyfills except of intl and abort controller, could be added one from
// https://github.com/facebook/create-react-app/blob/master/packages/react-app-polyfill/README.md
import React from 'react';
import ReactDOM from 'react-dom';
import deepForceUpdate from 'react-deep-force-update';
import queryString from 'query-string';
import { createPath } from 'history/PathUtils';
import { addLocaleData } from 'react-intl';
import { CookiesProvider, withCookies } from 'react-cookie';
import smoothscroll from 'smoothscroll-polyfill';
import safeJsonStringify from 'safe-json-stringify';
import Intl from 'intl';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Provider as ReactReduxProvider } from 'react-redux';
import StyleContext from 'isomorphic-style-loader/StyleContext';
import 'core-js';
import 'regenerator-runtime/runtime';

// This is so bad: requiring all locale if they are not needed?
/* @intl-code-template import ${lang} from 'react-intl/locale-data/${lang}'; */
import en from 'react-intl/locale-data/en';
import fi from 'react-intl/locale-data/fi';
import sv from 'react-intl/locale-data/sv';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/fi';
import 'moment/locale/sv';

/* @intl-code-template-end */
import 'logdna.js';

import App from './components/App';
import createLogger from './createLogger';
import createFetch from './createFetch';
import LoggerProvider from './decorators/LoggerProvider';
import { buildQuery } from './helpers';
import configureStore from './store/configureStore';
import { updateMeta, updateCustomMeta, updateLink } from './DOMUtils';
import history from './history';
import router from './router';
import { getIntl, setLocale } from './actions/intl';
import theme from './materialUiTheme';
import ContextProvider from './context';
import {
  getTableFilterAutocompleteStyles,
  getPrimaryAutocompleteStyles,
} from './components/Autocomplete/Autocomplete';
import materialUiThemeColorFromSlug from './helpers/materialUiThemeColorFromSlug';

if (!window.Intl) {
  window.Intl = Intl;
}

smoothscroll.polyfill();

/* @intl-code-template addLocaleData(${lang}); */
addLocaleData(en);
addLocaleData(fi);
addLocaleData(sv);
/* @intl-code-template-end */

const logger = createLogger(window.logdna, window.App.logdna.apiKey, {
  hostname: window.location.hostame,
  app: window.App.logdna.app,
  index_meta: true,
});

// Universal HTTP client
const fetch = createFetch(window.fetch, {
  baseUrl: window.App.apiUrl,
  serverUrl: window.App.serverUrl,
  cloudfrontUrl: window.App.cloudfrontUrl,
  organization: window.App.organizationSlug,
  apiVersion: window.App.apiVersion,
});

// Initialize a new Redux store
// http://redux.js.org/docs/basics/UsageWithReact.html
const store = configureStore(window.App.state, {
  fetch,
  history,
});
window.store = store;

// Global (context) variables that can be easily accessed from any React component
// https://facebook.github.io/react/docs/context.html
const context = {
  // Enables critical path CSS rendering
  // https://github.com/kriasoft/isomorphic-style-loader
  insertCss: (...styles) => {
    // eslint-disable-next-line no-underscore-dangle
    const removeCss = styles.map(x => x._insertCss());
    return () => {
      removeCss.forEach(f => f());
    };
  },
  // Universal HTTP client
  fetch,
  // intl instance as it can be get with injectIntl
  intl: store.dispatch(getIntl()),
  logger,
  cloudfrontUrl: window.App.cloudfrontUrl,
  apiUrl: window.App.apiUrl,
  cookiePrefix: window.App.cookiePrefix,
  whitelabeling: window.App.whitelabeling,
  organizationSlug: window.App.organizationSlug,
  tableFilterAutocompleteStyles: getTableFilterAutocompleteStyles(
    window.App.whitelabeling,
  ),
  primaryAutocompleteStyles: getPrimaryAutocompleteStyles(
    window.App.whitelabeling,
  ),
  vimeoLivestreamUrl: window.App.vimeoLivestreamUrl,
  apiVersion: window.App.apiVersion,
  contentLangs: window.App.contentLangs,
  twoFaDisabled: window.App.twoFaDisabled,
};

const container = document.getElementById('app');
let currentLocation = history.location;
let appInstance;

const scrollPositionsHistory = {};

@withCookies
class Main extends React.Component {
  // Remove the server-side injected CSS.
  componentDidMount() {
    const jssStyles = document.getElementById('jss-server-side');
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
  }

  render() {
    return <App {...this.props} />;
  }
}

// Re-render the app when window.location changes
async function onLocationChange(location, action) {
  window.isAppReady = false;
  // Remember the latest scroll position for the previous location
  scrollPositionsHistory[currentLocation.key] = {
    scrollX: window.pageXOffset,
    scrollY: window.pageYOffset,
  };
  // Delete stored scroll position for next page if any
  if (action === 'PUSH') {
    delete scrollPositionsHistory[location.key];
  }
  currentLocation = location;

  context.intl = store.dispatch(getIntl());

  const isInitialRender = !action;
  try {
    let { locale } = store.getState().intl;

    const locales = ['en-US', 'sv-SE', 'fi-FI'];

    let pathSlices = location.pathname.split('/');
    const newLocale = pathSlices[1];

    if (!_.includes(locales, newLocale)) {
      pathSlices.splice(1, 0, locale);

      pathSlices = _.reject(pathSlices, (value, index) => index && !value);

      const q = _.keys(location.search).length
        ? `?${buildQuery(location.search)}`
        : '';
      const redirect = `${pathSlices.join('/')}${q}`;

      history.replace(redirect);
      return;
    }

    if (locale !== newLocale) {
      locale = newLocale;
      await store.dispatch(setLocale({ locale: newLocale, redirect: false }));
    }

    context.intl = await store.dispatch(getIntl());
    const cookies = cookie.parse(document.cookie);
    context.token = cookies[`${window.App.cookiePrefix}token`];

    // Traverses the list of routes in the order they are defined until
    // it finds the first route that matches provided URL path string
    // and whose action method returns anything other than `undefined`.
    const start = Date.now();
    const route = await router.resolve({
      ...context,
      pathname: location.pathname,
      query: queryString.parse(location.search, { arrayFormat: 'bracket' }),
      locale,
      serverUrl: window.App.serverUrl,
      cloudfrontUrl: window.App.cloudfrontUrl,
      vimeoLivestreamUrl: window.App.vimeoLivestreamUrl,
      store,
      apiVersion: window.App.apiVersion,
    });

    // Prevent multiple page renders during the routing process
    if (currentLocation.key !== location.key) {
      window.isAppReady = true;
      return;
    }

    if (route.redirect) {
      history.replace(route.redirect);
      return;
    }

    const user = _.pick(_.get(store.getState().auth, 'user'), [
      '_id',
      'email',
      'mainGroup',
    ]);
    const reqUserMeta = _.cloneDeep(user);
    const mainGroup = _.get(reqUserMeta, 'mainGroup.name');
    if (mainGroup) {
      reqUserMeta.mainGroup = mainGroup;
    }
    if (window.App.logdna.apiKey && window.logdna) {
      const meta = {
        isBrowser: true,
        user: reqUserMeta,
        req: {
          url: location.pathname,
        },
        query: queryString.parse(location.search, { arrayFormat: 'bracket' }),
        locale: store.getState().intl.locale,
        responseTime: Date.now() - start,
        res: {
          statusCode: route.status,
        },
      };

      logger.info(
        `GET ${location.pathname} ${route.status || ''} ${Date.now() -
          start}ms`,
        {
          meta,
        },
      );
      const baseConsoleError = console.error;
      window.console.error = function consoleError(error, ...rest) {
        const errorMeta = {
          ...rest,
          ...meta,
        };
        let message = error;

        if (error instanceof Error) {
          message = error.message;
          errorMeta.stack = error.stack;
        } else if (_.isObject(error)) {
          message = safeJsonStringify(error);
        }

        logger.error(message, {
          meta: errorMeta,
        });

        baseConsoleError.apply(console, [error, ...rest]);
      };
    }

    if (window.App.whitelabeling) {
      materialUiThemeColorFromSlug(theme, window.App.whitelabeling);
    }

    const momentLocale = _.head(_.split(locale, '-')) || 'fi';
    moment.locale(momentLocale);

    const renderReactApp = isInitialRender ? ReactDOM.hydrate : ReactDOM.render;

    appInstance = renderReactApp(
      <ReactReduxProvider store={store}>
        <CookiesProvider>
          <ContextProvider.Provider value={context}>
            <MuiThemeProvider theme={createMuiTheme(theme)}>
              <MuiPickersUtilsProvider
                utils={MomentUtils}
                locale={momentLocale}
              >
                <StyleContext.Provider value={{ insertCss: context.insertCss }}>
                  <Main context={context}>
                    <LoggerProvider logger={logger}>
                      {route.component}
                    </LoggerProvider>
                  </Main>
                </StyleContext.Provider>
              </MuiPickersUtilsProvider>
            </MuiThemeProvider>
          </ContextProvider.Provider>
        </CookiesProvider>
      </ReactReduxProvider>,
      container,
      () => {
        if (isInitialRender) {
          // Switch off the native scroll restoration behavior and handle it manually
          // https://developers.google.com/web/updates/2015/09/history-api-scroll-restoration
          if (window.history && 'scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
          }

          window.isAppReady = true;

          const elem = document.getElementById('css');
          if (elem) elem.parentNode.removeChild(elem);
          return;
        }

        document.title = route.title;

        const ogUrl = `${window.App.serverUrl}${createPath(location)}`;

        const mappedKeywords = _.map(route.keywords || [], item =>
          _.get(item, 'name', item),
        );

        updateMeta('description', route.description);
        // Update necessary tags in <head> at runtime here, ie:
        updateMeta('keywords', (mappedKeywords || []).join(', '));
        // updateCustomMeta('og:url', route.canonicalUrl);
        updateCustomMeta('og:image', route.imageUrl);
        updateCustomMeta('og:title', route.title);
        updateCustomMeta(
          'og:url',
          _.endsWith(ogUrl, '/') ? ogUrl : `${ogUrl}/`,
        );
        updateCustomMeta('og:description', route.description);
        updateLink('canonical', route.canonicalUrl);
        updateLink('prev', route.prevUrl);
        updateLink('next', route.nextUrl);
        // etc.

        let scrollX = 0;
        let scrollY = 0;
        const pos = scrollPositionsHistory[location.key];
        if (pos) {
          scrollX = pos.scrollX;
          scrollY = pos.scrollY;
        } else {
          const targetHash = location.hash.substr(1);
          if (targetHash) {
            const target = document.getElementById(targetHash);
            if (target) {
              scrollY = window.pageYOffset + target.getBoundingClientRect().top;
            }
          }
        }

        // Restore the scroll position if it was saved into the state
        // or scroll to the given #hash anchor
        // or scroll to top of the page
        window.scrollTo(scrollX, scrollY);

        window.isAppReady = true;

        // Google Analytics tracking. Don't send 'pageview' event after
        // the initial rendering, as it was already sent
        if (window.ga) {
          window.ga('send', 'pageview', createPath(location));
          if (user._id) {
            window.ga('set', 'userId', user._id);
          }
          if (_.get(user, 'mainGroup._id')) {
            window.ga('set', 'dimension2', _.get(user, 'mainGroup._id'));
          }
        }
      },
    );
  } catch (error) {
    if (__DEV__) {
      throw error;
    }

    console.error(error);
    const user = _.pick(_.get(store.getState().auth, 'user'), [
      '_id',
      'email',
      'mainGroup',
    ]);
    const reqUserMeta = _.cloneDeep(user);
    const mainGroup = _.get(reqUserMeta, 'mainGroup.name');
    if (mainGroup) {
      reqUserMeta.mainGroup = mainGroup;
    }

    if (logger) {
      logger.error(`GET ${location.pathname} 500`, {
        meta: {
          isBrowser: true,
          error,
          user: reqUserMeta,
          req: {
            url: location.pathname,
          },
          query: queryString.parse(location.search, { arrayFormat: 'bracket' }),
          locale: store.getState().intl.locale,
          res: {
            statusCode: 500,
          },
        },
      });
    }

    // Do a full page reload if error occurs during client-side navigation
    if (!isInitialRender && currentLocation.key === location.key) {
      window.location.reload();
    }
  }
}

let isHistoryObserved = false;
export default function main() {
  // Handle client-side navigation by using HTML5 History API
  // For more information visit https://github.com/mjackson/history#readme
  currentLocation = history.location;
  if (!isHistoryObserved) {
    isHistoryObserved = true;
    history.listen(onLocationChange);
  }
  onLocationChange(currentLocation);
}

// globally accesible entry point
window.RSK_ENTRY = main;

// Enable Hot Module Replacement (HMR)
if (module.hot) {
  module.hot.accept('./router', () => {
    if (appInstance && appInstance.updater.isMounted(appInstance)) {
      // Force-update the whole tree, including components that refuse to update
      deepForceUpdate(appInstance);
    }
  });
}
