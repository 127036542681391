import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Cookies, withCookies } from 'react-cookie';
import {
  defineMessages,
  intlShape,
  injectIntl,
  FormattedHTMLMessage,
  FormattedMessage,
} from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LoadingBar from 'react-redux-loading-bar';
import * as searchActions from '../../actions/search';
// import { isLoggedIn } from '../../helpers';
import modalStyles from '../styles/Modal.scss'; // eslint-disable-line css-modules/no-unused-class

import s from './Header.scss';
import Link from '../Link';
import Navigation from '../Navigation';
import SearchField from './SearchField/SearchField';
import AutocompleteSearchField from './AutocompleteSearchField/AutocompleteSearchField';
import logoUrl from './skhole_logo_nega.png';

const messages = defineMessages({
  offlineTitle: {
    id: 'Header.offlineTitle',
    defaultMessage:
      "You don't appear to have internet connectivity so you can only view downloaded content",
  },
  pwaOffOfflineTitle: {
    id: 'Header.pwaOffOfflineTitle',
    defaultMessage: "You don't appear to have internet connectivity",
  },
  offline: {
    id: 'Header.offline',
    defaultMessage: 'Offline-mode',
  },
  close: {
    id: 'Header.close',
    defaultMessage: 'Close',
  },
  serviceName: {
    id: 'Header.defaultServiceName',
    defaultMessage: 'Skhole',
  },
  logoLabel: { id: 'Header.logoLabel', defaultMessage: '{serviceName} - home' },
  closeCategoryModalBtn: {
    id: 'Header.closeCategoryModalBtn',
    defaultMessage: 'Close category modal button',
  },
});

@injectIntl
@withStyles(s)
@withStyles(modalStyles)
@withCookies
@connect(
  state => ({
    auth: state.auth,
    search: state.search.search,
    selectedTags: state.search.tags,
    lang: state.intl.locale,
    offline: state.online.offline,
    turnedOffPwaOffline: state.online.turnedOffPwaOffline,
  }),
  dispatch => bindActionCreators({ ...searchActions }, dispatch),
)
class Header extends React.Component {
  static propTypes = {
    search: PropTypes.string.isRequired,
    selectedTags: PropTypes.array.isRequired, // eslint-disable-line
    changeSearchField: PropTypes.func.isRequired,
    changeTagsField: PropTypes.func.isRequired,
    changePendingTagsField: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    cookies: PropTypes.instanceOf(Cookies).isRequired,
    lang: PropTypes.string.isRequired,
    offline: PropTypes.bool.isRequired,
    turnedOffPwaOffline: PropTypes.bool.isRequired,
    logoBtnRef: PropTypes.func.isRequired,
  };

  static contextTypes = {
    path: PropTypes.string,
    whitelabeling: PropTypes.shape({}),
    cloudfrontUrl: PropTypes.string,
    organizationSlug: PropTypes.string,
    tour: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      isMobileSearchVisible: false,
      width: 769,
    };

    this.onResizeDebounced = _.debounce(this.onResize, 300);

    props.logoBtnRef(this);
  }

  componentDidMount() {
    // const { auth } = this.props;

    this.onResizeDebounced();
    window.addEventListener('resize', this.onResizeDebounced);

    // if (!isLoggedIn(auth)) {
    //   return;
    // }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (_.get(this, 'context.path') !== _.get(nextContext, 'path')) {
      this.setState({ isMobileSearchVisible: false });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResizeDebounced);
  }

  onResize = () => {
    this.setState({ width: window.innerWidth });
  };

  toggleMobileSearchView = () => {
    this.setState(prevState => ({
      isMobileSearchVisible: !prevState.isMobileSearchVisible,
    }));
  };

  render() {
    const {
      intl: { formatMessage },
      offline,
      turnedOffPwaOffline,
      lang,
    } = this.props;
    const {
      path,
      whitelabeling,
      cloudfrontUrl,
      organizationSlug,
    } = this.context;
    const { isMobileSearchVisible, width } = this.state;

    let logoStyles = {
      backgroundImage: `url(${logoUrl})`,
      backgroundRepeat: 'no-repeat',
    };

    if (whitelabeling.logoUrl) {
      logoStyles.backgroundImage = `url(${cloudfrontUrl}${whitelabeling.logoUrl})`;
    }

    if (whitelabeling.logoUrlMobile && width < 768) {
      logoStyles.backgroundImage = `url(${cloudfrontUrl}${whitelabeling.logoUrlMobile})`;
    }

    if (whitelabeling.logoSizes) {
      logoStyles =
        width < 768
          ? {
              ...logoStyles,
              width: `${whitelabeling.logoSizesMobile[0]}px`,
              height: `${whitelabeling.logoSizesMobile[1]}px`,
              backgroundSize: `${whitelabeling.logoSizesMobile[0]}px ${whitelabeling.logoSizesMobile[1]}px`,
            }
          : {
              ...logoStyles,
              width: `${whitelabeling.logoSizes[0]}px`,
              height: `${whitelabeling.logoSizes[1]}px`,
              backgroundSize: `${whitelabeling.logoSizes[0]}px ${whitelabeling.logoSizes[1]}px`,
            };
    }

    let homePages = [
      '/',
      '/en-US',
      '/fi-FI',
      '/sv-SE',
      '/en-US/',
      '/fi-FI/',
      '/sv-SE/',
    ];

    if (organizationSlug) {
      homePages = _.concat(homePages, [
        `/en-US/${organizationSlug}`,
        `/fi-FI/${organizationSlug}`,
        `/sv-SE/${organizationSlug}`,
        `/en-US/${organizationSlug}/`,
        `/fi-FI/${organizationSlug}/`,
        `/sv-SE/${organizationSlug}/`,
      ]);
    }

    const serviceName = _.get(
      whitelabeling,
      `serviceName.${lang}`,
      formatMessage(messages.serviceName),
    );

    const isHomePage = homePages.includes(path);
    return (
      <header className={s.root}>
        <LoadingBar className={s.loadingBar} />
        <div className={classNames(s.container)}>
          <div className={s.flex}>
            <Link
              className={s.brand}
              to="/"
              ref={node => {
                // eslint-disable-next-line react/no-unused-class-component-methods
                this.logoBtnRef = node;
              }}
              aria-label={formatMessage(messages.logoLabel, {
                serviceName,
              })}
              tabIndex={isHomePage ? '-1' : '0'}
            >
              <div className={s.logotype} style={logoStyles} id="header_logo" />
            </Link>
            {!offline && (
              <form
                onSubmit={event => event.preventDefault()}
                id="site-search-block"
                className={s.flex}
                role="search"
              >
                <div className={s.searchWrapper}>
                  {isHomePage ? (
                    <SearchField
                      classes={{
                        searchFieldRoot: s.searchFieldRoot,
                        inputLabelRoot: s.searchInputLabelRoot,
                        searchIconButtonRoot: s.searchIconButtonRoot,
                        inputLabelShrink: s.inputLabelShrink,
                      }}
                      className={s.searchField}
                    />
                  ) : (
                    <AutocompleteSearchField />
                  )}
                </div>
              </form>
            )}
          </div>
          {!offline && (
            <Navigation toggleMobileSearchView={this.toggleMobileSearchView} />
          )}
          {offline && (
            <span className={s.offline}>
              <FormattedMessage {...messages.offline} />
            </span>
          )}
        </div>
        {isMobileSearchVisible && !offline && (
          <div className={s.mobileFlexSearchWrapper}>
            <div
              className={classNames(s.mobileSearchWrapper, {
                [s.autocompleteSearchField]: !isHomePage,
              })}
            >
              {isHomePage ? <SearchField /> : <AutocompleteSearchField />}
            </div>
          </div>
        )}
        {turnedOffPwaOffline && (
          <div className={s.offlineTitle}>
            <FormattedHTMLMessage {...messages.pwaOffOfflineTitle} />
          </div>
        )}
        {offline && (
          <div className={s.offlineTitle}>
            <FormattedHTMLMessage {...messages.offlineTitle} />
          </div>
        )}
      </header>
    );
  }
}

export default React.forwardRef((props, ref) => (
  <Header {...props} logoBtnRef={ref} />
));
