import { intercomWidgetPositions } from '../constants';

const getIntercomPaddings = currentURL => {
  const matchingPosition = intercomWidgetPositions.find(position =>
    currentURL.includes(position.PATH),
  );

  return matchingPosition;
};

export default getIntercomPaddings;
