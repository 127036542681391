import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './NotFound.css';

const messages = defineMessages({
  notFoundMessage: {
    id: 'NotFound.notFoundMessage',
    defaultMessage: 'Sorry, the page you were trying to view does not exist.',
    description: 'message displayed in not found page',
  },
});

class NotFound extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
  };

  render() {
    return (
      <div className={s.root}>
        <div className={s.container}>
          <h1>{this.props.title}</h1>
          <p>
            <FormattedMessage {...messages.notFoundMessage} />
          </p>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(NotFound);
