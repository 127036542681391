import { defineMessages } from 'react-intl';

const messages = defineMessages({
  submitButtonText: {
    id: 'Login.submitButtonText',
    defaultMessage: 'Log In',
    description: 'Submit button text on the login form',
  },
  emailLabel: {
    id: 'Login.emailLabel',
    defaultMessage: 'Email',
    description: 'Email placeholder on the login form',
  },
  passwordLabel: {
    id: 'Login.passwordLabel',
    defaultMessage: 'Password',
    description: 'Password placeholder on the login form',
  },
  forgotPasswordButtonText: {
    id: 'Login.forgotPasswordButtonText',
    defaultMessage: 'Forgot your password?',
    description:
      'Forgot password button text on the login form before Submit button',
  },
  orOptionText: {
    id: 'Login.orOptionText',
    defaultMessage: 'OR',
    description: 'OR  displayed in login form to show option for login',
  },
  continueWithFBText: {
    id: 'Login.continueWithFBText',
    defaultMessage: 'Continue with Facebook',
    description:
      'Continue with Facebook displayed in login form to choose login with Facebook',
  },
  continueWithGoogleText: {
    id: 'Login.continueWithGoogleText',
    defaultMessage: 'Continue with Google',
    description:
      'Continue with Google displayed in login form to choose login with Google',
  },
  continueWithHakaText: {
    id: 'Login.continueWithHakaText',
    defaultMessage: 'Continue with Haka',
    description:
      'Continue with Haka displayed in login form to choose login with Haka',
  },
  logInText: {
    id: 'Login.logInText',
    defaultMessage: 'Log in',
    description: 'Text displayed in login form',
  },
  needHelp: {
    id: 'Login.needHelp',
    defaultMessage: 'Need help?',
  },
  signUpButtonText: {
    id: 'Login.signUpButtonText',
    defaultMessage: "Don't have an account? Sign Up!",
  },
  hakaIsDisabled: {
    id: 'Login.hakaIsDisabled',
    defaultMessage: 'Haka login is temporarily disabled',
  },
  serviceNamePossessive: {
    id: 'General.serviceNamePossessive',
    defaultMessage: 'Skhole',
  },
  chooseLoginMethod: {
    id: 'Login.chooseLoginMethod',
    defaultMessage: 'Choose login method',
  },
  loginWithSelectedMethod: {
    id: 'Login.loginWithSelectedMethod',
    defaultMessage: 'Login with selected method',
  },
  continueWithSuomisportText: {
    id: 'Login.continueWithSuomisportText',
    defaultMessage: 'Continue with Suomisport',
  },
});

export default messages;
