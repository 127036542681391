import React, { useContext, useState } from 'react';

import { connect } from 'react-redux';
import {
  Field,
  propTypes as reduxFormPropTypes,
  reduxForm,
  SubmissionError,
} from 'redux-form';
import withStyles from 'isomorphic-style-loader/withStyles';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {
  FormattedMessage,
  injectIntl,
  intlShape,
  FormattedHTMLMessage,
} from 'react-intl';

import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { ModalSaveBtn } from '../../../statelessComponents/Buttons/ButtonsInModals';

import s from './OtpTwoFaForm.scss';
import messages from './messages';
import ContextProvider from '../../../../context';
import TextField from '../../../statelessComponents/FormFields/TextField';
import validate from './validate';
import { TWO_FA_TYPES } from '../../../../constants/twoFa';

const OtpTwoFaForm = props => {
  const {
    intl: { formatMessage },
    handleSubmit,
    submitFailed,
    error,
    submitting,

    onSubmit,
    isActionConfirmation,
    action,
    verificationAccount,
    onCloseAfterSubmit,
    onSubmitFailedDueToExpiration,
  } = props;

  const { fetch } = useContext(ContextProvider);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [
    isSubmitFailedDueToExpiration,
    setIsSubmitFailedDueToExpiration,
  ] = useState(false);

  const submit = async data => {
    setIsSubmitted(false);

    try {
      const res = await fetch('/api/auth/validate-otp', {
        method: 'POST',
        body: JSON.stringify({
          ...data,
          action,
          type: TWO_FA_TYPES.GOOGLE_AUTHENTICATOR,
        }),
      });
      const resJson = await res.json();

      if (!res.ok) {
        if (res.status === 423) {
          setIsSubmitFailedDueToExpiration(true);
        }
        if (resJson.message) {
          throw new Error(resJson.message);
        }
        throw new Error(resJson);
      }
      await onSubmit(resJson);
      setIsSubmitted(true);
    } catch (err) {
      throw new SubmissionError({ _error: err && err.message });
    }
  };

  return (
    <div className={s.container}>
      {!isActionConfirmation && (
        <Typography variant="body2">
          <FormattedMessage {...messages.keepYourAccountSecureText} />
        </Typography>
      )}
      {isActionConfirmation && (
        <Typography variant="body2">
          <FormattedMessage {...messages.thisActionRequiresTwoFaConfirmation} />
        </Typography>
      )}
      <Typography className={s.callToActionText} variant="body2">
        <FormattedHTMLMessage {...messages.callToActionText} />
      </Typography>

      <Typography variant="body2" data-test="account-label">
        <FormattedMessage
          {...messages.accountLabel}
          values={{ name: verificationAccount }}
        />
      </Typography>

      <form noValidate autoComplete="off">
        <Field
          name="token"
          type="text"
          label={formatMessage(messages.verifyCodeLabel)}
          margin="normal"
          component={TextField}
          fullWidth
          disabled={submitting}
          data-test="verify-code-input"
        />

        {submitFailed && error && (
          <div>
            <Typography color="error">{error}</Typography>
          </div>
        )}
        <ModalSaveBtn
          isLoaderOn={submitting}
          onClick={handleSubmit(submit)}
          isSuccessed={isSubmitted}
          mobileLandscapeRelative
          disabled={submitting || isSubmitFailedDueToExpiration}
          tooltip={formatMessage(messages.saveBtnTooltip)}
          data-test="verify-code-save-btn"
        />
      </form>
      <Snackbar
        open={isSubmitted}
        onClose={onCloseAfterSubmit}
        autoHideDuration={4000}
        message={formatMessage(messages.successSnackbarText)}
      />
      <Snackbar
        open={isSubmitFailedDueToExpiration}
        onClose={onSubmitFailedDueToExpiration}
        autoHideDuration={15000}
        message={formatMessage(
          messages.submitFailedDueToExpirationSnackbarText,
        )}
        action={[
          <IconButton
            className={s.closeSnackbar}
            color="inherit"
            onClick={onSubmitFailedDueToExpiration}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </div>
  );
};

OtpTwoFaForm.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isActionConfirmation: PropTypes.bool.isRequired,
  action: PropTypes.string.isRequired,
  verificationAccount: PropTypes.string.isRequired,
  onCloseAfterSubmit: PropTypes.func.isRequired,
  onSubmitFailedDueToExpiration: PropTypes.func.isRequired,
  ...reduxFormPropTypes,
};

OtpTwoFaForm.defaultProps = {};
export default compose(
  injectIntl,
  withStyles(s),
  connect(state => ({
    otp: state.twoFaSettings.otp,
    initialValues: {
      token: '',
    },
  })),
  reduxForm({
    form: 'otpTwoFaForm',
    validate,
  }),
)(OtpTwoFaForm);
