import React from 'react';
import _ from 'lodash';
import { defineMessages, FormattedMessage } from 'react-intl';
import { buildQuery } from '../../helpers';

const messages = defineMessages({
  emailIsTaken: {
    id: 'General.ThisEmailIsTaken',
    defaultMessage: 'This email is taken',
  },
  codeIsInvalid: {
    id: 'Register.ThisCodeIsInvalid',
    defaultMessage: 'Sign up code is not found, consult your group leader',
  },
});

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export default (values, dispatch, { fetch, membershipIdVerificationEnabled }) =>
  sleep(1000)
    .then(() => {
      if (!values || !values.email) return false;

      return fetch(
        `/api/auth/is-email-valid?${buildQuery(
          _.pick(values, ['email', 'invitationToken']),
        )}`,
        {
          method: 'GET',
        },
      )
        .then(response => response.json())
        .then(res => {
          if (res && res.message) {
            /* eslint-disable-next-line no-throw-literal */
            throw { code: res.message };
          }
          if (res && res.isExist) {
            const error = {
              email: <FormattedMessage {...messages.emailIsTaken} />,
            };

            if (res.isGroupleader) {
              error.isGroupleader = true;
            }
            throw error;
          }
        });
    })
    .then(() => {
      if (
        !values ||
        !values.code ||
        (values && values.invitationToken) ||
        membershipIdVerificationEnabled
      )
        return false;

      return fetch(`/api/auth/is-code-valid?code=${values.code}`, {
        method: 'GET',
      })
        .then(response => response.json())
        .then(res => {
          if (res && res.message) {
            /* eslint-disable-next-line no-throw-literal */
            throw { code: res.message };
          }
          if (res && res.isExist === false) {
            /* eslint-disable-next-line no-throw-literal */
            throw { code: <FormattedMessage {...messages.codeIsInvalid} /> };
          }
        });
    });
