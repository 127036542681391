/* eslint-disable import/prefer-default-export */

import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAIL,
  APPEND_NOTIFICATION,
  READ_NOTIFICATION,
  READ_ALL_NOTIFICATIONS,
  RESET_NOTIFICATIONS,
  RESET_PAGINATION_NOTIFICATION,
} from '../constants';
import { buildQuery } from '../helpers';

export function getNotifications(
  params = { page: 1, perPage: 10, sort: '-createDate' },
) {
  const query = buildQuery(params);

  return {
    types: [
      GET_NOTIFICATIONS,
      GET_NOTIFICATIONS_SUCCESS,
      GET_NOTIFICATIONS_FAIL,
    ],
    promise: fetch =>
      fetch(`/api/notifications?${query}`, {
        method: 'GET',
      }),
  };
}

export function resetNotifications() {
  return {
    type: RESET_NOTIFICATIONS,
  };
}

export function appendNotification(notification) {
  return {
    type: APPEND_NOTIFICATION,
    notification,
  };
}

export function setFirstPagePagination(perPage) {
  return {
    type: RESET_PAGINATION_NOTIFICATION,
    perPage,
  };
}

export function readNotification(notificationId) {
  return {
    type: READ_NOTIFICATION,
    notificationId,
  };
}

export function readAllNotifications() {
  return {
    type: READ_ALL_NOTIFICATIONS,
  };
}
