import {
  MANAGE_GET_TAGS,
  MANAGE_GET_TAGS_SUCCESS,
  MANAGE_GET_TAGS_FAIL,
  MANAGE_GET_ALL_TAGS,
  MANAGE_GET_ALL_TAGS_SUCCESS,
  MANAGE_GET_ALL_TAGS_FAIL,
  MANAGE_RESET_TAGS,
} from '../constants';

const initialState = {
  loading: false,
  loaded: false,
  tags: [],
  total: 0,
  allTags: [],
  loadingAll: false,
};

export default function manageTags(state = initialState, action) {
  switch (action.type) {
    case MANAGE_GET_TAGS:
      return {
        ...state,
        loading: true,
      };
    case MANAGE_GET_TAGS_SUCCESS:
      return {
        ...state,
        tags: action.result.json,
        total: action.result.total,
        loading: false,
        loaded: true,
      };
    case MANAGE_GET_TAGS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case MANAGE_GET_ALL_TAGS:
      return {
        ...state,
        loadingAll: true,
      };
    case MANAGE_GET_ALL_TAGS_SUCCESS:
      return {
        ...state,
        allTags: action.result.json,
        loadingAll: false,
      };
    case MANAGE_GET_ALL_TAGS_FAIL:
      return {
        ...state,
        loadingAll: false,
      };
    case MANAGE_RESET_TAGS:
      return initialState;
    default:
      return state;
  }
}
