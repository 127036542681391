import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { intlShape, injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';

import withStyles from 'isomorphic-style-loader/withStyles';
import isLoggedIn from '../../../helpers/checkAuth';

import s from './LicensesReachedSnackbar.css';

const messages = defineMessages({
  licencesLimitIsReached: {
    id: 'navigation.licencesLimitIsReached',
    defaultMessage:
      'Limit of users in main group is reached: {usedLicencesNumber}/{licencesNumber}',
  },
});

@injectIntl
@withStyles(s)
@connect(state => ({
  auth: state.auth,
}))
export default class LicensesReachedSnackbar extends React.Component {
  static propTypes = {
    auth: PropTypes.object,  // eslint-disable-line
    intl: intlShape.isRequired,
  };

  state = {
    isShown: false,
  };

  componentDidMount() {
    this.checkLicencesLimit(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      (isLoggedIn(this.props.auth) && !isLoggedIn(nextProps.auth)) ||
      (_.get(this, 'props.auth.user._id') &&
        _.get(nextProps, 'auth.user._id') &&
        _.get(this, 'props.auth.user._id') !==
          _.get(nextProps, 'auth.user._id'))
    ) {
      this.checkLicencesLimit(nextProps);
    }

    if (
      (!isLoggedIn(this.props.auth) && isLoggedIn(nextProps.auth)) ||
      (_.get(this, 'props.auth.user._id') &&
        _.get(nextProps, 'auth.user._id') &&
        _.get(this, 'props.auth.user._id') !==
          _.get(nextProps, 'auth.user._id'))
    ) {
      this.checkLicencesLimit(nextProps);
    }
  }

  handleCloseUsersLimitReached = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ isShown: false });
  };

  checkLicencesLimit = props => {
    const usedLicencesNumber = _.get(
      props,
      'auth.user.mainGroup.usedLicencesNumber',
    );
    const licencesNumber = _.get(props, 'auth.user.mainGroup.licencesNumber');

    const isShown = licencesNumber && usedLicencesNumber >= licencesNumber;

    return this.setState({ isShown });
  };

  render() {
    const {
      auth,
      intl: { formatMessage },
    } = this.props;
    const { isShown } = this.state;

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={isShown}
        onClose={this.handleCloseUsersLimitReached}
        message={formatMessage(messages.licencesLimitIsReached, {
          licencesNumber: _.get(auth, 'user.mainGroup.licencesNumber'),
          usedLicencesNumber: _.get(auth, 'user.mainGroup.usedLicencesNumber'),
        })}
        classes={{ root: s.root }}
        action={[
          <IconButton
            color="inherit"
            onClick={this.handleCloseUsersLimitReached}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    );
  }
}
