import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import classNames from 'classnames';
import {
  Field,
  reduxForm,
  SubmissionError,
  propTypes as reduxFormPropTypes,
} from 'redux-form';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import _ from 'lodash';

import TextField from '../statelessComponents/FormFields/TextField';

import s from './AddToDifferentMainGroupModal.scss';
import textFieldStyles from '../styles/TextField.scss';
import modalStyles from '../styles/Modal.scss';
import validate from './validation';
import asyncValidate from './asyncValidation';

import messages from './messages';

@withStyles(s)
@withStyles(textFieldStyles)
@withStyles(modalStyles)
@injectIntl
@connect((state, props) => ({
  initialValues: {
    code: props.code,
    invitationToken: props.invitationToken,
  },
}))
@reduxForm({
  form: 'requestChangeMainGroup',
  validate,
  asyncValidate,
  asyncBlurFields: ['code'],
})
export default class AddToDifferentMainGroupModal extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired,
    invitationToken: PropTypes.string,
    code: PropTypes.string,
    ...reduxFormPropTypes,
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    invitationToken: '',
    code: '',
  };

  static contextTypes = {
    fetch: PropTypes.func,
  };

  state = {
    isSubmitted: false,
  };

  submit = data =>
    this.context
      .fetch('/api/auth/request-add-to-different-main-group', {
        method: 'POST',
        body: JSON.stringify({
          ...(data.invitationToken ? _.omit(data, 'code') : data),
          email: this.props.email,
        }),
      })
      .catch(err => {
        throw new SubmissionError({ _error: err && err.message });
      })
      .then(res =>
        res.json().then(resJson => {
          if (!res.ok) {
            if (resJson.message) {
              throw new SubmissionError({ _error: resJson.message });
            }
            throw new SubmissionError(resJson);
          }
          return resJson;
        }),
      )
      .then(() => {
        this.props.reset();
        this.setState({ isSubmitted: true });
      });

  handleCloseSnackbar = () => {
    const { onClose } = this.props;
    this.setState({ isSubmitted: false });
    onClose(true);
  };

  handleCloseButtonClick = () => this.props.onClose();

  render() {
    const {
      handleSubmit,
      submitting,
      error,
      intl,
      invitationToken,
    } = this.props;

    const { isSubmitted } = this.state;

    return (
      <div
        className={classNames(modalStyles.paper, s.modal)}
        id="add-to-different-main-group-modal"
      >
        <Typography variant="h4" component="h1" className={s.lead}>
          <FormattedMessage {...messages.title} />
        </Typography>
        <form noValidate autoComplete="off">
          <Field
            name="code"
            type="text"
            placeholder={intl.formatMessage(messages.code)}
            component={TextField}
            normalize={value =>
              value &&
              value
                .toUpperCase()
                .replace(/[^ILWERTYUPASDFGHKZXCVNM2345679]+/g, '')
                .substr(0, 6)
            }
            disabled={invitationToken}
            margin="dense"
            InputProps={{
              classes: {
                root: textFieldStyles.textField,
                input: textFieldStyles.textFieldInput,
                focused: textFieldStyles.textFieldFocused,
                disabled: textFieldStyles.textFieldDisabled,
                error: textFieldStyles.textFieldError,
              },
            }}
            fullWidth
          />

          <Typography variant="body2" className={s.lead}>
            <FormattedMessage {...messages.userTip} />
          </Typography>
          {error && (
            <Typography gutterBottom color="error">
              {error}
            </Typography>
          )}
          <Button
            color="secondary"
            type="submit"
            margin="dense"
            size="large"
            variant="contained"
            onClick={handleSubmit(this.submit)}
            disabled={submitting}
            className={s.submit}
            fullWidth
          >
            <FormattedMessage {...messages.submit} />
          </Button>
          <Snackbar
            open={isSubmitted}
            onClose={this.handleCloseSnackbar}
            autoHideDuration={2000}
            message={
              invitationToken ? (
                <FormattedMessage
                  {...messages.successInviteCompletionMessage}
                />
              ) : (
                <FormattedMessage {...messages.successMessage} />
              )
            }
          />
        </form>
        <div className={modalStyles.closeModalBtn}>
          <IconButton onClick={this.handleCloseButtonClick}>
            <CloseIcon className={modalStyles.closeIcon} />
          </IconButton>
        </div>
      </div>
    );
  }
}
