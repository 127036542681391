import _ from 'lodash';
import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAIL,
  APPEND_NOTIFICATION,
  READ_NOTIFICATION,
  READ_ALL_NOTIFICATIONS,
  RESET_NOTIFICATIONS,
  RESET_PAGINATION_NOTIFICATION,
} from '../constants';

export default function notifications(
  state = { loading: false, notifications: [], total: 0 },
  action,
) {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      return {
        ...state,
        loading: true,
      };
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: [].concat(state.notifications, action.result.json),
        total: action.result.total,
        loading: false,
      };
    case GET_NOTIFICATIONS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case APPEND_NOTIFICATION: {
      return {
        ...state,
        notifications: [].concat([action.notification], state.notifications),
        total: state.total + 1,
      };
    }
    case READ_NOTIFICATION: {
      const { notifications: items } = state;
      const notificationIndex = _.findIndex(items, {
        _id: action.notificationId,
      });

      if (notificationIndex !== -1) {
        // bad working solution, used because it's not so important to sync readDate with api
        items[notificationIndex].readDate = new Date();
      }

      return {
        ...state,
        notifications: [...items],
      };
    }
    case RESET_PAGINATION_NOTIFICATION: {
      const { notifications: items } = state;
      return {
        ...state,
        notifications: _.take(items, action.perPage),
      };
    }
    case READ_ALL_NOTIFICATIONS: {
      return {
        ...state,
        notifications: [
          ...state.notifications.map(notification => ({
            ...notification,
            readDate: new Date(), // bad working solution, used because it's not so important to sync readDate with api
          })),
        ],
      };
    }
    case RESET_NOTIFICATIONS:
      return {
        ...state,
        notifications: [],
      };
    default:
      return state;
  }
}
