import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './NotAuthorized.css';

const messages = defineMessages({
  notAuthorizedMessage: {
    id: 'NotAuthorized.notAuthorizedMessage',
    defaultMessage: 'Authorization is required',
  },
});

class NotAuthorized extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
  };

  componentDidMount() {
    const el = document.querySelector('#navigation-login-btn');

    if (!el) {
      return;
    }

    el.click();
  }

  render() {
    return (
      <div className={s.root}>
        <div className={s.container}>
          <h1>{this.props.title}</h1>
          <p>
            <FormattedMessage {...messages.notAuthorizedMessage} />
          </p>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(NotAuthorized);
