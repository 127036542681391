import {
  GET_XLSX_EXPORT,
  GET_XLSX_EXPORT_SUCCESS,
  GET_XLSX_EXPORT_FAIL,
  RESET_XLSX_EXPORT,
} from '../constants';

const initialState = { loading: false, xlsxExport: {} };

export default function xlsxExport(state = initialState, action) {
  switch (action.type) {
    case GET_XLSX_EXPORT:
      return {
        ...state,
        loading: true,
      };
    case GET_XLSX_EXPORT_SUCCESS:
      return {
        ...state,
        xlsxExport: action.result.json,
        loading: false,
      };
    case GET_XLSX_EXPORT_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case RESET_XLSX_EXPORT:
      return initialState;
    default:
      return state;
  }
}
