import React, { useState } from 'react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  intlShape,
} from 'react-intl';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';

import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';
import { getCurrentLink } from '../createLinkifyPlugin';
import s from './AddLink.scss';

const messages = defineMessages({
  title: {
    id: 'AddLink.title',
    defaultMessage: 'Add Link',
  },
  pasteUrl: {
    id: 'AddLink.pasteUrl',
    defaultMessage: 'Paste URL:',
  },
  saveLabel: {
    id: 'General.saveLabel',
    defaultMessage: 'Save',
  },
  cancelLabel: {
    id: 'AddLink.cancelLabel',
    defaultMessage: 'Cancel',
  },
  urlNotMatch: {
    id: 'AddLink.urlNotMatch',
    defaultMessage: "Url doesn't match",
  },
});

const AddLink = ({
  editorState,
  disabled,
  handleAddLink,
  handleRemoveLink,
  intl: { formatMessage },
}) => {
  const [showDialog, setShowDialog] = useState(false);
  const [url, setUrl] = useState('');
  const [error, setError] = useState('');

  const handleLinkChange = e => {
    setUrl(e.target.value);
    if (error) setError('');
  };

  const handleLinkSave = () => {
    const urlRegex = new RegExp(
      "^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&'\\(\\)\\*\\+%,;=.]+$",
      'gi',
    );
    if (!url) {
      setError('');
      setShowDialog(false);
      handleRemoveLink();
    }
    if (url.match(urlRegex)) {
      setError('');
      setShowDialog(false);
      handleAddLink(url);
    } else {
      setError(formatMessage(messages.urlNotMatch));
    }
  };

  const handleAddOrUpdateLink = () => {
    setUrl(getCurrentLink(editorState) || '');
    setShowDialog(true);
  };

  const handleDialogClose = () => {
    setError('');
    setShowDialog(false);
  };

  return (
    <>
      <Button
        onClick={handleAddOrUpdateLink}
        size="small"
        variant="outlined"
        color="primary"
        className={s.addLinkBtn}
        disabled={disabled}
      >
        <FormattedMessage {...messages.title} />
      </Button>
      <Dialog open={showDialog} disableBackdropClick disableEscapeKeyDown>
        <DialogContent className={s.dialogContent}>
          <FormattedMessage {...messages.pasteUrl} />
          <TextField
            value={url}
            onChange={handleLinkChange}
            error={!!error}
            helperText={error}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleDialogClose}
          >
            <FormattedMessage {...messages.cancelLabel} />
          </Button>
          <Button color="primary" variant="contained" onClick={handleLinkSave}>
            <FormattedMessage {...messages.saveLabel} />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

AddLink.propTypes = {
  editorState: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool.isRequired,
  handleAddLink: PropTypes.func.isRequired,
  handleRemoveLink: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(withStyles(s)(AddLink));
