import React, { Component } from 'react';
import { defineMessages, intlShape, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import { connect } from 'react-redux';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import muiWithStyles from '@material-ui/core/styles/withStyles';

import AsyncAutocomplete from '../../AsyncAutocomplete/AsyncAutocomplete';

import { buildQuery } from '../../../helpers';
import isLoggedIn from '../../../helpers/checkAuth';
import history from '../../../history';
import * as searchActions from '../../../actions/search';
import RestrictedAccessModal from '../../RestrictedAccessModal/RestrictedAccessModal';

const messages = defineMessages({
  autocompleteSearchText: {
    id: 'AutocompleteSearchField.autocompleteSearchText',
    defaultMessage: 'Search...',
    description:
      'Search displayed in search field placeholder in page header accept home page',
  },
  courses: {
    id: 'AutocompleteSearchField.courses',
    defaultMessage: 'Courses ({total} results)',
  },
  lessons: {
    id: 'AutocompleteSearchField.lessons',
    defaultMessage: 'Lessons ({total} results)',
  },
  noAccess: {
    id: 'AutocompleteSearchField.noAccess',
    defaultMessage: 'You don’t have access to this content yet',
  },
});

const styles = () => ({
  inputLabelRoot: {
    color: '#fff !important',
    top: -10,
  },
  searchFieldRoot: {
    // background: "black"
    marginTop: '0 !important',
  },

  searchFieldInput: {
    color: '#fff',
    padding: '3px 0 3px',
    '& input': {
      color: '#fff',
    },
  },
  searchFieldUnderline: {
    '&::after': {
      borderBottomColor: '#fff',
    },
    '&:hover:after': {
      borderBottomColor: '#fff !important',
    },
    '&:hover:before': {
      borderBottomColor: '#fff !important',
    },
    '&::before': {
      borderBottomColor: '#fff',
    },
  },
  searchIconButtonRoot: {
    width: 26,
    height: 26,
  },
  arrowIconClass: {
    color: '#fff',
  },
});

@injectIntl
@muiWithStyles(styles)
@connect(
  state => ({
    auth: state.auth,
    search: state.search.search,
    selectedTags: state.search.tags,
    tags: state.tags.tags,
    lang: state.intl.locale,
  }),
  dispatch => bindActionCreators({ ...searchActions }, dispatch),
)
export default class AutocompleteSearchField extends Component {
  static propTypes = {
    changeSearchField: PropTypes.func.isRequired,
    selectedTags: PropTypes.array.isRequired, // eslint-disable-line
    tags: PropTypes.array.isRequired, // eslint-disable-line
    intl: intlShape.isRequired,
    lang: PropTypes.string.isRequired,
    auth: PropTypes.shape({}).isRequired,
    classes: PropTypes.shape({
      searchFieldRoot: PropTypes.string,
      searchFieldInput: PropTypes.string,
      searchFieldUnderline: PropTypes.string,
      inputLabelRoot: PropTypes.string,
      arrowIconClass: PropTypes.object, // eslint-disable-line
    }).isRequired,
    search: PropTypes.string.isRequired,
  };

  static contextTypes = {
    fetch: PropTypes.func,
    organizationSlug: PropTypes.string,
  };

  state = {
    isRestrictedAccessModalOpened: false,
  };

  getCoursesAndLessons = value => {
    const {
      intl: { formatMessage },
    } = this.props;
    return Promise.all([this.getCourses(value), this.getLessons(value)])
      .then(([courses, lessons]) => {
        const results = [];

        if (_.get(courses, 'json.length')) {
          results.push({
            label: formatMessage(messages.courses, {
              total: courses.total > 50 ? `50+` : courses.total,
            }),
            options: _.map(courses.json, item => ({
              ...item,
              itemType: 'course',
              tooltip: !item.isAvailable
                ? formatMessage(messages.noAccess)
                : item.name,
            })),
          });
        }

        if (_.get(lessons, 'json.length')) {
          results.push({
            label: formatMessage(messages.lessons, {
              total: lessons.total > 50 ? `50+` : lessons.total,
            }),
            options: _.map(lessons.json, item => ({
              ...item,
              itemType: 'lesson',
              tooltip: !item.isAvailable
                ? formatMessage(messages.noAccess)
                : item.name,
            })),
          });
        }

        return results;
      })
      .catch(console.error);
  };

  getCourses = value => {
    const { fetch } = this.context;
    const { selectedTags, lang, tags } = this.props;

    if (!value) {
      return new Promise(resolve =>
        resolve({
          options: [],
        }),
      );
    }

    const params = {
      sort: '-createDate',
      page: 1,
      search: _.trim(value).length < 3 ? '' : value.trim(),
      perPage: 50,
      lang,
      includeNotAvailable: true,
    };

    if (
      selectedTags &&
      selectedTags.length &&
      tags.length !== selectedTags.length
    ) {
      params.tags = selectedTags;
    }

    const query = buildQuery(params);

    return fetch(`/api/courses/with-related-lessons?${query}`, {
      method: 'GET',
    })
      .then(async res => {
        const resText = await res.text();
        const total = +(res.headers.get('x-total-count') || 0);
        const json = resText ? JSON.parse(resText) : [];
        if (res.status >= 400) {
          console.error(json);
          return {
            total: 0,
            json: [],
          };
        }

        return {
          json,
          total,
        };
      })
      .catch(console.error);
  };

  getLessons = value => {
    const { fetch } = this.context;
    const { selectedTags, lang, tags } = this.props;

    if (!value) {
      return new Promise(resolve =>
        resolve({
          options: [],
        }),
      );
    }

    const params = {
      sort: '-createDate',
      page: 1,
      search: _.trim(value).length < 3 ? '' : value.trim(),
      perPage: 50,
      lang,
      isHomePage: true,
      withoutPopulation: true,
    };

    if (
      selectedTags &&
      selectedTags.length &&
      tags.length !== selectedTags.length
    ) {
      params.tags = selectedTags;
    }

    const query = buildQuery(params);

    return fetch(`/api/lessons?${query}`, { method: 'GET' })
      .then(async res => {
        const resText = await res.text();
        const total = +(res.headers.get('x-total-count') || 0);
        const json = resText ? JSON.parse(resText) : [];
        if (res.status >= 400) {
          console.error(json);
          return {
            total: 0,
            json: [],
          };
        }

        return {
          json,
          total,
        };
      })
      .catch(console.error);
  };

  checkAuth = item => {
    const { auth } = this.props;

    if (isLoggedIn(auth) && item.isAvailable) {
      return true;
    }

    this.setState({
      isRestrictedAccessModalOpened: true,
    });

    return false;
  };

  closeRestrictedAccessModal = () => {
    this.setState({
      isRestrictedAccessModalOpened: false,
    });
  };

  render() {
    const {
      search,
      changeSearchField,
      classes: {
        searchFieldRoot,
        searchFieldInput,
        searchFieldUnderline,
        inputLabelRoot,
        arrowIconClass,
      },
      intl: { formatMessage },
      lang,
    } = this.props;
    const { isRestrictedAccessModalOpened } = this.state;
    const { organizationSlug } = this.context;

    return (
      <>
        <AsyncAutocomplete
          name="search-courses"
          input={{ value: search }}
          onChange={item => {
            const { slug } = item;
            if (!slug) {
              return;
            }

            // course
            if (item.itemType === 'course') {
              history.push(
                `/${lang}${
                  organizationSlug ? `/${organizationSlug}` : ''
                }/courses/${slug}`,
              );
              return;
            }

            if (!this.checkAuth(item)) {
              return;
            }

            history.push(
              `/${lang}${
                organizationSlug ? `/${organizationSlug}` : ''
              }/lessons/${slug}`,
            );
          }}
          onBlur={() => null}
          onDrop={() => null}
          onFocus={() => null}
          onDragStart={() => null}
          meta={{}}
          placeholder={formatMessage(messages.autocompleteSearchText)}
          loadOptions={this.getCoursesAndLessons}
          changeSearchField={changeSearchField}
          searchValue={search}
          noneDefaultPlaceholder
          InputProps={{
            classes: {
              root: searchFieldRoot,
              input: searchFieldInput,
              underline: searchFieldUnderline,
            },
          }}
          InputLabelProps={{
            classes: {
              root: inputLabelRoot,
            },
          }}
          clearIconClass={arrowIconClass}
          arrowIconClass={arrowIconClass}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.slug}
          styles={{
            input: base => ({
              ...base,
              color: '#fff',
            }),
            menu: base => ({
              ...base,
              borderRadius: 0,
            }),
            placeholder: base => ({
              ...base,
              color: '#fff',
            }),
            singleValue: base => ({
              ...base,
              color: '#fff',
            }),
            valueContainer: base => ({
              ...base,
              color: '#fff',
            }),
          }}
        />
        <Modal
          open={isRestrictedAccessModalOpened}
          onClose={this.closeRestrictedAccessModal}
        >
          <RestrictedAccessModal onClose={this.closeRestrictedAccessModal} />
        </Modal>
      </>
    );
  }
}
