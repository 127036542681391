import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

const messages = defineMessages({
  required: { id: 'General.Required', defaultMessage: 'Required' },
  wrongFormat: { id: 'General.WrongFormat', defaultMessage: 'Wrong format' },
  eightChars: {
    id: 'General.PasswordMustHaveAtLeastEightCharacters',
    defaultMessage: 'Password must have at least eight characters',
  },
  passwordStrength: {
    id: 'General.PasswordsMustUse4Types',
    defaultMessage:
      'Passwords must use the four available character types: lowercase letters, uppercase letters, numbers, and symbols',
  },
  matchPassword: {
    id: 'General.PasswordsMustMatch',
    defaultMessage: 'Passwords must match',
  },
  codeFormat: {
    id: 'General.CodeMustConsistOf6Chars',
    defaultMessage: 'Code must match format XXXXXX (6 chars)',
  },
  privacyRequired: {
    id: 'General.privacyRequired',
    defaultMessage: 'You must agree to the Terms and Conditions',
  },
  passwordHasSpaces: {
    id: 'General.passwordHasSpaces',
    defaultMessage: 'Password cannot contain spaces.',
  },
});

export default (
  values,
  { membershipIdVerificationEnabled, integrationPersonIdVerificationEnabled },
) => {
  const errors = {};

  const isSocialSignUp =
    values && (values.facebookId || values.googleId || values.isOffice365);

  if (
    !values.invitationToken &&
    !membershipIdVerificationEnabled &&
    (!values.code || !values.code.trim())
  ) {
    errors.code = <FormattedMessage {...messages.required} />;
  }
  if (
    !values.invitationToken &&
    membershipIdVerificationEnabled &&
    (!values.b2cMembershipId || !values.b2cMembershipId.trim())
  ) {
    errors.b2cMembershipId = <FormattedMessage {...messages.required} />;
  }
  if (
    !values.invitationToken &&
    !membershipIdVerificationEnabled &&
    values.code &&
    values.code.length !== 6 &&
    values.code.length !== 5
  ) {
    errors.code = <FormattedMessage {...messages.codeFormat} />;
  }
  if (!values.email || !values.email.trim()) {
    errors.email = <FormattedMessage {...messages.required} />;
  }
  if (!values.privacy) {
    errors.privacy = <FormattedMessage {...messages.privacyRequired} />;
  }
  if (
    values.email &&
    // eslint-disable-next-line no-useless-escape
    !/^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(  // eslint-disable-line
      values.email,
    )
  ) {
    errors.email = <FormattedMessage {...messages.wrongFormat} />;
  }

  if (!isSocialSignUp) {
    const passHasNumber =
      values && values.password && /\d/g.test(values.password);
    const passHasSpaces =
      values && values.password && /\s/g.test(values.password);
    const passHasUpperCase =
      values && values.password && /[A-Z]/g.test(values.password);
    const passHasLowerCase =
      values && values.password && /[a-z]/g.test(values.password);
    const passHasSpecSymbols =
      values &&
      values.password &&
      /[^a-zA-Z0-9ÅåÄäÖöŽžŠš]/g.test(values.password);

    const passStrength =
      passHasNumber +
        passHasUpperCase +
        passHasLowerCase +
        passHasSpecSymbols || 0;

    if (passHasSpaces) {
      errors.password = <FormattedMessage {...messages.passwordHasSpaces} />;
    }

    if (passStrength < 4) {
      errors.password = <FormattedMessage {...messages.passwordStrength} />;
    }

    if (!values.password || !values.password.trim()) {
      errors.password = <FormattedMessage {...messages.required} />;
    }

    if (values.password && values.password.length < 8) {
      errors.password = <FormattedMessage {...messages.eightChars} />;
    }

    if (!values.confirmPassword || !values.confirmPassword.trim()) {
      errors.confirmPassword = <FormattedMessage {...messages.required} />;
    }

    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = <FormattedMessage {...messages.matchPassword} />;
    }
  }

  if (!values.firstName || !values.firstName.trim()) {
    errors.firstName = <FormattedMessage {...messages.required} />;
  }

  if (!values.lastName || !values.lastName.trim()) {
    errors.lastName = <FormattedMessage {...messages.required} />;
  }

  if (!values.yearBirth) {
    errors.yearBirth = <FormattedMessage {...messages.required} />;
  }

  if (!values.gender) {
    errors.gender = <FormattedMessage {...messages.required} />;
  }

  if (!values.education) {
    errors.education = <FormattedMessage {...messages.required} />;
  }

  if (!values.profession || !values.profession.trim()) {
    errors.profession = <FormattedMessage {...messages.required} />;
  }
  if (
    integrationPersonIdVerificationEnabled &&
    (!values.integrationPersonId || !values.integrationPersonId.trim())
  ) {
    errors.integrationPersonId = <FormattedMessage {...messages.required} />;
  }

  return errors;
};
