// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JQ0vm{max-height:70vh;overflow-y:auto;padding:10px}@media(max-width: 767px){.JQ0vm{max-height:initial}}.sGLf_{padding:10px 0}.Vgwte{background-color:#f6f6f7;border:0;border-radius:3px;color:var(--whitelabeling-primary-color);margin:10px 0;position:relative}@media only screen and (max-width: 767px){.Vgwte{margin:15px 0}.Vgwte>li{min-height:90px}}.AExXA{flex-wrap:wrap;padding:18px}@media only screen and (max-width: 767px){.AExXA{padding:15px 15px 12px}}.AExXA .vntaf{display:none}.yVyzZ{cursor:not-allowed;flex-wrap:wrap;margin:0;padding:18px;width:100%}@media only screen and (max-width: 767px){.yVyzZ{padding:15px 15px 12px}}.vntaf{color:#000;cursor:not-allowed;margin:5px 0 0;width:100%}.AExXA:hover{background-color:#f6f6f7;-webkit-box-shadow:0 0 10px 0 #c9d0dc;box-shadow:0 0 10px 0 #c9d0dc}@media only screen and (max-width: 767px){.AExXA:hover{background-color:rgba(0,0,0,0);-webkit-box-shadow:unset;box-shadow:unset}}.oBeCI{display:flex;flex-direction:column;margin:0}@media only screen and (max-width: 767px){.oBeCI{padding:0 10px 0 6px}}.fbABA{color:inherit;font-size:17px;font-weight:600;line-height:23px}@media only screen and (max-width: 767px){.fbABA{font-weight:normal;overflow:hidden;-o-text-overflow:ellipsis;text-overflow:ellipsis}}.f5eSH{margin-right:16px;max-width:22px;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"orgsContainer": "JQ0vm",
	"orgsList": "sGLf_",
	"orgItemWrapper": "Vgwte",
	"orgListItemActive": "AExXA",
	"orgListItemMessage": "vntaf",
	"orgListItemDisable": "yVyzZ",
	"orgTitleTextWrapper": "oBeCI",
	"orgTitleTextPrimary": "fbABA",
	"orgLogo": "f5eSH"
};
module.exports = ___CSS_LOADER_EXPORT___;
