import React, { useContext } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';

import { Interweave } from 'interweave';
import Tour from '../Tour';
import ContextProvider from '../../context';

const messages = defineMessages({
  signUpCode: {
    id: 'Tours.Register.signUpCode',
    defaultMessage:
      'To join {serviceNamePossessive}, you must have valid signup code.<br />You can get this code from your teacher',
  },
  signUpCodeWhitelabeled: {
    id: 'Tours.Register.signUpCodeWhitelabeled',
    defaultMessage:
      'To sign up for the service, you must have a valid signup code which you can get from your teacher/supervisor.',
  },
  serviceNamePossessive: {
    id: 'Tours.Register.defaultServiceNamePossessive',
    defaultMessage: 'Skhole',
  },
});

export default injectIntl(
  connect(state => ({ lang: state.intl.locale }))(
    ({ intl: { formatMessage }, lang, ...rest }) => {
      const { whitelabeling, organizationSlug } = useContext(ContextProvider);

      const serviceNamePossessive = _.get(
        whitelabeling,
        `serviceNamePossessive.${lang}`,
        formatMessage(messages.serviceNamePossessive),
      );

      return (
        <Tour
          steps={[
            {
              selector: '#sign-up-code',
              content: (
                <Interweave
                  tagName="div"
                  content={formatMessage(
                    organizationSlug
                      ? messages.signUpCodeWhitelabeled
                      : messages.signUpCode,
                    {
                      serviceNamePossessive,
                    },
                  )}
                />
              ),
            },
          ]}
          {...rest}
        />
      );
    },
  ),
);
