import {
  CHANGE_PENDING_TAGS,
  CHANGE_TAGS,
  CHANGE_PENDING_CONTENT_CATEGORIES,
  CHANGE_SEARCH,
  CHANGE_CONTENT_CATEGORIES,
  TOGGLE_NOTIFICATIONS_POPOVER,
  CHANGE_SEARCH_SESSION,
  CHANGE_IS_INITIAL_CONTENT_CATEGORIES_LOAD,
} from '../constants';

const defaultSearchSession = {
  _id: '',
  searchPhrase: '',
};
export default function search(
  state = {
    search: '',
    contentCategories: [],
    pendingContentCategories: [],
    tags: [],
    pendingTags: [],
    isInitialContentCategoriesLoad: true,
    isNotificationsMenuOpened: false,
    searchSession: defaultSearchSession,
  },
  action,
) {
  switch (action.type) {
    case CHANGE_SEARCH:
      return {
        ...state,
        search: action.search,
      };
    case CHANGE_TAGS:
      return {
        ...state,
        tags: action.tags,
      };
    case CHANGE_PENDING_TAGS:
      return {
        ...state,
        pendingTags: action.tags,
      };
    case CHANGE_CONTENT_CATEGORIES:
      return {
        ...state,
        contentCategories: action.contentCategories,
      };
    case CHANGE_PENDING_CONTENT_CATEGORIES:
      return {
        ...state,
        pendingContentCategories: action.contentCategories,
      };
    case CHANGE_IS_INITIAL_CONTENT_CATEGORIES_LOAD:
      return {
        ...state,
        isInitialContentCategoriesLoad: action.isInitialContentCategoriesLoad,
      };
    case TOGGLE_NOTIFICATIONS_POPOVER:
      return {
        ...state,
        isNotificationsMenuOpened: action.isNotificationsMenuOpened,
      };
    case CHANGE_SEARCH_SESSION:
      return { ...state, searchSession: action.searchSession };
    default:
      return state;
  }
}
