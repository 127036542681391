import {
  GET_QUIZ_IMPROVERS,
  GET_QUIZ_IMPROVERS_SUCCESS,
  GET_QUIZ_IMPROVERS_FAIL,
  RESET_QUIZ_IMPROVERS,
} from '../constants';

export default function quizImprovers(
  state = { loading: false, quizImprovers: [], total: 0 },
  action,
) {
  switch (action.type) {
    case GET_QUIZ_IMPROVERS:
      return {
        ...state,
        loading: true,
      };
    case GET_QUIZ_IMPROVERS_SUCCESS:
      return {
        ...state,
        quizImprovers: action.result.json,
        total: action.result.total,
        loading: false,
      };
    case GET_QUIZ_IMPROVERS_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case RESET_QUIZ_IMPROVERS:
      return {
        ...state,
        quizImprovers: [],
      };
    default:
      return state;
  }
}
