import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './CircularProgress.scss';

const CircularProgress = ({ size, styles }) => (
  <progress
    className={s.circularProgress}
    style={{
      width: size,
      height: size,
      ...styles,
    }}
  />
);

CircularProgress.propTypes = {
  size: PropTypes.number,
  styles: PropTypes.object, // eslint-disable-line
};

CircularProgress.defaultProps = {
  size: 80,
  styles: {},
};

export default withStyles(s)(CircularProgress);
