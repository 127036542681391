import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as onlineActions from '../../actions/online';
import isOnline from '../../helpers/checkIsOnline';
import unregisterSw from '../../helpers/unregisterSw';

@connect(
  store => ({
    auth: store.auth,
    lang: store.intl.locale,
    turnedOffPwaOffline: store.online.turnedOffPwaOffline,
  }),
  dispatch =>
    bindActionCreators(
      {
        togglePwaOffOnline: onlineActions.togglePwaOffOnline,
      },
      dispatch,
    ),
)
export default class OnlineProvider extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    togglePwaOffOnline: PropTypes.func.isRequired,
    turnedOffPwaOffline: PropTypes.bool.isRequired,
  };

  static contextTypes = {
    cookiePrefix: PropTypes.string,
    organizationSlug: PropTypes.string,
  };

  async componentDidMount() {
    const { togglePwaOffOnline } = this.props;

    if ('serviceWorker' in navigator) {
      unregisterSw();
    }

    this.connectivityInterval = setInterval(async () => {
      const oldTurnedOffPwaOffline = !this.props.turnedOffPwaOffline;
      const newTurnedOffPwaOffline = await isOnline({ timeout: 5000 });
      if (oldTurnedOffPwaOffline !== newTurnedOffPwaOffline) {
        this.props.togglePwaOffOnline(newTurnedOffPwaOffline);
      }
    }, 5000);
    togglePwaOffOnline(await isOnline({ timeout: 5000 }));
  }

  componentWillUnmount() {
    if (this.connectivityInterval) {
      clearInterval(this.connectivityInterval);
    }
  }

  render() {
    const { children } = this.props;

    return <div>{children}</div>;
  }
}
