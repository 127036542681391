import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import withStyles from 'isomorphic-style-loader/withStyles';

import {
  intlShape,
  injectIntl,
  defineMessages,
  FormattedMessage,
} from 'react-intl';
import { connect } from 'react-redux';
import { isProfessionalMainGroup } from '../../../helpers';
import isLoggedIn from '../../../helpers/checkAuth';

import s from './StudentsLimitReachedSnackbar.css';

const messages = defineMessages({
  studentsLimitIsReached: {
    id: 'navigation.studentsLimitIsReached',
    defaultMessage: 'Students limit is reached in the following subgroups:',
  },
  studentsLimitIsReachedProfessional: {
    id: 'navigation.studentsLimitIsReachedProfessional',
    defaultMessage: 'Students limit is reached in the following subgroups:',
  },
});

@injectIntl
@withStyles(s)
@connect(state => ({
  auth: state.auth,
}))
export default class StudentsLimitReachedSnackbar extends React.Component {
  static propTypes = {
    auth: PropTypes.object,  // eslint-disable-line
    intl: intlShape.isRequired,
  };

  state = {
    isShown: false,
    subgroupsWithReachedLimit: [],
  };

  componentDidMount() {
    this.checkLicencesLimit(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      (isLoggedIn(this.props.auth) && !isLoggedIn(nextProps.auth)) ||
      (_.get(this, 'props.auth.user._id') &&
        _.get(nextProps, 'auth.user._id') &&
        _.get(this, 'props.auth.user._id') !==
          _.get(nextProps, 'auth.user._id'))
    ) {
      this.checkLicencesLimit(nextProps);
    }

    if (
      (!isLoggedIn(this.props.auth) && isLoggedIn(nextProps.auth)) ||
      (_.get(this, 'props.auth.user._id') &&
        _.get(nextProps, 'auth.user._id') &&
        _.get(this, 'props.auth.user._id') !==
          _.get(nextProps, 'auth.user._id'))
    ) {
      this.checkLicencesLimit(nextProps);
    }
  }

  handleCloseUsersLimitReached = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ isShown: false, subgroupsWithReachedLimit: [] });
  };

  checkLicencesLimit = props => {
    const subgroupsWithReachedLimit = _(_.get(props, 'auth.user.subgroups', []))
      .filter(
        ({ maximumStudents, students }) =>
          maximumStudents && maximumStudents <= students,
      )
      .map(item => `${item.name}: ${item.students}/${item.maximumStudents}`)
      .value();

    return this.setState({
      isShown: subgroupsWithReachedLimit.length,
      subgroupsWithReachedLimit,
    });
  };

  render() {
    const { auth } = this.props;
    const { isShown, subgroupsWithReachedLimit } = this.state;

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={isShown}
        onClose={this.handleCloseUsersLimitReached}
        message={
          <div>
            <FormattedMessage
              {...(isProfessionalMainGroup(auth)
                ? messages.studentsLimitIsReachedProfessional
                : messages.studentsLimitIsReached)}
            />
            <ul className={s.list}>
              {subgroupsWithReachedLimit.map(item => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </div>
        }
        classes={{ root: s.root }}
        action={[
          <IconButton
            color="inherit"
            onClick={this.handleCloseUsersLimitReached}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    );
  }
}
