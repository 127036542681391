import React from 'react';
import { Icon } from './index';

const SkholeIcon46 = Icon(
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 40 40"
    style={{ enableBackground: 'new 0 0 40 40' }}
    xmlSpace="preserve"
  >
    <path
      className="st1"
      d="M32.6,9.4C29.4,5.8,24.8,3.7,20,3.7S10.6,5.8,7.4,9.4c-2.7,3.1-4.2,7-4.2,11s1.5,8,4.2,11
      c3.2,3.6,7.8,5.7,12.6,5.7s9.4-2.1,12.6-5.7c2.7-3.1,4.2-7,4.2-11S35.3,12.4,32.6,9.4z M23.1,29.8c0,0.2-0.1,0.3-0.1,0.5
      c0,0.1-0.1,0.2-0.1,0.2c0,0.1-0.1,0.3-0.1,0.4c0,0.1-0.1,0.2-0.1,0.2c0,0.1-0.1,0.3-0.1,0.4c0,0.1-0.1,0.1-0.1,0.2
      c-0.1,0.1-0.1,0.3-0.2,0.4c0,0,0,0.1-0.1,0.1c-0.1,0.2-0.2,0.3-0.2,0.5c0,0,0,0.1-0.1,0.1c-0.1,0.1-0.1,0.2-0.2,0.3
      c0,0.1-0.1,0.1-0.1,0.1c0,0.1-0.1,0.1-0.1,0.2c0,0-0.1,0.1-0.1,0.1c0,0.1-0.1,0.1-0.1,0.2c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1
      c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0.1c0,0-0.1,0-0.1,0.1
      c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.2,0s-0.1,0-0.2,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0
      c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1
      c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2c0,0-0.1-0.1-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2c0,0-0.1-0.1-0.1-0.1
      c-0.1-0.1-0.1-0.2-0.2-0.3c0,0,0-0.1-0.1-0.1c-0.1-0.1-0.2-0.3-0.2-0.5c0,0,0-0.1-0.1-0.1c-0.1-0.1-0.1-0.3-0.2-0.4
      c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.1-0.3-0.1-0.4c0-0.1-0.1-0.2-0.1-0.2
      c0-0.1-0.1-0.3-0.1-0.5c0-0.1,0-0.2-0.1-0.3c0-0.1,0-0.1-0.1-0.2c0.2,0,0.4-0.1,0.6-0.1c0.2,0,0.3,0,0.5,0c1.4-0.1,2.7-0.1,4.1,0
      c0.2,0,0.3,0,0.5,0c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1-0.1,0.2C23.1,29.7,23.1,29.8,23.1,29.8z M16.9,11c0-0.2,0.1-0.3,0.1-0.5
      c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0.1-0.3,0.2-0.4c0-0.1,0.1-0.1,0.1-0.2
      c0.1-0.1,0.1-0.3,0.2-0.4c0,0,0-0.1,0.1-0.1c0.1-0.2,0.2-0.3,0.2-0.5c0,0,0-0.1,0.1-0.1c0.1-0.1,0.1-0.2,0.2-0.3
      c0-0.1,0.1-0.1,0.1-0.1c0-0.1,0.1-0.1,0.1-0.2c0,0,0.1-0.1,0.1-0.1c0-0.1,0.1-0.1,0.1-0.2c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1
      c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1-0.1
      c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0.1,0,0.1,0,0.2,0s0.1,0,0.2,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0
      c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1
      c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0,0.1,0.1,0.1,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0,0.1,0.1,0.1,0.1
      c0.1,0.1,0.1,0.2,0.2,0.3c0,0,0,0.1,0.1,0.1c0.1,0.1,0.2,0.3,0.2,0.5c0,0,0,0.1,0.1,0.1c0.1,0.1,0.1,0.3,0.2,0.4
      c0,0.1,0.1,0.1,0.1,0.2c0.1,0.1,0.1,0.3,0.2,0.4c0,0.1,0.1,0.2,0.1,0.2c0,0.1,0.1,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.2
      c0,0.1,0.1,0.3,0.1,0.5c0,0.1,0,0.2,0.1,0.3c0,0.1,0,0.1,0.1,0.2c-0.2,0-0.4,0.1-0.6,0.1c-0.2,0-0.3,0-0.5,0c-1.4,0.1-2.7,0.1-4.1,0
      c-0.2,0-0.3,0-0.5,0c-0.2,0-0.4-0.1-0.6-0.1c0-0.1,0-0.1,0.1-0.2C16.9,11.2,16.9,11.1,16.9,11z M22.3,26.8c-0.8-0.1-1.5-0.1-2.3-0.1
      s-1.5,0-2.3,0.1c-0.2,0-0.4,0-0.6,0.1c-0.3,0-0.6,0-0.8,0.1c0-0.1,0-0.2,0-0.3c-0.1-0.7-0.2-1.5-0.3-2.3c0,0,0,0,0,0
      c-0.1-0.8-0.1-1.6-0.1-2.4c0-0.1,0-0.2,0-0.3h8.4c0,0.1,0,0.2,0,0.3c0,0.8-0.1,1.6-0.1,2.4c0,0,0,0,0,0c-0.1,0.8-0.2,1.5-0.3,2.3
      c0,0.1,0,0.2,0,0.3c-0.3,0-0.6-0.1-0.8-0.1C22.7,26.8,22.5,26.8,22.3,26.8z M17.8,14.1c0.7,0.1,1.4,0.1,2.1,0.1c0,0,0.1,0,0.1,0
      s0.1,0,0.1,0c0.7,0,1.4,0,2.1-0.1c0.2,0,0.4,0,0.7-0.1c0.3,0,0.5,0,0.8-0.1c0,0.1,0,0.2,0,0.3c0.1,0.7,0.2,1.5,0.3,2.3c0,0,0,0,0,0
      c0.1,0.8,0.1,1.6,0.1,2.4c0,0.1,0,0.2,0,0.3h-8.4c0-0.1,0-0.2,0-0.3c0-0.8,0.1-1.6,0.1-2.4c0,0,0,0,0,0c0.1-0.8,0.2-1.5,0.3-2.3
      c0-0.1,0-0.2,0-0.3c0.3,0,0.5,0,0.8,0.1C17.3,14,17.5,14.1,17.8,14.1z M26.7,19.2c0-0.1,0-0.2,0-0.3c0-0.8-0.1-1.6-0.1-2.4
      c0-0.1,0-0.1,0-0.2c-0.1-0.8-0.2-1.7-0.3-2.5c0-0.1,0-0.2,0-0.3c0.2,0,0.3-0.1,0.5-0.1c0.2,0,0.3-0.1,0.5-0.1
      c0.6-0.1,1.2-0.3,1.7-0.5c0,0,0,0,0,0c0.5-0.2,1.1-0.4,1.6-0.6c0.1-0.1,0.3-0.1,0.4-0.2c0.1-0.1,0.3-0.1,0.4-0.2
      c1.6,2.1,2.6,4.7,2.9,7.4H26.7z M29.5,9.9c-0.1,0-0.2,0.1-0.3,0.1c-0.5,0.2-0.9,0.4-1.4,0.5c-0.2,0.1-0.4,0.1-0.6,0.2
      c-0.4,0.1-0.8,0.2-1.2,0.3c-0.1,0-0.2,0-0.3,0.1c0-0.1,0-0.1,0-0.1c-0.1-0.3-0.2-0.6-0.3-0.9c0-0.1-0.1-0.2-0.1-0.3
      c-0.1-0.3-0.2-0.6-0.3-0.9c0-0.1,0-0.1-0.1-0.2c-0.1-0.3-0.3-0.6-0.4-0.9c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.4-0.3-0.6
      c0,0,0-0.1-0.1-0.1C26.1,7.4,28,8.4,29.5,9.9z M15.9,6.9c-0.1,0.2-0.2,0.4-0.3,0.6c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.3-0.3,0.6-0.4,0.9
      c0,0.1,0,0.1-0.1,0.2c-0.1,0.3-0.2,0.6-0.3,0.9c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.3-0.2,0.6-0.3,0.9c0,0,0,0.1,0,0.1
      c-0.1,0-0.2,0-0.3-0.1c-0.4-0.1-0.8-0.2-1.2-0.3c-0.2-0.1-0.4-0.1-0.6-0.2c-0.5-0.2-1-0.3-1.4-0.5c-0.1,0-0.2-0.1-0.3-0.1
      c1.6-1.4,3.5-2.5,5.5-3.1C15.9,6.8,15.9,6.9,15.9,6.9z M8.7,11.8C8.8,11.9,9,11.9,9.1,12c0.1,0.1,0.3,0.1,0.4,0.2
      c0.5,0.2,1,0.4,1.6,0.6c0,0,0,0,0,0c0.5,0.2,1.1,0.3,1.7,0.5c0.2,0,0.3,0.1,0.5,0.1c0.2,0,0.3,0.1,0.5,0.1c0,0.1,0,0.2,0,0.3
      c-0.1,0.8-0.2,1.6-0.3,2.5c0,0.1,0,0.1,0,0.2c-0.1,0.8-0.1,1.6-0.1,2.4c0,0.1,0,0.2,0,0.3H5.8C6,16.5,7,13.9,8.7,11.8z M13.3,21.7
      c0,0.1,0,0.2,0,0.3c0,0.8,0.1,1.6,0.1,2.4c0,0.1,0,0.1,0,0.2c0.1,0.8,0.2,1.7,0.3,2.5c0,0.1,0,0.2,0,0.3c-0.2,0-0.3,0.1-0.5,0.1
      c-0.2,0-0.3,0.1-0.5,0.1c-0.6,0.1-1.2,0.3-1.7,0.5c0,0,0,0,0,0c-0.5,0.2-1.1,0.4-1.6,0.6c-0.1,0.1-0.3,0.1-0.4,0.2
      C9,28.9,8.8,29,8.7,29.1C7,26.9,6,24.3,5.8,21.7H13.3z M10.5,31c0.1,0,0.2-0.1,0.3-0.1c0.5-0.2,0.9-0.4,1.4-0.5
      c0.2-0.1,0.4-0.1,0.6-0.2c0.4-0.1,0.8-0.2,1.2-0.3c0.1,0,0.2,0,0.3-0.1c0,0,0,0.1,0,0.1c0.1,0.3,0.2,0.6,0.3,0.9
      c0,0.1,0.1,0.2,0.1,0.3c0.1,0.3,0.2,0.6,0.3,0.9c0,0.1,0,0.1,0.1,0.2c0.1,0.3,0.2,0.6,0.4,0.9c0,0.1,0.1,0.1,0.1,0.2
      c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0.1,0.1,0.1C13.9,33.5,12,32.4,10.5,31z M24.1,33.9c0.1-0.2,0.2-0.4,0.3-0.6c0-0.1,0.1-0.1,0.1-0.2
      c0.1-0.3,0.3-0.6,0.4-0.9c0-0.1,0-0.1,0.1-0.2c0.1-0.3,0.2-0.6,0.3-0.9c0-0.1,0.1-0.2,0.1-0.3c0.1-0.3,0.2-0.6,0.3-0.9
      c0,0,0-0.1,0-0.1c0.1,0,0.2,0,0.3,0.1c0.4,0.1,0.8,0.2,1.2,0.3c0.2,0.1,0.4,0.1,0.6,0.2c0.5,0.2,1,0.3,1.4,0.5
      c0.1,0,0.2,0.1,0.3,0.1c-1.6,1.4-3.5,2.5-5.5,3.1C24.1,34,24.1,34,24.1,33.9z M31.3,29.1c-0.1-0.1-0.3-0.1-0.4-0.2
      c-0.1-0.1-0.3-0.1-0.4-0.2c-0.5-0.2-1-0.4-1.6-0.6c0,0,0,0,0,0c-0.5-0.2-1.1-0.3-1.7-0.5c-0.2,0-0.3-0.1-0.5-0.1
      c-0.2,0-0.3-0.1-0.5-0.1c0-0.1,0-0.2,0-0.3c0.1-0.8,0.2-1.6,0.3-2.5c0-0.1,0-0.1,0-0.2c0.1-0.8,0.1-1.6,0.1-2.4c0-0.1,0-0.2,0-0.3
      h7.5C34,24.3,33,26.9,31.3,29.1z"
    />
  </svg>,
);

export default SkholeIcon46;
