import _ from 'lodash';

import {
  GET_TRANSCODING_JOBS,
  GET_TRANSCODING_JOBS_SUCCESS,
  GET_TRANSCODING_JOBS_FAIL,
  RESET_TRANSCODING_JOBS,
  UPDATE_TRANSCODING_JOB,
} from '../constants';

const initialState = {
  loading: false,
  loaded: false,
  jobs: [],
  total: 0,
};

export default function transcodingJobs(state = initialState, action) {
  switch (action.type) {
    case GET_TRANSCODING_JOBS:
      return {
        ...state,
        loading: true,
      };
    case GET_TRANSCODING_JOBS_SUCCESS:
      return {
        ...state,
        jobs: action.result.json,
        total: action.result.total,
        loading: false,
        loaded: true,
      };
    case GET_TRANSCODING_JOBS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case UPDATE_TRANSCODING_JOB: {
      const job = _.cloneDeep(action.job);
      const jobs = [...state.jobs];
      const index = _.findIndex(jobs, ({ _id }) => job._id === _id);

      if (index !== -1) {
        jobs[index] = job;
      }

      return {
        ...state,
        jobs,
      };
    }
    case RESET_TRANSCODING_JOBS:
      return initialState;
    default:
      return state;
  }
}
