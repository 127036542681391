import React from 'react';
import { Icon } from './index';

const SkholeIcon06 = Icon(
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 40 40"
    style={{ enableBackground: 'new 0 0 40 40' }}
    xmlSpace="preserve"
  >
    <path
      className="st1"
      d="M35.2,4.7H4.8c-0.7,0-1.2,0.6-1.2,1.2v3.5c0,0.7,0.6,1.2,1.2,1.2h1.1v15.1c0,0.7,0.6,1.2,1.2,1.2h5.6l-1,6.7
      c-0.1,0.7,0.4,1.3,1.1,1.4c0.1,0,0.1,0,0.2,0c0.6,0,1.1-0.4,1.2-1.1l1-7.1h9.5l1,7.1c0.1,0.6,0.6,1.1,1.2,1.1c0.1,0,0.1,0,0.2,0
      c0.7-0.1,1.2-0.7,1.1-1.4l-1-6.7h5.6c0.7,0,1.2-0.6,1.2-1.2V10.7h1.1c0.7,0,1.2-0.6,1.2-1.2V5.9C36.4,5.2,35.9,4.7,35.2,4.7z
       M6.1,7.2h27.8v1h-1.1H7.2H6.1V7.2z M31.6,24.5H8.4V10.7h23.2V24.5z"
    />
  </svg>,
);

export default SkholeIcon06;
