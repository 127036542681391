import {
  GET_CERTIFICATE,
  GET_CERTIFICATE_SUCCESS,
  GET_CERTIFICATE_FAIL,
  RESET_CERTIFICATE,
  SET_CERTIFICATE,
} from '../constants';

const initialState = { loading: false, cert: {} };

export default function editCertificate(state = initialState, action) {
  switch (action.type) {
    case GET_CERTIFICATE:
      return {
        ...state,
        loading: true,
      };
    case GET_CERTIFICATE_SUCCESS:
      return {
        ...state,
        cert: action.result.json,
        loading: false,
      };
    case GET_CERTIFICATE_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case SET_CERTIFICATE:
      return {
        ...state,
        cert: action.cert,
      };
    case RESET_CERTIFICATE:
      return initialState;
    default:
      return state;
  }
}
