import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ButtonBase from '@material-ui/core/ButtonBase';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';

import { intlShape, injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';

import withStyles from 'isomorphic-style-loader/withStyles';
import { bindActionCreators } from 'redux';

import * as tourActions from '../../../actions/tours';
import {
  isAdmin,
  isTeacher,
  isWhitelabeledOrganization,
} from '../../../helpers';
import isLoggedIn from '../../../helpers/checkAuth';
import QuestionSolidIcon from '../../statelessComponents/Icons/QuestionSolidIcon';

import s from './HelpButton.scss';

const faq = {
  'en-US': 'https://support.skhole.fi/faq',
  'sv-SE': 'https://support.skhole.fi/faq',
  'fi-FI': 'https://support.skhole.fi/ukk',
};

const messages = defineMessages({
  generalHelp: {
    id: 'HelpButton.generalHelp',
    defaultMessage: 'General help',
  },
  currentPageHelp: {
    id: 'HelpButton.currentPageHelp',
    defaultMessage: 'Current page help',
  },
  help: {
    id: 'navigation.help',
    defaultMessage: 'Help',
  },
  releaseNotes: {
    id: 'HelpButton.releaseNotes',
    defaultMessage: 'Release notes',
  },
  bookUserTraining: {
    id: 'HelpButton.bookUserTraining',
    defaultMessage: 'Book user training',
  },
});

@injectIntl
@withStyles(s)
@connect(
  state => ({
    auth: state.auth,
    locale: state.intl.locale,
  }),
  dispatch =>
    bindActionCreators(
      {
        toggleTour: tourActions.toggleTour,
      },
      dispatch,
    ),
)
export default class HelpButton extends React.Component {
  static propTypes = {
    auth: PropTypes.object, // eslint-disable-line
    intl: intlShape.isRequired,
    className: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    toggleTour: PropTypes.func.isRequired,
    tour: PropTypes.string,
    inverted: PropTypes.bool,
    lessonChangelogElement: PropTypes.func,
  };

  static defaultProps = {
    tour: '',
    inverted: false,
    lessonChangelogElement: () => null,
  };

  static contextTypes = {
    tour: PropTypes.string,
    fetch: PropTypes.func,
    organizationSlug: PropTypes.string,
    whitelabeling: PropTypes.shape({}),
  };

  state = {
    isShown: false,
  };

  button = null;

  togglePopover = isShown => {
    this.setState({ isShown });
  };

  render() {
    const {
      className,
      locale,
      intl: { formatMessage },
      toggleTour,
      auth,
      inverted,
      lessonChangelogElement,
    } = this.props;
    const tour = this.props.tour || this.context.tour;
    const { isShown } = this.state;
    const { organizationSlug, whitelabeling } = this.context;

    const generalHelpUrl =
      isWhitelabeledOrganization(_.get(auth, 'user.mainGroup')) &&
      _.get(whitelabeling, `generalHelpUrl.${locale}`)
        ? _.get(whitelabeling, `generalHelpUrl.${locale}`)
        : faq[locale];

    const isBookUserTrainingHelpVisibleForWhitelabeling =
      !!organizationSlug && _.get(whitelabeling, 'bookUserTrainingHelpVisible');

    return (
      <>
        <Tooltip title={formatMessage(messages.help)}>
          <ButtonBase
            aria-label={formatMessage(messages.help)}
            className={className}
            onClick={() => this.togglePopover(true)}
            key={tour}
            id="navigation-help-btn"
            buttonRef={node => {
              this.button = node;
            }}
          >
            <QuestionSolidIcon
              color={inverted ? 'primary' : '#fff'}
              className={s.helpIcon}
            />
          </ButtonBase>
        </Tooltip>
        <Popover
          open={isShown}
          anchorEl={this.button}
          anchorReference="anchorEl"
          onClose={() => this.togglePopover(false)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          classes={{ paper: s.popover }}
        >
          <div className={s.popoverContent}>
            <List component="nav">
              <ListItem
                component="a"
                href={generalHelpUrl}
                target="_blank"
                button
                dense
              >
                <ListItemText
                  primary={formatMessage(messages.generalHelp)}
                  classes={{
                    textDense: s.groupMenuItemText,
                    root: s.groupMenuItemText,
                  }}
                />
              </ListItem>
              {lessonChangelogElement(this.togglePopover)}
              {locale === 'fi-FI' &&
                isTeacher(auth) &&
                !isAdmin(auth) &&
                (!organizationSlug ||
                  isBookUserTrainingHelpVisibleForWhitelabeling) && (
                  <ListItem
                    component="a"
                    href="https://www.calendly.com/skhole"
                    target="_blank"
                    button
                    dense
                  >
                    <ListItemText
                      primary={formatMessage(messages.bookUserTraining)}
                      classes={{
                        textDense: s.groupMenuItemText,
                        root: s.groupMenuItemText,
                      }}
                    />
                  </ListItem>
                )}
              {tour && isLoggedIn(auth) && (
                <ListItem
                  component={ButtonBase}
                  onClick={() => {
                    toggleTour(tour, true);
                    this.togglePopover(false);
                  }}
                  button
                  dense
                >
                  <ListItemText
                    primary={formatMessage(messages.currentPageHelp)}
                    classes={{
                      textDense: s.groupMenuItemText,
                      root: s.groupMenuItemText,
                    }}
                  />
                </ListItem>
              )}
              <ListItem
                component="a"
                href="https://support.skhole.fi/release-notes"
                target="_blank"
                button
                dense
              >
                <ListItemText
                  primary={formatMessage(messages.releaseNotes)}
                  classes={{
                    textDense: s.groupMenuItemText,
                    root: s.groupMenuItemText,
                  }}
                />
              </ListItem>
            </List>
          </div>
        </Popover>
      </>
    );
  }
}
