import {
  GET_MAIN_GROUPS,
  GET_MAIN_GROUPS_SUCCESS,
  GET_MAIN_GROUPS_FAIL,
  GET_ALL_MAIN_GROUPS,
  GET_ALL_MAIN_GROUPS_SUCCESS,
  GET_ALL_MAIN_GROUPS_FAIL,
  RESET_MAIN_GROUPS,
} from '../constants';

const initialState = {
  loading: false,
  loaded: false,
  groups: [],
  total: 0,
  loadingAll: false,
  allGroups: [],
};

export default function mainGroups(state = initialState, action) {
  switch (action.type) {
    case GET_MAIN_GROUPS:
      return {
        ...state,
        loading: true,
      };
    case GET_MAIN_GROUPS_SUCCESS:
      return {
        ...state,
        groups: action.result.json,
        total: action.result.total,
        loading: false,
        loaded: true,
      };
    case GET_MAIN_GROUPS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case GET_ALL_MAIN_GROUPS:
      return {
        ...state,
        loadingAll: true,
      };
    case GET_ALL_MAIN_GROUPS_SUCCESS:
      return {
        ...state,
        allGroups: action.result.json,
        loadingAll: false,
      };
    case GET_ALL_MAIN_GROUPS_FAIL:
      return {
        ...state,
        loadingAll: false,
      };
    case RESET_MAIN_GROUPS:
      return initialState;
    default:
      return state;
  }
}
