import {
  QUIZ_RESULT__GET_RESULT,
  QUIZ_RESULT__GET_RESULT_SUCCESS,
  QUIZ_RESULT__GET_RESULT_FAIL,
  RESET_RESULT,
  QUIZ_RESULT__GET_RESULT_ATTEMPTS,
  QUIZ_RESULT__GET_RESULT_ATTEMPTS_SUCCESS,
  QUIZ_RESULT__GET_RESULT_ATTEMPTS_FAIL,
} from '../constants';

const initialState = { loading: false, quizResult: {}, attempts: 0 };

export default function quizResult(state = initialState, action) {
  switch (action.type) {
    case QUIZ_RESULT__GET_RESULT:
      return {
        ...state,
        loading: true,
      };
    case QUIZ_RESULT__GET_RESULT_SUCCESS:
      return {
        ...state,
        quizResult: action.result.json,
        loading: false,
      };
    case QUIZ_RESULT__GET_RESULT_FAIL:
      return {
        ...state,
        loading: false,
      };
    case QUIZ_RESULT__GET_RESULT_ATTEMPTS:
      return {
        ...state,
        loading: true,
      };
    case QUIZ_RESULT__GET_RESULT_ATTEMPTS_SUCCESS:
      return {
        ...state,
        attempts: action.result.json,
        loading: false,
      };
    case QUIZ_RESULT__GET_RESULT_ATTEMPTS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case RESET_RESULT:
      return initialState;
    default:
      return state;
  }
}
