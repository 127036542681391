import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import {
  Field,
  reduxForm,
  SubmissionError,
  propTypes as reduxFormPropTypes,
} from 'redux-form';

import { connect } from 'react-redux';
import {
  defineMessages,
  FormattedMessage,
  intlShape,
  injectIntl,
} from 'react-intl';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '../statelessComponents/FormFields/TextField';

import s from './ForgotPassword.scss';
import modalStyles from '../styles/Modal.scss';
import textFieldStyles from '../styles/TextField.scss';
import validate from './forgotPasswordValidation';

const messages = defineMessages({
  emailLabel: {
    id: 'ForgotPassword.email',
    defaultMessage: 'Email',
    description: 'Email label displayed on the forgot password form',
  },
  submitButtonText: {
    id: 'ForgotPassword.submitButtonText',
    defaultMessage: 'Submit',
    description: 'Submit button text on the forgot password form',
  },
  submitInstructionsSentText: {
    id: 'ForgotPassword.submitInstructionsSentText',
    defaultMessage:
      'Instructions for password change have been sent to your email,check you inbox',
    description: 'Text displayed in snackbar when instructions sent success',
  },
  passwordRecoveryTitle: {
    id: 'ForgotPassword.passwordRecoveryTitle',
    defaultMessage: 'Password recovery',
    description:
      'Password recovery displayed on the forgot password form as title',
  },
});

@injectIntl
@withStyles(s)
@reduxForm({
  form: 'forgotPassword',
  validate,
})
@connect(() => ({}))
export default class ForgotPassword extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    ...reduxFormPropTypes,
    intl: intlShape.isRequired,
  };

  static contextTypes = {
    fetch: PropTypes.func,
  };

  state = {
    isSnackBarShown: false,
  };

  submit = data =>
    this.context
      .fetch('/api/auth/recover-password', {
        method: 'POST',
        body: JSON.stringify(data),
      })
      .catch(err => {
        throw new SubmissionError({ _error: err && err.message });
      })
      .then(async res => {
        if (res.status === 200) {
          this.setState({
            isSnackBarShown: true,
          });
          this.props.reset();
          return;
        }

        const err = await res.json();
        if (err.error) {
          throw new SubmissionError({ _error: err.error });
        }
        throw new SubmissionError({ _error: err && err.message });
      });

  handleCloseSnackbar = () => {
    this.setState({
      isSnackBarShown: false,
    });
    this.props.onClose();
  };

  render() {
    const { handleSubmit, submitting, error, onClose, intl } = this.props;
    const { isSnackBarShown } = this.state;

    return (
      <div className={modalStyles.paper}>
        <Typography variant="h4" component="h1" className={s.lead}>
          <FormattedMessage {...messages.passwordRecoveryTitle} />
        </Typography>

        <form noValidate autoComplete="off">
          <Field
            name="email"
            type="email"
            margin="dense"
            placeholder={intl.formatMessage(messages.emailLabel)}
            component={TextField}
            InputProps={{
              classes: {
                root: textFieldStyles.textField,
                input: textFieldStyles.textFieldInput,
                focused: textFieldStyles.textFieldFocused,
                disabled: textFieldStyles.textFieldDisabled,
                error: textFieldStyles.textFieldError,
              },
            }}
            fullWidth
            normalize={value => value && value.toLowerCase()}
          />
          {error && (
            <Typography gutterBottom color="error">
              {error}
            </Typography>
          )}
          <Snackbar
            open={isSnackBarShown}
            message={
              <span id="message-id">
                <FormattedMessage {...messages.submitInstructionsSentText} />
              </span>
            }
            onClose={this.handleCloseSnackbar}
            autoHideDuration={3000}
          />
          <Button
            color="primary"
            type="submit"
            margin="normal"
            size="dense"
            variant="contained"
            onClick={handleSubmit(this.submit)}
            disabled={submitting}
            className={s.btn}
            fullWidth
          >
            <FormattedMessage {...messages.submitButtonText} />
          </Button>
        </form>
        <div className={modalStyles.closeModalBtn}>
          <IconButton onClick={onClose}>
            <CloseIcon className={modalStyles.closeIcon} />
          </IconButton>
        </div>
      </div>
    );
  }
}
