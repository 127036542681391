import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

// external-global styles must be imported in your JS.
import normalizeCss from 'normalize.css';
import { connect } from 'react-redux';
import Footer from '../Footer';
import WsProvider from '../WsProvider/WsProvider';
import s from './Layout.css';
import Header from '../Header';
import GeneralLayout from '../GeneralLayout/GeneralLayout';
import withIntercom from '../../decorators/withIntercom';
import ContextProvider from '../ContextProvider/ContextProvider';

@connect(state => ({
  auth: state.auth,
}))
@withStyles(normalizeCss, s)
@withIntercom
export default class Layout extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    context: PropTypes.shape({
      params: PropTypes.shape({}),
      path: PropTypes.string,
      locale: PropTypes.string,
      route: PropTypes.shape({}),
      router: PropTypes.shape({}),
      query: PropTypes.shape({}),
    }),
    tour: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    context: {
      params: {},
    },
    tour: '',
    className: '',
  };

  static childContextTypes = {
    tour: PropTypes.string,
    headerRef: PropTypes.node,
  };

  getChildContext() {
    return {
      tour: this.props.tour,
      headerRef: this.headerRef,
    };
  }

  render() {
    return (
      <ContextProvider context={this.props.context} tour={this.props.tour}>
        <GeneralLayout>
          <WsProvider>
            <Header
              ref={node => {
                this.headerRef = node;
              }}
            />
            <div className={classNames(s.content, this.props.className)}>
              {this.props.children}
            </div>
            <Footer />
          </WsProvider>
        </GeneralLayout>
      </ContextProvider>
    );
  }
}
