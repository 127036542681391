import {
  EDIT_QUESTION__GET_QUESTION,
  EDIT_QUESTION__GET_QUESTION_SUCCESS,
  EDIT_QUESTION__GET_QUESTION_FAIL,
  RESET_QUESTION,
  SET_QUESTION,
  SET_QUESTION_ASSOCIATED_COURSE,
  EDIT_QUESTION__SET_OPENED_EDITOR,
  EDIT_QUESTION__GET_OPENED_EDITOR,
  EDIT_QUESTION__GET_OPENED_EDITOR_SUCCESS,
  EDIT_QUESTION__GET_OPENED_EDITOR_FAIL,
  EDIT_QUESTION_TAKE_EDIT_CONTROL,
  EDIT_QUESTION_TAKE_EDIT_CONTROL_SUCCESS,
  EDIT_QUESTION_TAKE_EDIT_CONTROL_FAIL,
} from '../constants';

const initialState = {
  loading: false,
  question: {
    type: 'multiple choice',
    access: 'Group',
    points: 1,
    allowDifferentPoints: false,
    generalAnswers: [
      {
        description: '',
        isCorrect: false,
      },
    ],
    fillInBlankAnswers: [''],
    trueOrFalseAnswers: '',
    caseSensitiveFillInBlank: true,
    allowedDecimals: 'no preference',
  },
  openedEditor: {},
  loadingOpenedEditor: false,
  error: '',
  takingControl: false,
  takenControl: false,
  takingControlError: '',
};

export default function editQuestion(state = initialState, action) {
  switch (action.type) {
    case EDIT_QUESTION__GET_QUESTION:
      return {
        ...state,
        loading: true,
      };
    case EDIT_QUESTION__GET_QUESTION_SUCCESS:
      return {
        ...state,
        question: action.result.json,
        loading: false,
        loadingOpenedEditor: true,
      };
    case EDIT_QUESTION__GET_QUESTION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case SET_QUESTION:
      return {
        ...state,
        question: action.question,
      };
    case SET_QUESTION_ASSOCIATED_COURSE:
      return {
        ...state,
        question: {
          ...state.question,
          associatedCourses: action.associatedCourses,
        },
      };
    case EDIT_QUESTION__GET_OPENED_EDITOR:
      return {
        ...state,
        loadingOpenedEditor: true,
        error: '',
      };

    case EDIT_QUESTION__GET_OPENED_EDITOR_SUCCESS:
      return {
        ...state,
        loadingOpenedEditor: false,
        openedEditor: action.result.status < 400 ? action.result.json : {},
      };

    case EDIT_QUESTION__GET_OPENED_EDITOR_FAIL:
      return {
        ...state,
        loadingOpenedEditor: false,
        error: action.error,
      };

    case EDIT_QUESTION__SET_OPENED_EDITOR:
      return {
        ...state,
        openedEditor: action.openedEditor,
        takenControl: false,
        takingControl: false,
        takingControlError: '',
      };

    case EDIT_QUESTION_TAKE_EDIT_CONTROL:
      return {
        ...state,
        takingControl: true,
        takenControl: false,
        takingControlError: '',
      };

    case EDIT_QUESTION_TAKE_EDIT_CONTROL_SUCCESS:
      return {
        ...state,
        takingControl: false,
        takenControl: true,
      };

    case EDIT_QUESTION_TAKE_EDIT_CONTROL_FAIL:
      return {
        ...state,
        takingControl: false,
        takingControlError: action.error,
      };
    case RESET_QUESTION:
      return initialState;
    default:
      return state;
  }
}
