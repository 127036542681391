import {
  GET_DISCUSSION_MESSAGES,
  GET_DISCUSSION_MESSAGES_SUCCESS,
  GET_DISCUSSION_MESSAGES_FAIL,
  RESET_DISCUSSIONS,
} from '../constants';

const initialState = { loading: false, loaded: false, messages: [], total: 0 };

export default function discussions(state = initialState, action) {
  switch (action.type) {
    case GET_DISCUSSION_MESSAGES:
      return {
        ...state,
        loading: true,
      };
    case GET_DISCUSSION_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: action.result.json,
        total: action.result.total,
        loading: false,
        loaded: true,
      };
    case GET_DISCUSSION_MESSAGES_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error,
      };
    case RESET_DISCUSSIONS:
      return initialState;
    default:
      return state;
  }
}
