import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';

import {
  reduxForm,
  SubmissionError,
  propTypes as reduxFormPropTypes,
} from 'redux-form';
import _ from 'lodash';
import {
  defineMessages,
  FormattedMessage,
  intlShape,
  injectIntl,
} from 'react-intl';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';

import modalStyles from '../styles/Modal.scss';
import s from './JoinMainGroup.scss';

const messages = defineMessages({
  submitButtonText: {
    id: 'JoinMainGroup.submitButtonText',
    defaultMessage: 'Submit',
  },
  title: {
    id: 'JoinMainGroup.title',
    defaultMessage: 'Join main group',
  },
  snackbar: {
    id: 'JoinMainGroup.snackbar',
    defaultMessage: 'You have been successfully joined to the main group',
  },
});

@injectIntl
@withStyles(s)
@withStyles(modalStyles)
@reduxForm({
  form: 'JoinMainGroup',
})
@connect(state => ({
  locale: state.intl.locale,
}))
export default class JoinMainGroup extends React.Component {
  static propTypes = {
    query: PropTypes.object, // eslint-disable-line
    intl: intlShape.isRequired,
    onClose: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
    ...reduxFormPropTypes,
  };

  static contextTypes = {
    fetch: PropTypes.func,
    organizationSlug: PropTypes.string,
  };

  state = {
    isSent: false,
  };

  componentDidMount() {
    document.querySelector('#submit-join-main-group').click();
  }

  submit = () => {
    const { query, onClose, locale } = this.props;
    const { organizationSlug } = this.context;
    const token = _.get(query, 'token');

    return this.context
      .fetch('/api/auth/join-main-group-by-token', {
        method: 'POST',
        body: JSON.stringify({ token }),
      })
      .then(async res => {
        if (res.status === 200) {
          this.setState({
            isSent: true,
          });
          setTimeout(() => {
            onClose();
            window.location = `/${locale}${
              organizationSlug ? `/${organizationSlug}` : ''
            }`;
          }, 3000);
          return;
        }

        const err = await res.json();
        throw new SubmissionError({ _error: err && err.message });
      });
  };

  render() {
    const {
      handleSubmit,
      submitting,
      error,
      onClose,
      intl: { formatMessage },
    } = this.props;
    const { isSent } = this.state;

    return (
      <div className={modalStyles.paper}>
        <Typography variant="h4" component="h1" className={s.lead}>
          <FormattedMessage {...messages.title} />
        </Typography>

        <form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(this.submit)}
        >
          {error && (
            <Typography gutterBottom color="error">
              {error}
            </Typography>
          )}
          <Snackbar open={isSent} message={formatMessage(messages.snackbar)} />
          <Button
            color="primary"
            type="submit"
            margin="normal"
            size="large"
            variant="contained"
            disabled={submitting || isSent}
            className={s.button}
            id="submit-join-main-group"
            fullWidth
          >
            <FormattedMessage {...messages.submitButtonText} />
          </Button>
        </form>
        <div className={modalStyles.closeModalBtn}>
          <IconButton onClick={onClose}>
            <CloseIcon className={modalStyles.closeIcon} />
          </IconButton>
        </div>
      </div>
    );
  }
}
