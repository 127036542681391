import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

const messages = defineMessages({
  required: { id: 'General.Required', defaultMessage: 'Required' },
});

export default values => {
  const errors = {};

  if (!values.token || !values.token.trim()) {
    errors.token = <FormattedMessage {...messages.required} />;
  }

  return errors;
};
