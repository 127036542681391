import React, { Component } from 'react';
import { defineMessages, intlShape, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import classNames from 'classnames';
import muiWithStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';

import SkholeIcon01 from '../../statelessComponents/Icons/SkholeIcon01';

import s from './SearchField.scss';

import * as searchActions from '../../../actions/search';

const messages = defineMessages({
  searchLabel: {
    id: 'SearchField.searchLabel',
    defaultMessage: 'Search...',
    description:
      'Search displayed in search field label in page header at home page',
  },
});

const styles = () => ({
  inputLabelRoot: {
    color: '#fff !important',
    fontSize: '1rem',
    fontWeight: 300,
  },
  searchFieldRoot: {
    // background: "black"
  },
  searchFieldInput: {
    color: '#fff',
    padding: '3px 0 3px',
  },
  searchFieldUnderline: {
    '&::after': {
      borderBottomColor: '#fff',
    },
    '&:hover:after': {
      borderBottomColor: '#fff !important',
    },
    '&:hover:before': {
      borderBottomColor: '#fff !important',
    },
    '&::before': {
      borderBottomColor: '#fff',
    },
  },
  searchIconButtonRoot: {
    width: 26,
    height: 26,
  },
  inputLabelShrink: '',
  searchIcon: '',
});

@injectIntl
@withStyles(s)
@muiWithStyles(styles)
@connect(
  state => ({
    search: state.search.search,
  }),
  dispatch => bindActionCreators({ ...searchActions }, dispatch),
)
export default class SearchField extends Component {
  static propTypes = {
    changeSearchField: PropTypes.func.isRequired,
    changeSearchSession: PropTypes.func.isRequired,
    classes: PropTypes.shape({
      inputLabelShrink: PropTypes.string,
      inputLabelRoot: PropTypes.object, // eslint-disable-line
      searchFieldRoot: PropTypes.object, // eslint-disable-line
      searchFieldInput: PropTypes.object, // eslint-disable-line
      searchFieldUnderline: PropTypes.object, // eslint-disable-line
      searchIconButtonRoot: PropTypes.object, // eslint-disable-line
      searchIcon: PropTypes.string,
    }).isRequired,
    className: PropTypes.string,
    intl: intlShape.isRequired,
    search: PropTypes.string.isRequired,
  };

  static defaultProps = {
    className: '',
  };

  handleSearchChange = ({ target: { value } }) => {
    const { changeSearchField, changeSearchSession } = this.props;
    changeSearchField(value);
    changeSearchSession('', value);
  };

  render() {
    const {
      search,
      classes,
      className,
      intl: { formatMessage },
    } = this.props;

    return (
      <TextField
        name={formatMessage(messages.searchLabel)}
        label={formatMessage(messages.searchLabel)}
        value={search}
        className={className}
        onChange={this.handleSearchChange}
        InputLabelProps={{
          htmlFor: 'search-field-input',
          classes: {
            root: classes.inputLabelRoot,
            shrink: classes.inputLabelShrink,
          },
        }}
        InputProps={{
          id: 'search-field-input',
          classes: {
            root: classes.searchFieldRoot,
            input: classes.searchFieldInput,
            underline: classes.searchFieldUnderline,
          },
          endAdornment: (
            <IconButton classes={{ root: classes.searchIconButtonRoot }}>
              {/* <div className={s.searchIcon} /> */}
              <SkholeIcon01
                color="#fff"
                className={classNames(s.searchIcon, classes.searchIcon)}
              />
            </IconButton>
          ),
        }}
        inputProps={{
          role: 'search',
          maxLength: 50,
        }}
        fullWidth
      />
    );
  }
}
