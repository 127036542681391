import moment from 'moment';
import _ from 'lodash';

import {
  EDIT_COURSE__GET_COURSE,
  EDIT_COURSE__GET_COURSE_SUCCESS,
  EDIT_COURSE__GET_COURSE_FAIL,
  EDIT_COURSE__GET_OPENED_EDITOR_COURSE,
  EDIT_COURSE__GET_OPENED_EDITOR_COURSE_SUCCESS,
  EDIT_COURSE__GET_OPENED_EDITOR_COURSE_FAIL,
  EDIT_COURSE__SET_OPENED_EDITOR_COURSE,
  EDIT_COURSE_TAKE_COURSE_EDIT_CONTROL,
  EDIT_COURSE_TAKE_COURSE_EDIT_CONTROL_SUCCESS,
  EDIT_COURSE_TAKE_COURSE_EDIT_CONTROL_FAIL,
  // EDIT_COURSE__GET_COURSE_RELATIONS,
  // EDIT_COURSE__GET_COURSE_RELATIONS_SUCCESS,
  // EDIT_COURSE__GET_COURSE_RELATIONS_FAIL,
  EDIT_COURSE__RESET_COURSE,
} from '../constants';

const initialState = {
  loading: false,
  loadingRelations: false,
  loadingOpenedEditor: false,
  course: {
    access: 'private',
    studyTimeEstimationType: 'hide',
    studyTimeEstimationHours: 0,
    studyTimeEstimationMinutes: 30,
    accessGroups: [],
    type: 'normal',
    editorIds: [],
    courseTags: [],
    assignCertificate: true,
  },
  courseRelations: {},
  openedEditor: {},
  error: '',
  takingControl: false,
  takenControl: false,
  takingControlError: '',
  unauthorizedUsersAccess: 'listed',
};

export default function editCourse(state = initialState, action) {
  switch (action.type) {
    case EDIT_COURSE__GET_COURSE:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case EDIT_COURSE__GET_COURSE_SUCCESS: {
      const course = action.result.json;
      // todo remove it after db update
      course.accessGroups =
        course.accessGroups === undefined ? [] : course.accessGroups;

      _.each(course.accessGroups, accessGroup => {
        _.each(accessGroup.items, accessGroupRecord => {
          if (accessGroupRecord.startDate) {
            // eslint-disable-next-line no-param-reassign
            accessGroupRecord.startDate = moment(
              accessGroupRecord.startDate,
            ).format('YYYY-MM-DD');
          }

          if (accessGroupRecord.endDate) {
            // eslint-disable-next-line no-param-reassign
            accessGroupRecord.endDate = moment(
              accessGroupRecord.endDate,
            ).format('YYYY-MM-DD');
          }
        });
      });

      course.studyTimeEstimationMinutes =
        (course.studyTimeEstimation || 0) % 60;
      course.studyTimeEstimationHours =
        ((course.studyTimeEstimation || 0) -
          course.studyTimeEstimationMinutes) /
        60;

      if (course && course.liveStreamStart) {
        course.liveStreamStart = moment(course.liveStreamStart).format(
          'YYYY-MM-DDTHH:mm',
        );
      }
      if (course && course.liveStreamEnd) {
        course.liveStreamEnd = moment(course.liveStreamEnd).format(
          'YYYY-MM-DDTHH:mm',
        );
      }

      return {
        ...state,
        course,
        loading: false,
        loadingOpenedEditor: true,
      };
    }
    case EDIT_COURSE__GET_COURSE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case EDIT_COURSE__GET_OPENED_EDITOR_COURSE:
      return {
        ...state,
        loadingOpenedEditor: true,
        error: '',
      };

    case EDIT_COURSE__GET_OPENED_EDITOR_COURSE_SUCCESS:
      return {
        ...state,
        loadingOpenedEditor: false,
        openedEditor: action.result.status < 400 ? action.result.json : {},
      };

    case EDIT_COURSE__GET_OPENED_EDITOR_COURSE_FAIL:
      return {
        ...state,
        loadingOpenedEditor: false,
        error: action.error,
      };

    case EDIT_COURSE__SET_OPENED_EDITOR_COURSE:
      return {
        ...state,
        openedEditor: action.openedEditor,
        takenControl: false,
        takingControl: false,
        takingControlError: '',
      };

    case EDIT_COURSE_TAKE_COURSE_EDIT_CONTROL:
      return {
        ...state,
        takingControl: true,
        takenControl: false,
        takingControlError: '',
      };

    case EDIT_COURSE_TAKE_COURSE_EDIT_CONTROL_SUCCESS:
      return {
        ...state,
        takingControl: false,
        takenControl: true,
      };

    case EDIT_COURSE_TAKE_COURSE_EDIT_CONTROL_FAIL:
      return {
        ...state,
        takingControl: false,
        takingControlError: action.error,
      };

    // case EDIT_COURSE__GET_COURSE_RELATIONS:
    //   return {
    //     ...state,
    //     error: action.error,
    //     loadingRelations: true,
    //   };
    // case EDIT_COURSE__GET_COURSE_RELATIONS_SUCCESS: {
    //   const courseRelations = action.result.json;
    //
    //   return {
    //     ...state,
    //     courseRelations,
    //     loadingRelations: false,
    //   };
    // }
    // case EDIT_COURSE__GET_COURSE_RELATIONS_FAIL:
    //   return {
    //     ...state,
    //     error: action.error,
    //     loadingRelations: false,
    //   };
    case EDIT_COURSE__RESET_COURSE:
      return initialState;
    default:
      return state;
  }
}
