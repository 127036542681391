import React from 'react';
import { Icon } from './index';

const SkholeIcon08 = Icon(
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 40 40"
    style={{ enableBackground: 'new 0 0 40 40' }}
    xmlSpace="preserve"
  >
    <g>
      <path
        className="st1"
        d="M21.9,25.6c-0.7,0-1.2,0.6-1.2,1.2v4.8c0,0.3-0.2,0.5-0.3,0.5H7c-0.1,0-0.3-0.2-0.3-0.5V7.6
		c0-0.3,0.2-0.5,0.3-0.5h13.3c0.1,0,0.3,0.2,0.3,0.5v4.8c0,0.7,0.6,1.2,1.2,1.2s1.2-0.6,1.2-1.2V7.6c0-1.6-1.2-3-2.8-3H7
		c-1.5,0-2.8,1.3-2.8,3v24.1c0,1.6,1.2,3,2.8,3h13.3c1.5,0,2.8-1.3,2.8-3v-4.8C23.1,26.2,22.6,25.6,21.9,25.6z"
      />
      <path
        className="st1"
        d="M35.9,20.5c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.1-0.1-0.2-0.2-0.3c0,0,0,0,0,0L29,12.5
		c-0.5-0.5-1.3-0.4-1.8,0.1c-0.5,0.5-0.4,1.3,0.1,1.8l4.4,4.1H15.3c-0.7,0-1.2,0.6-1.2,1.2s0.6,1.2,1.2,1.2h16.5l-4.5,4.1
		c-0.5,0.5-0.5,1.3-0.1,1.8c0.2,0.3,0.6,0.4,0.9,0.4c0.3,0,0.6-0.1,0.8-0.3l6.8-6.3C35.8,20.6,35.8,20.6,35.9,20.5
		C35.9,20.5,35.9,20.5,35.9,20.5z"
      />
    </g>
  </svg>,
);

export default SkholeIcon08;
