import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';

import messages from '../buttonsMessages';

import s from './ButtonsInModal.scss';
import TooltipButton from '../../Tooltip/TooltipButton';

const ModalSaveButton = ({
  ariaLabel,
  onClick,
  disabled,
  isLoaderOn,
  mobileLandscapeRelative,
  className,
  isSuccessed,
  mobileMessage,
  tooltip,
  ...rest
}) => (
  <div
    className={classNames(
      className,
      {
        [s.mobileLandscapeRelative]: mobileLandscapeRelative,
        [s.successed]: isSuccessed,
      },
      s.saveBtn,
    )}
  >
    <Tooltip title={tooltip}>
      <Fab
        color="primary"
        aria-label={ariaLabel}
        type="submit"
        onClick={onClick}
        size="small"
        disabled={disabled}
        {...rest}
      >
        <div className={s.displayOnlyMobile}>
          <FormattedMessage {...mobileMessage} />
        </div>
        <DoneIcon />
      </Fab>
    </Tooltip>
    {isLoaderOn && <CircularProgress size={52} className={s.fabProgress} />}
  </div>
);

ModalSaveButton.propTypes = {
  ariaLabel: PropTypes.string,
  mobileLandscapeRelative: PropTypes.bool,
  isSuccessed: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isLoaderOn: PropTypes.bool,
  mobileMessage: PropTypes.shape({
    id: PropTypes.string.isRequired,
    defaultMessage: PropTypes.string.isRequired,
  }),
  tooltip: PropTypes.string.isRequired,
};

ModalSaveButton.defaultProps = {
  mobileLandscapeRelative: false,
  isSuccessed: false,
  className: '',
  ariaLabel: '',
  isLoaderOn: false,
  disabled: false,
  mobileMessage: messages.saveLabel,
};

@withStyles(s)
class ModalCloseButton extends React.PureComponent {
  static propTypes = {
    ariaLabel: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    transparent: PropTypes.bool,
    fitMobileLandscapeFullWidth: PropTypes.bool,
    className: PropTypes.string,
    tooltipMessage: PropTypes.string,
  };

  static defaultProps = {
    ariaLabel: '',
    disabled: false,
    transparent: false,
    fitMobileLandscapeFullWidth: false,
    className: '',
    tooltipMessage: '',
  };

  render() {
    const {
      ariaLabel,
      onClick,
      disabled,
      transparent,
      dispatch, // eslint-disable-line
      className,
      tooltipMessage,
      fitMobileLandscapeFullWidth,
      ...rest
    } = this.props;
    return (
      <TooltipButton message={tooltipMessage || 'closeButton'}>
        <div
          className={classNames(s.closeModalBtn, className, {
            [s.landscapeMobile]: fitMobileLandscapeFullWidth,
          })}
        >
          <Fab
            color="primary"
            className={classNames({ [s.mobileViewCloseButton]: transparent })}
            aria-label={ariaLabel}
            size="small"
            onClick={onClick}
            disabled={disabled}
            {...rest}
          >
            <CloseIcon />
          </Fab>
        </div>
      </TooltipButton>
    );
  }
}

export const ModalCloseBtn = ModalCloseButton;

export const ModalSaveBtn = withStyles(s)(ModalSaveButton);
