// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ggjtB{border-top:1px solid rgba(0,19,58,.14);font-size:13px;margin:30px 30px 10px;padding:30px 30px 0;text-align:center}@media(max-width: 991px){.ggjtB{margin:10px 10px 5px;padding:10px 10px 0}}.ggjtB a,.ggjtB a:hover,.ggjtB a:active,.ggjtB a:visited{color:#4c72bd}.ggjtB img{height:auto;max-width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "ggjtB"
};
module.exports = ___CSS_LOADER_EXPORT___;
