import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import _ from 'lodash';

const messages = defineMessages({
  closeButton: { id: 'General.closeButton', defaultMessage: 'Close' },
  helpButton: { id: 'General.helpButton', defaultMessage: 'Guide' },
  hasError: { id: 'General.hasError', defaultMessage: 'Form has error' },
});
const TooltipButton = ({ children, intl: { formatMessage }, message }) => (
  <Tooltip title={formatMessage(_.get(messages, `${message}`))}>
    {children}
  </Tooltip>
);

TooltipButton.propTypes = {
  children: PropTypes.element.isRequired,
  intl: intlShape.isRequired,
  message: PropTypes.string.isRequired,
};
export default injectIntl(TooltipButton);
