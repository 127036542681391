import {
  GET_COURSE_STATISTICS,
  GET_COURSE_STATISTICS_SUCCESS,
  GET_COURSE_STATISTICS_FAIL,
  RESET_COURSE_STATISTICS,
} from '../constants';

export default function courseStatistics(
  state = { loading: false, courseStatistics: [], total: 0, loaded: false },
  action,
) {
  switch (action.type) {
    case GET_COURSE_STATISTICS:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case GET_COURSE_STATISTICS_SUCCESS:
      return {
        ...state,
        courseStatistics: action.result.json,
        total: action.result.total,
        loading: false,
        loaded: true,
      };
    case GET_COURSE_STATISTICS_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: true,
      };
    case RESET_COURSE_STATISTICS:
      return {
        ...state,
        loaded: false,
        courseStatistics: [],
      };
    default:
      return state;
  }
}
