import {
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  GET_ALL_USERS,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAIL,
  RESET_USERS,
} from '../constants';

const initialState = {
  loading: false,
  loaded: false,
  users: [],
  total: 0,
  allUsers: [],
  loadingAll: false,
};

export default function users(state = initialState, action) {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        loading: true,
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.result.json,
        total: action.result.total,
        loading: false,
        loaded: true,
      };
    case GET_USERS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case GET_ALL_USERS:
      return {
        ...state,
        loadingAll: true,
      };
    case GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        allUsers: action.result.json,
        loadingAll: false,
      };
    case GET_ALL_USERS_FAIL:
      return {
        ...state,
        loadingAll: false,
      };
    case RESET_USERS:
      return initialState;
    default:
      return state;
  }
}
