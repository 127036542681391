import {
  GET_TAG,
  GET_TAG_SUCCESS,
  GET_TAG_FAIL,
  RESET_TAG,
} from '../constants';

export default function editTag(state = { loading: false, tag: {} }, action) {
  switch (action.type) {
    case GET_TAG:
      return {
        ...state,
        loading: true,
      };
    case GET_TAG_SUCCESS: {
      return {
        ...state,
        tag: action.result.json,
        loading: false,
      };
    }
    case GET_TAG_FAIL:
      return {
        ...state,
        loading: false,
      };
    case RESET_TAG:
      return {
        ...state,
        tag: {},
      };
    default:
      return state;
  }
}
