/* @flow */

import _ from 'lodash';
import handleUnauthorized from './helpers/handleUnauthorized';

type Fetch = (url: string, options: ?any) => Promise<any>;

type Options = {
  baseUrl: string,
  cookie?: string,
};

/**
 * Creates a wrapper function around the HTML5 Fetch API that provides
 * default arguments to fetch(...) and is intended to reduce the amount
 * of boilerplate code in the application.
 * https://developer.mozilla.org/docs/Web/API/Fetch_API/Using_Fetch
 */
function createFetch(
  fetch: Fetch,
  {
    baseUrl,
    serverUrl,
    cloudfrontUrl,
    cookie,
    organization,
    apiVersion,
  }: Options,
) {
  // NOTE: Tweak the default options to suite your application needs
  const defaults = {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
    cache: 'no-cache',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...(cookie ? { Cookie: cookie } : null),
    },
  };

  return async (url: string, options: any) => {
    if (url.startsWith('/locales')) {
      return fetch(`${serverUrl}${url}`, {
        ...defaults,
        ...options,
        headers: _.pickBy(
          {
            ...defaults.headers,
            ...(options && options.headers),
          },
          _.identity,
        ),
      });
    }

    if (url.startsWith(cloudfrontUrl)) {
      return fetch(url, {
        mode: 'cors',
        cache: 'no-store',
        ...options,
        headers: _.pickBy(
          {
            ...(options && options.headers),
          },
          _.identity,
        ),
      });
    }

    if (url.startsWith(baseUrl)) {
      return fetch(url, {
        ...defaults,
        ...options,
        headers: _.pickBy(
          {
            ...defaults.headers,
            ...(options && options.headers),
          },
          _.identity,
        ),
      });
    }

    const fetchApiOptions = {
      ...defaults,
      ...options,
      headers: _.pickBy(
        {
          ...defaults.headers,
          ...(options && options.headers),
          ...(organization ? { 'x-organization': organization } : {}),
        },
        _.identity,
      ),
    };

    const fetchApiUrl = `${baseUrl}${url.replace(
      '/api',
      `/api/v${apiVersion}`,
    )}`;

    const createNewRefreshTokenParams = {
      url: `${baseUrl}/api/v${apiVersion}/auth/refresh-token`,
      options: {
        ...defaults,
        method: 'POST',
        headers: _.pickBy(
          {
            ...defaults.headers,
            ...(organization ? { 'x-organization': organization } : {}),
          },
          _.identity,
        ),
      },
    };

    return url.startsWith('/api')
      ? fetch(fetchApiUrl, fetchApiOptions).then(async res => {
          // intercept all responses in browser and if 401 then try to obtain new access token
          if (res.status === 401 && process.env.BROWSER) {
            return handleUnauthorized(
              {
                url: fetchApiUrl,
                options: fetchApiOptions,
              },
              createNewRefreshTokenParams,
            );
          }

          return res;
        })
      : fetch(url, options);
  };
}

export default createFetch;
