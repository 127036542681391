import {
  GET_MY_TASKS,
  GET_MY_TASKS_SUCCESS,
  GET_MY_TASKS_FAIL,
  RESET_MY_TASKS,
} from '../constants';

export default function myTasks(
  state = { loading: false, loaded: false, myTasks: [], total: 0 },
  action,
) {
  switch (action.type) {
    case GET_MY_TASKS:
      return {
        ...state,
        loading: true,
      };
    case GET_MY_TASKS_SUCCESS:
      return {
        ...state,
        myTasks: action.result.json,
        total: action.result.total,
        loading: false,
        loaded: true,
      };
    case GET_MY_TASKS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case RESET_MY_TASKS:
      return {
        ...state,
        loaded: false,
        myTasks: [],
      };
    default:
      return state;
  }
}
