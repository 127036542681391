import _ from 'lodash';
import {
  GET_COURSES_REQUEST,
  GET_COURSES_SUCCESS,
  GET_COURSES_FAIL,
  RESET_COURSES,
} from '../constants';

export default function courses(
  state = { loading: false, loaded: false, courses: [] },
  action,
) {
  switch (action.type) {
    case GET_COURSES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_COURSES_SUCCESS:
      return {
        ...state,
        courses: action.doNotAppend
          ? action.result.json
          : _.uniqBy(state.courses.concat(action.result.json), '_id'),
        total: action.result.total,
        loaded: true,
        loading: false,
      };
    case GET_COURSES_FAIL:
      return {
        ...state,
        loaded: true,
        loading: false,
      };
    case RESET_COURSES:
      return {
        ...state,
        courses: [],
        loaded: false,
      };
    default:
      return state;
  }
}
