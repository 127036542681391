// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hOhXx {\n  /* background: url('../../../public/icons/skhole_icon-42.svg') no-repeat; */\n  color: transparent;\n  height: 16px;\n  top: 0;\n  width: 25px;\n}\n\n.KvDM8 {\n  font-size: 14px;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectIcon": "hOhXx",
	"tooltip": "KvDM8"
};
module.exports = ___CSS_LOADER_EXPORT___;
