import React from 'react';
import { Icon } from './index';

const SkholeIcon55 = Icon(
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 40 40"
    style={{ enableBackground: 'new 0 0 40 40' }}
    xmlSpace="preserve"
  >
    <path
      className="st1"
      d="M29.9,20.2c0.4,0,0.9,0.1,1.3,0.3c0.4,0.2,0.8,0.1,1.2-0.1c0.3-0.2,0.6-0.6,0.6-1v-6.1c0-1.4-1.2-2.6-2.6-2.6
      h-4.4C26,10.3,26,10,26,9.7c0-3.3-2.7-6-6-6s-6,2.7-6,6c0,0.3,0,0.6,0.1,0.9H9.7c-1.4,0-2.6,1.2-2.6,2.6v5.9c0,0.4,0.2,0.8,0.6,1
      c0.3,0.2,0.8,0.3,1.2,0.1c0.4-0.2,0.9-0.3,1.3-0.3c1.9,0,3.5,1.6,3.5,3.5c0,1.9-1.6,3.5-3.5,3.5c-0.5,0-0.9-0.1-1.3-0.3
      c-0.4-0.2-0.8-0.1-1.2,0.1c-0.3,0.2-0.6,0.6-0.6,1v5.9c0,1.4,1.2,2.6,2.6,2.6h20.6c1.4,0,2.6-1.2,2.6-2.6v-6.1c0-0.4-0.2-0.8-0.6-1
      c-0.3-0.2-0.8-0.3-1.2-0.1c-0.4,0.2-0.8,0.3-1.3,0.3c-1.8,0-3.3-1.5-3.3-3.3S28.1,20.2,29.9,20.2z M29.9,29.3c0.2,0,0.3,0,0.5,0v4.5
      c0,0,0,0.1-0.1,0.1H9.7c0,0-0.1,0-0.1-0.1v-4.3c0.2,0,0.4,0,0.6,0c3.3,0,6-2.7,6-6s-2.7-6-6-6c-0.2,0-0.4,0-0.6,0v-4.3
      c0,0,0-0.1,0.1-0.1h6.1c0.4,0,0.8-0.2,1.1-0.6c0.2-0.4,0.2-0.8,0-1.2c-0.3-0.5-0.4-1-0.4-1.6c0-1.9,1.6-3.5,3.5-3.5
      c1.9,0,3.5,1.6,3.5,3.5c0,0.6-0.1,1.1-0.4,1.6c-0.2,0.4-0.2,0.9,0,1.2c0.2,0.4,0.6,0.6,1.1,0.6h6.1c0,0,0.1,0,0.1,0.1v4.5
      c-0.2,0-0.3,0-0.5,0c-3.2,0-5.8,2.6-5.8,5.8S26.7,29.3,29.9,29.3z"
    />
  </svg>,
);

export default SkholeIcon55;
