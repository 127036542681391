import {
  LESSON_DETAILS__GET_LESSON,
  LESSON_DETAILS__GET_LESSON_SUCCESS,
  LESSON_DETAILS__GET_LESSON_FAIL,
  LESSON_DETAILS__GET_LESSON_TEXT_NOTES,
  LESSON_DETAILS__GET_LESSON_TEXT_NOTES_SUCCESS,
  LESSON_DETAILS__GET_LESSON_TEXT_NOTES_FAIL,
  LESSON_DETAILS__GET_LESSON_VIDEO_NOTES,
  LESSON_DETAILS__GET_LESSON_VIDEO_NOTES_SUCCESS,
  LESSON_DETAILS__GET_LESSON_VIDEO_NOTES_FAIL,
  LESSON_DETAILS__UPDATE_STATE,
  LESSON_DETAILS__RESET_LESSON,
  LESSON_DETAILS__COMPLETE_LESSON,
  LESSON_DETAILS__COMPLETE_LESSON_FAIL,
  LESSON_DETAILS__COMPLETE_LESSON_SUCCESS,
  LESSON_DETAILS__COMPLETE_LESSON_RESET_ERROR,
  LESSON_DETAILS__RESET_TEXT_NOTES,
  LESSON_DETAILS__RESET_VIDEO_NOTES,
  LESSON_DETAILS__GET_LESSON_CHANGELOG,
  LESSON_DETAILS__GET_LESSON_CHANGELOG_SUCCESS,
  LESSON_DETAILS__GET_LESSON_CHANGELOG_FAIL,
} from '../constants';

const initialState = {
  loading: false,
  lesson: {},
  textNotes: [],
  videoNotes: [],
  loadingTextNotes: false,
  loadingVideoNotes: false,
  updatingTextNotes: false,
  isNotesShown: true,
  isPlayerLoaded: false,
  completed: false,
  changelog: [],
  loadingChangelog: false,
  error: null,
};

export default function lessonDetails(state = initialState, action) {
  switch (action.type) {
    case LESSON_DETAILS__GET_LESSON:
      return {
        ...state,
        loading: true,
        completed: false,
      };
    case LESSON_DETAILS__GET_LESSON_SUCCESS:
      return {
        ...state,
        lesson: action.result.json,
        loading: false,
      };
    case LESSON_DETAILS__GET_LESSON_FAIL:
      return {
        ...state,
        loading: false,
      };
    case LESSON_DETAILS__GET_LESSON_TEXT_NOTES:
      return {
        ...state,
        loadingTextNotes: true,
      };
    case LESSON_DETAILS__GET_LESSON_TEXT_NOTES_SUCCESS:
      return {
        ...state,
        textNotes: action.result.json,
        loadingTextNotes: false,
      };
    case LESSON_DETAILS__GET_LESSON_TEXT_NOTES_FAIL:
      return {
        ...state,
        loadingTextNotes: false,
      };
    case LESSON_DETAILS__GET_LESSON_VIDEO_NOTES:
      return {
        ...state,
        loadingVideoNotes: true,
      };
    case LESSON_DETAILS__GET_LESSON_VIDEO_NOTES_SUCCESS:
      return {
        ...state,
        videoNotes: action.result.json,
        loadingVideoNotes: false,
      };
    case LESSON_DETAILS__GET_LESSON_VIDEO_NOTES_FAIL:
      return {
        ...state,
        loadingVideoNotes: false,
      };
    case LESSON_DETAILS__UPDATE_STATE:
      return {
        ...state,
        [action.payload[0]]: action.payload[1],
      };
    case LESSON_DETAILS__GET_LESSON_CHANGELOG:
      return {
        ...state,
        loadingChangelog: true,
      };
    case LESSON_DETAILS__GET_LESSON_CHANGELOG_SUCCESS:
      return {
        ...state,
        changelog: action.result.json,
        loadingChangelog: false,
      };
    case LESSON_DETAILS__GET_LESSON_CHANGELOG_FAIL:
      return {
        ...state,
        loadingChangelog: false,
      };
    case LESSON_DETAILS__COMPLETE_LESSON:
      return {
        ...state,
        loading: true,
        completed: false,
      };
    case LESSON_DETAILS__COMPLETE_LESSON_FAIL:
      return {
        ...state,
        loading: false,
        completed: false,
        error: action.error,
      };
    case LESSON_DETAILS__COMPLETE_LESSON_SUCCESS:
      return {
        ...state,
        loading: false,
        completed: true,
      };
    case LESSON_DETAILS__COMPLETE_LESSON_RESET_ERROR:
      return {
        ...state,
        error: null,
      };
    case LESSON_DETAILS__RESET_TEXT_NOTES:
      return {
        ...state,
        textNotes: [],
      };
    case LESSON_DETAILS__RESET_VIDEO_NOTES:
      return {
        ...state,
        videoNotes: [],
      };
    case LESSON_DETAILS__RESET_LESSON:
      return initialState;
    default:
      return state;
  }
}
