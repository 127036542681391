import { defineMessages } from 'react-intl';

const messages = defineMessages({
  modalTitle: {
    id: 'TwoFaSetupModal.VerificationSelectionModal.modalTitle',
    defaultMessage: 'Choose a verification method',
  },
  googleAuthenticatorListItemText: {
    id:
      'TwoFaSetupModal.VerificationSelectionModal.googleAuthenticatorListItemText',
    defaultMessage: 'Google authenticator',
  },
  emailListItemText: {
    id: 'TwoFaSetupModal.VerificationSelectionModal.emailListItemText',
    defaultMessage: 'Confirmation via email',
  },
});

export default messages;
