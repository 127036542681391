import {
  GET_DEALS,
  GET_DEALS_SUCCESS,
  GET_DEALS_FAIL,
  RESET_DEALS,
} from '../constants';

const initialState = {
  loading: false,
  loaded: false,
  deals: [],
  total: 0,
};

export default function pipedriveDeals(state = initialState, action) {
  switch (action.type) {
    case GET_DEALS:
      return {
        ...state,
        loading: true,
      };
    case GET_DEALS_SUCCESS:
      return {
        ...state,
        deals: action.result.json,
        total: action.result.total,
        loading: false,
        loaded: true,
      };
    case GET_DEALS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case RESET_DEALS:
      return initialState;
    default:
      return state;
  }
}
