import {
  GET_PRODUCTS,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAIL,
  RESET_PRODUCTS,
} from '../constants';

const initialState = {
  loading: false,
  loaded: false,
  products: [],
  total: 0,
};

export default function pipedriveProducts(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCTS:
      return {
        ...state,
        loading: true,
      };
    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.result.json,
        total: action.result.total,
        loading: false,
        loaded: true,
      };
    case GET_PRODUCTS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case RESET_PRODUCTS:
      return initialState;
    default:
      return state;
  }
}
