// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Awfxz{display:flex;flex-direction:column;gap:15px;margin-top:20px}.eoLKg{padding:0}.ciZUM{margin-top:20px}.P4k4P{position:absolute;right:0;top:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Awfxz",
	"resendCodeBtn": "eoLKg",
	"callToActionText": "ciZUM",
	"closeSnackbar": "P4k4P"
};
module.exports = ___CSS_LOADER_EXPORT___;
