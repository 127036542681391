import React from 'react';
import { Icon } from './index';

const SkholeIcon05 = Icon(
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 40 40"
    style={{ enableBackground: 'new 0 0 40 40' }}
    xmlSpace="preserve"
  >
    <path
      className="st1"
      d="M38.5,29.4c0-5.1-1-10-7.1-11.6c1.6-1.4,2.5-3.4,2.5-5.6c0-4.2-3.4-7.6-7.6-7.6c-2.6,0-5,1.4-6.3,3.5
      c-1.4-2.1-3.7-3.5-6.3-3.5c-4.2,0-7.6,3.4-7.6,7.6c0,2.2,1,4.2,2.5,5.6c-6.1,1.6-7.1,6.4-7.1,11.6c0,1.4,0.5,1.5,7.2,1.6
      c-0.1,0.8-0.1,1.7-0.1,2.6c0,1.4,1.2,1.5,4.6,1.6c2,0.1,4.7,0.1,7.5,0.1s5.6,0,7.5-0.1c3.5-0.1,4.6-0.2,4.6-1.6c0-0.9,0-1.7-0.1-2.6
      C37.8,31.1,38.5,30.7,38.5,29.4z M15.6,16.4c0-2.8,2.3-5.1,5.1-5.1s5.1,2.3,5.1,5.1c0,2.8-2.3,5.1-5.1,5.1S15.6,19.2,15.6,16.4z
       M26.3,7.1c2.8,0,5.1,2.3,5.1,5.1c0,2.1-1.3,3.9-3.2,4.7c0-0.2,0-0.3,0-0.5c0-3.6-2.5-6.6-5.9-7.4C23.3,7.9,24.8,7.1,26.3,7.1z
       M13.7,7.1c1.7,0,3.3,0.9,4.2,2.3c-2.8,1.1-4.7,3.8-4.7,7c0,0.3,0,0.5,0,0.8c-2.6-0.2-4.6-2.4-4.6-5C8.6,9.4,10.9,7.1,13.7,7.1z
       M4,28.4c0.1-5,1.3-8.6,9.7-8.6c0.1,0,0.2,0,0.3,0c0.4,0.9,1,1.7,1.8,2.3c-4,1.1-5.8,3.5-6.6,6.5C6.4,28.5,4.9,28.5,4,28.4z
       M11.1,32.6c0-0.9,0.1-1.8,0.2-2.6c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0c0.6-3.5,2.7-5.8,9.4-5.8c8.4,0,9.5,3.7,9.7,8.6
      C26.9,32.9,14.5,32.9,11.1,32.6z M32.3,28.6c-0.8-3-2.6-5.5-6.6-6.5c0.7-0.6,1.3-1.4,1.7-2.3c6.8,0.3,8.4,3.2,8.5,8.7
      C35.2,28.5,33.9,28.6,32.3,28.6z"
    />
  </svg>,
);

export default SkholeIcon05;
