import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import {
  Field,
  reduxForm,
  SubmissionError,
  propTypes as reduxFormPropTypes,
} from 'redux-form';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
  defineMessages,
  FormattedMessage,
  intlShape,
  injectIntl,
} from 'react-intl';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '../statelessComponents/FormFields/TextField';

import modalStyles from '../styles/Modal.scss';
import textFieldStyles from '../styles/TextField.scss';
import s from './ChangePassword.scss';
import validate from './changePasswordValidation';

const messages = defineMessages({
  passwordLabel: {
    id: 'ChangePassword.password',
    defaultMessage: 'New password',
    description: 'New password label displayed on the change password form',
  },
  repeatPasswordLabel: {
    id: 'ChangePassword.repeatPassword',
    defaultMessage: 'Repeat Password',
    description: 'Repeat password label displayed on the change password form',
  },
  submitButtonText: {
    id: 'ChangePassword.submitButtonText',
    defaultMessage: 'Submit',
    description: 'Submit button text on the login form',
  },
  passwordChangedSnackBar: {
    id: 'ChangePassword.passwordChangedSnackBar',
    defaultMessage: 'You password has been changed',
    description:
      'You password has been changed label displayed on the snackbar',
  },
  loginBtnLabel: {
    id: 'ChangePassword.loginBtnLabel',
    defaultMessage: 'Log In',
    description: 'Log In  label displayed on the snackbar button',
  },
  changePasswordTitle: {
    id: 'ChangePassword.changePasswordTitle',
    defaultMessage: 'Change password',
    description:
      'Change password title displayed on the topography modal window',
  },
});

@injectIntl
@withStyles(s)
@withStyles(textFieldStyles)
@withStyles(modalStyles)
@reduxForm({
  form: 'forgotPassword',
  validate,
})
@connect(() => null)
export default class Login extends React.Component {
  static propTypes = {
    query: PropTypes.object, // eslint-disable-line
    intl: intlShape.isRequired,
    onClose: PropTypes.func.isRequired,
    openSignIn: PropTypes.func.isRequired,
    ...reduxFormPropTypes,
  };

  static contextTypes = {
    fetch: PropTypes.func,
  };

  state = {
    isSent: false,
  };

  submit = data => {
    const { query } = this.props;
    const token = _.get(query, 'token');

    return this.context
      .fetch('/api/auth/change-password-by-token', {
        method: 'POST',
        body: JSON.stringify({ ..._.omit(data, ['confirmPassword']), token }),
      })
      .then(async res => {
        if (res.status === 200) {
          this.setState({
            isSent: true,
          });
          return;
        }

        const err = await res.json();
        throw new SubmissionError({ _error: err && err.message });
      });
  };

  render() {
    const {
      handleSubmit,
      submitting,
      error,
      intl: { formatMessage },
      onClose,
      openSignIn,
    } = this.props;
    const { isSent } = this.state;

    return (
      <div className={modalStyles.paper}>
        <Typography variant="h4" component="h1" className={s.lead}>
          <FormattedMessage {...messages.changePasswordTitle} />
        </Typography>

        <form noValidate autoComplete="off">
          <Field
            name="password"
            type="password"
            margin="dense"
            placeholder={formatMessage(messages.passwordLabel)}
            component={TextField}
            InputProps={{
              classes: {
                root: textFieldStyles.textField,
                input: textFieldStyles.textFieldInput,
                focused: textFieldStyles.textFieldFocused,
                disabled: textFieldStyles.textFieldDisabled,
                error: textFieldStyles.textFieldError,
              },
            }}
            fullWidth
          />
          <Field
            name="confirmPassword"
            type="password"
            margin="dense"
            placeholder={formatMessage(messages.repeatPasswordLabel)}
            component={TextField}
            InputProps={{
              classes: {
                root: textFieldStyles.textField,
                input: textFieldStyles.textFieldInput,
                focused: textFieldStyles.textFieldFocused,
                disabled: textFieldStyles.textFieldDisabled,
                error: textFieldStyles.textFieldError,
              },
            }}
            fullWidth
          />
          {error && (
            <Typography gutterBottom color="error">
              {error}
            </Typography>
          )}
          <Snackbar
            open={isSent}
            message={formatMessage(messages.passwordChangedSnackBar)}
            action={
              <Button
                color="secondary"
                size="small"
                onClick={() => {
                  onClose();
                  openSignIn();
                }}
              >
                <FormattedMessage {...messages.loginBtnLabel} />
              </Button>
            }
          />
          <Button
            color="primary"
            type="submit"
            margin="normal"
            size="large"
            variant="contained"
            onClick={handleSubmit(this.submit)}
            disabled={submitting || isSent}
            className={s.button}
            fullWidth
          >
            <FormattedMessage {...messages.submitButtonText} />
          </Button>
        </form>
        <div className={modalStyles.closeModalBtn}>
          <IconButton onClick={onClose}>
            <CloseIcon className={modalStyles.closeIcon} />
          </IconButton>
        </div>
      </div>
    );
  }
}
