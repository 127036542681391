import {
  GET_ATTEMPTS_RESULTS,
  GET_ATTEMPTS_RESULTS_SUCCESS,
  GET_ATTEMPTS_RESULTS_FAIL,
  RESET_ATTEMPTS_RESULTS,
} from '../constants';

const initialState = { loading: false, attemptsResults: [], total: 0 };

export default function attemptsResults(state = initialState, action) {
  switch (action.type) {
    case GET_ATTEMPTS_RESULTS:
      return {
        ...state,
        loading: true,
      };
    case GET_ATTEMPTS_RESULTS_SUCCESS:
      return {
        ...state,
        attemptsResults: action.result.json,
        total: action.result.total,
        loading: false,
      };
    case GET_ATTEMPTS_RESULTS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case RESET_ATTEMPTS_RESULTS:
      return initialState;
    default:
      return state;
  }
}
