export default ({ cloudfrontUrl }) => entity => {
  const entityType = entity.get('type').toLowerCase();
  if (entityType === 'link') {
    const data = entity.getData();
    return {
      element: 'a',
      attributes: {
        href: data.url,
        target: '_blank',
      },
    };
  }

  if (entityType === 'image') {
    const data = entity.getData();

    const src = `${cloudfrontUrl}${(data.src || '').replace(
      '/cloudfront',
      '',
    )}`;

    return {
      element: 'img',
      attributes: {
        width: data.width && `${data.width}%`,
        align: data.alignment,
        style: data.alignment === 'center' && 'margin: 0 auto',
        src,
      },
    };
  }

  return undefined;
};
