import React from 'react';
import PropTypes from 'prop-types';
import ContextType from './ContextType';

/**
 * The top-level React component setting context (global) variables
 * that can be accessed from all the child components.
 *
 * https://facebook.github.io/react/docs/context.html
 *
 * Usage example:
 *
 *   const context = {
 *     history: createBrowserHistory(),
 *     store: createStore(),
 *   };
 *
 *   ReactDOM.render(
 *     <App context={context}>
 *       <Layout>
 *         <LandingPage />
 *       </Layout>
 *     </App>,
 *     container,
 *   );
 */

export default class App extends React.PureComponent {
  static propTypes = {
    context: PropTypes.shape(ContextType).isRequired,
    children: PropTypes.element.isRequired,
  };

  static childContextTypes = ContextType;

  getChildContext() {
    return this.props.context;
  }

  // NOTE: This methods are not needed if you update URL by setLocale action.
  //
  //  componentDidMount() {
  //    const store = this.props.context && this.props.context.store;
  //    if (store) {
  //      this.lastLocale = store.getState().intl.locale;
  //      this.unsubscribe = store.subscribe(() => {
  //        const state = store.getState();
  //        const { newLocale, locale } = state.intl;
  //        if (!newLocale && this.lastLocale !== locale) {
  //          this.lastLocale = locale;
  //          this.forceUpdate();
  //        }
  //      });
  //    }
  //  }
  //
  //  componentWillUnmount() {
  //    if (this.unsubscribe) {
  //      this.unsubscribe();
  //      this.unsubscribe = null;
  //    }
  //  }

  render() {
    // NOTE: If you need to add or modify header, footer etc. of the app,
    // please do that inside the Layout component.
    return <div>{this.props.children}</div>;
  }
}
