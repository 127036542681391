import {
  GET_AUTHORS,
  GET_AUTHORS_SUCCESS,
  GET_AUTHORS_FAIL,
  GET_ALL_AUTHORS,
  GET_ALL_AUTHORS_SUCCESS,
  GET_ALL_AUTHORS_FAIL,
  RESET_AUTHORS,
} from '../constants';

const initialState = {
  loading: false,
  loaded: false,
  authors: [],
  total: 0,
  allAuthors: [],
  loadingAll: false,
};

export default function authors(state = initialState, action) {
  switch (action.type) {
    case GET_AUTHORS:
      return {
        ...state,
        loading: true,
      };
    case GET_AUTHORS_SUCCESS:
      return {
        ...state,
        authors: action.result.json,
        total: action.result.total,
        loading: false,
        loaded: true,
      };
    case GET_AUTHORS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case GET_ALL_AUTHORS:
      return {
        ...state,
        loadingAll: true,
      };
    case GET_ALL_AUTHORS_SUCCESS:
      return {
        ...state,
        allAuthors: action.result.json,
        loadingAll: false,
      };
    case GET_ALL_AUTHORS_FAIL:
      return {
        ...state,
        loadingAll: false,
      };
    case RESET_AUTHORS:
      return initialState;
    default:
      return state;
  }
}
