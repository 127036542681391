import React from 'react';
import { Icon } from './index';

const SkholeIcon42 = Icon(
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 40 40"
    style={{ enableBackground: 'new 0 0 40 40' }}
    xmlSpace="preserve"
  >
    <path
      className="st1"
      d="M20,28.9c-0.5,0-1-0.2-1.3-0.6L5.6,14.1c-0.7-0.7-0.6-1.9,0.1-2.5c0.7-0.7,1.9-0.6,2.5,0.1L20,24.5l11.8-12.8
	c0.7-0.7,1.8-0.8,2.5-0.1c0.7,0.7,0.8,1.8,0.1,2.5L21.3,28.3C21,28.7,20.5,28.9,20,28.9z"
    />
  </svg>,
);

export default SkholeIcon42;
