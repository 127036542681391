import { defineMessages } from 'react-intl';

const messages = defineMessages({
  titleMultipleOrganization: {
    id: 'multiOrganizationsModal.titleMultipleOrganization',
    defaultMessage:
      'Hello {firstName}! Please select an organization to log in.',
  },
  organizationWorkspacesTitle: {
    id: 'multiOrganizationsModal.organizationWorkspacesTitle',
    defaultMessage: 'Organization workspaces',
  },
  signupMultipleOrganizationConfirmationMsg: {
    id: 'multiOrganizationsModal.signupMultipleOrganizationConfirmationMsg',
    defaultMessage:
      'To sign in to this organization you should confirm it via email, please check your mailbox.',
  },
  signupMultipleOrganizationConfirmationTip: {
    id: 'multiOrganizationsModal.signupMultipleOrganizationConfirmationTip',
    defaultMessage:
      "If you can't find the email, contact your teacher/supervisor and they will help you resolve your issue.",
  },
  signupMultMainGroupInvitationErr: {
    id: 'multiOrganizationsModal.signupMultMainGroupInvitationErr',
    defaultMessage:
      'You have been invited to this organization, to sign in you should confirm it via email, please check your mailbox!',
  },
  accountIsArchivedMessage: {
    id: 'multiOrganizationsModal.accountIsArchivedMessage',
    defaultMessage:
      'Your have been archived in {mainGroupName}. Please contact groupleader/teacher.',
  },
  trialAccountIsArchivedMessage: {
    id: 'multiOrganizationsModal.trialAccountIsArchivedMessage',
    defaultMessage:
      'Your trial period has ended. It was active from {startTrialDate} to {endTrialDate}. Please sign in using alternative organizations.',
  },
  noAccounts: {
    id: 'multiOrganizationsModal.noAccounts',
    defaultMessage: 'Oops, something went wrong!',
  },
});

export default messages;
