import { defineMessages } from 'react-intl';

const messages = defineMessages({
  verifyCodeLabel: {
    id: 'VerifyTwoFaModal.verifyCodeLabel',
    defaultMessage: 'Verification Code',
  },
  saveBtnTooltip: {
    id: 'VerifyTwoFaModal.saveBtnTooltip',
    defaultMessage: 'Verify',
  },
  successSnackbarText: {
    id: 'VerifyTwoFaModal.successSnackbarText',
    defaultMessage: 'Code has been successfully verified',
  },
  submitFailedDueToExpirationSnackbarText: {
    id: 'VerifyTwoFaModal.submitFailedDueToExpirationSnackbarText',
    defaultMessage:
      'It seems the confirmation process is taking longer than expected. For your security, please re-enter your credentials to continue. If you encounter any issues, please contact our support team.',
  },
  keepYourAccountSecureText: {
    id: 'VerifyTwoFaModal.keepYourAccountSecureText',
    defaultMessage: 'To keep your account secure, we verify your identity.',
  },
  thisActionRequiresTwoFaConfirmation: {
    id: 'VerifyTwoFaModal.thisActionRequiresTwoFaConfirmation',
    defaultMessage: 'This action requires 2 FA confirmation',
  },
  accountLabel: {
    id: 'VerifyTwoFaModal.accountLabel',
    defaultMessage: 'Account: {name}',
  },

  callToActionText: {
    id: 'OtpTwoFaForm.callToActionText',
    defaultMessage:
      'Enter the code generated by your <strong>authenticator app</strong>.',
  },
});

export default messages;
