import { defineMessages } from 'react-intl';

export default defineMessages({
  noNotificationsYet: {
    id: 'Notifications.noNotificationsYet',
    defaultMessage: 'No notifications yet',
    description:
      'Message shown on notifications popover in case there is no notification yet',
  },
  markAllAsRead: {
    id: 'Notifications.markAllAsRead',
    defaultMessage: 'Mark all as read',
    description:
      'Text on button that will mark all notification as read on notifications popover',
  },
  notesSharedWithYou: {
    id: 'Notifications.markAllAsRead',
    defaultMessage: 'Mark all as read',
    description:
      'Text on button that will mark all notification as read on notifications popover',
  },
  showOlder: {
    id: 'Notifications.showOlder',
    defaultMessage: 'Show older',
    description: 'Text on button that will load older notifications to popover',
  },
  from: {
    id: 'Notifications.from',
    defaultMessage: 'from',
  },
  to: {
    id: 'Notifications.to',
    defaultMessage: 'to',
  },
  allUsersCompleted: {
    id: 'Notifications.allUsersCompleted',
    defaultMessage: 'All users completed {taskName}',
    description:
      'Notification text shown in case all users completed some task',
  },
  sharedNotes: {
    id: 'Notifications.sharedNotes',
    defaultMessage: 'shared {courseName} notes with you',
    description:
      'Text going after user first and last name that shared his course notes with the current user',
  },
  followedDiscussion: {
    id: 'Notifications.followedDiscussion',
    defaultMessage: 'replied to a post you follow',
    description:
      'Text going after user first and last name that posted new message to followed discussion',
  },
  youHaveBeenAssignedToExamTask: {
    id: 'Notifications.youHaveBeenAssignedToExamTask',
    defaultMessage: 'You have been assigned an exam:<br /><b>{name}</b>',
  },
  youHaveBeenAssignedToCourse: {
    id: 'Notifications.youHaveBeenAssignedToCourse',
    defaultMessage:
      'You have been assigned a task<br />Complete course: <b>{name}</b>',
  },
  youHaveBeenAssignedToOpenAssignment: {
    id: 'Notifications.youHaveBeenAssignedToOpenAssignment',
    defaultMessage:
      'You have been assigned an open assignment task:<br /><b>{name}</b>',
  },
  assignmentIsSentByStudent: {
    id: 'Notifications.assignmentIsSentByStudent',
    defaultMessage:
      '{firstName} {lastName} sent answer for <b>"{taskName}"</b>',
  },
  assignmentIsCompletedByStudent: {
    id: 'Notifications.assignmentIsCompletedByStudent',
    defaultMessage:
      'Assignment <b>"{taskName}"</b> has been approved by {firstName} {lastName}.<br>You score is {score}/{maxPoints} points.',
  },
  changesAreRequested: {
    id: 'Notifications.changesAreRequested',
    defaultMessage: '{firstName} {lastName} requested changes of "{taskName}"',
  },
  youGotMessage: {
    id: 'Notifications.youGotMessage',
    defaultMessage:
      'You have a new message from <b>{firstName}</b> <b>{lastName}</b>',
  },
  youGotSystemMessage: {
    id: 'Notifications.youGotSystemMessage',
    defaultMessage: 'You have a new message from <b>{organizationName}</b>',
  },
  taskProlonged: {
    id: 'Notifications.taskProlonged',
    defaultMessage: 'Task has been prolonged.',
  },
  completeTime: {
    id: 'Notifications.completeTime',
    defaultMessage: 'Completion time: <b>{from}</b> - <b>{to}</b>',
  },
  hereIsXlsxYouExported: {
    id: 'Notifications.hereIsXlsxYouExported',
    defaultMessage:
      'Excel export of {type} now available.<br />Download here ({size})',
  },
  quizStatistics: {
    id: 'Notifications.quizStatistics',
    defaultMessage: 'quiz statistics',
  },
  courseStatistics: {
    id: 'Notifications.courseStatistics',
    defaultMessage: 'course statistics',
  },
  quizIsArchived: {
    id: 'Notifications.quizIsArchived',
    defaultMessage:
      '<b>{quizName}</b> in course <b>{courseName}</b> you have created has been archived by <b>{firstName} {lastName}</b>.',
  },
  quizIsArchivedSecondary: {
    id: 'Notifications.quizIsArchivedSecondary',
    defaultMessage:
      '<b>{quizName}</b> is still available in course, but you should review its content.',
  },
  lessonMediaIsReadyTitle: {
    id: 'Notifications.lessonMediaIsReadyTitle',
    defaultMessage: '<b>{lessonName}</b> media files are ready',
  },
  assignmentType_text: {
    id: 'Notifications.assignmentTypeText',
    defaultMessage: 'Text',
  },
  assignmentType_file: {
    id: 'Notifications.assignmentTypeFile',
    defaultMessage: 'File',
  },
  yourSubscriptionIsExpiring: {
    id: 'Notifications.yourSubscriptionIsExpiring',
    defaultMessage: '{serviceName} - Your subscription is expiring',
  },
  yourSubscriptionIsExpiringBody: {
    id: 'Notifications.yourSubscriptionIsExpiringBody',
    defaultMessage:
      'Your subscription is about to expire ({expiryDateWithNoTime}) because we could not verify your membership id. If you think your membership id should be valid, please contact our customer service cs@skhole.fi to resolve issue',
  },
  serviceName: {
    id: 'General.serviceName',
    defaultMessage: 'Skhole',
  },
  taskReminder: {
    id: 'Notifications.taskReminder',
    defaultMessage:
      'You have unfinished task {taskName} that is due on {endTime}',
  },
  noNewNotificationsYet: {
    id: 'Notifications.noNewNotificationsYet',
    defaultMessage: 'No new notifications',
  },
});
