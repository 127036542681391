import {
  GET_API_KEY,
  GET_API_KEY_SUCCESS,
  GET_API_KEY_FAIL,
  SET_API_KEY,
  RESET_API_KEY,
} from '../constants';

export default function editApiKey(
  state = { loading: false, apiKey: {} },
  action,
) {
  switch (action.type) {
    case GET_API_KEY:
      return {
        ...state,
        loading: true,
      };
    case GET_API_KEY_SUCCESS: {
      const apiKey = action.result.json;

      return {
        ...state,
        apiKey,
        loading: false,
      };
    }
    case GET_API_KEY_FAIL:
      return {
        ...state,
        loading: false,
      };
    case SET_API_KEY:
      return {
        ...state,
        apiKey: action.apiKey,
      };
    case RESET_API_KEY:
      return {
        ...state,
        apiKey: {},
      };
    default:
      return state;
  }
}
