import moment from 'moment';

import {
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  GET_USER_COMPLETED_COURSES,
  GET_USER_COMPLETED_COURSES_SUCCESS,
  GET_USER_COMPLETED_COURSES_FAIL,
  GET_USER_PUBLIC_NOTES,
  GET_USER_PUBLIC_NOTES_SUCCESS,
  GET_USER_PUBLIC_NOTES_FAIL,
  GET_USER_PUBLIC_CERTIFICATES,
  GET_USER_PUBLIC_CERTIFICATES_SUCCESS,
  GET_USER_PUBLIC_CERTIFICATES_FAIL,
  RESET_USER,
} from '../constants';

const initialState = {
  loading: false,
  user: {},
  completedCourses: [],
  loadingCompletedCourses: false,
  publicNotes: [],
  loadingPublicNotes: false,
  publicCertificates: [],
  loadingPublicCertificates: false,
};

export default function editUser(state = initialState, action) {
  switch (action.type) {
    case GET_USER:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_SUCCESS: {
      const user = action.result.json;

      if (user && user.expirationDate) {
        user.expirationDate = moment(user.expirationDate).format('YYYY-MM-DD');
      }

      return {
        ...state,
        user,
        loading: false,
      };
    }
    case GET_USER_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_USER_COMPLETED_COURSES:
      return {
        ...state,
        loadingCompletedCourses: true,
      };
    case GET_USER_COMPLETED_COURSES_SUCCESS:
      return {
        ...state,
        completedCourses: action.result.json,
        loadingCompletedCourses: false,
      };
    case GET_USER_COMPLETED_COURSES_FAIL:
      return {
        ...state,
        loadingCompletedCourses: false,
      };
    case GET_USER_PUBLIC_NOTES:
      return {
        ...state,
        loadingPublicNotes: true,
      };
    case GET_USER_PUBLIC_NOTES_SUCCESS:
      return {
        ...state,
        publicNotes: action.result.json,
        loadingPublicNotes: false,
      };
    case GET_USER_PUBLIC_NOTES_FAIL:
      return {
        ...state,
        loadingPublicNotes: false,
      };
    case GET_USER_PUBLIC_CERTIFICATES:
      return {
        ...state,
        loadingPublicCertificates: true,
      };
    case GET_USER_PUBLIC_CERTIFICATES_SUCCESS:
      return {
        ...state,
        publicCertificates: action.result.json,
        loadingPublicCertificates: false,
      };
    case GET_USER_PUBLIC_CERTIFICATES_FAIL:
      return {
        ...state,
        loadingPublicCertificates: false,
        error: action.error,
      };
    case RESET_USER:
      return initialState;
    default:
      return state;
  }
}
