import React from 'react';
import { Icon } from './index';

const SkholeIcon50 = Icon(
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 40 40"
    style={{ enableBackground: 'new 0 0 40 40' }}
    xmlSpace="preserve"
  >
    <path
      className="st1"
      d="M34.5,4.1H14.9c-0.7,0-1.3,0.6-1.3,1.3v4.1c-2.9,1.1-5,3.9-5,7.2c0,2.3,1,4.3,2.5,5.7C4.9,24,3.9,29,3.9,34.2
      c0,1.5,1.2,1.5,4.7,1.6c2,0.1,4.7,0.1,7.7,0.1s5.6,0,7.7-0.1c3.5-0.1,4.7-0.2,4.7-1.6c0-1.6-0.1-3.2-0.4-4.7h6.2
      c0.7,0,1.3-0.6,1.3-1.3V5.4C35.8,4.7,35.2,4.1,34.5,4.1z M11.2,16.7c0-2.8,2.3-5.1,5.1-5.1s5.1,2.3,5.1,5.1s-2.3,5.1-5.1,5.1
      S11.2,19.5,11.2,16.7z M6.5,33.1c0.2-5.8,2-8.7,9.8-8.7s9.6,2.9,9.8,8.7C22.5,33.4,10.1,33.4,6.5,33.1z M33.2,26.9h-5.9
      c-0.6-1.2-1.5-2.3-2.8-3.1h4.2c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3h-6.3c0.7-0.9,1.1-2,1.3-3.1h4.9c0.7,0,1.3-0.6,1.3-1.3
      s-0.6-1.3-1.3-1.3h-4.9c-0.2-1.1-0.6-2.2-1.2-3.1h6.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3h-9.1c-1-0.5-2.2-0.8-3.5-0.8
      c0,0-0.1,0-0.1,0V6.7h17V26.9z"
    />
  </svg>,
);

export default SkholeIcon50;
