let isRefreshing = false;
const refreshQueue = [];

const handleUnauthorized = async (originalReqOptions, refreshTokenProps) => {
  if (!isRefreshing) {
    isRefreshing = true;
    try {
      // Send refresh token request
      const response = await fetch(
        refreshTokenProps.url,
        refreshTokenProps.options,
      );

      if (response.ok) {
        isRefreshing = false;

        // Process any queued requests
        while (refreshQueue.length > 0) {
          const {
            url: queueUrl,
            options: queueOptions,
            resolve,
            reject,
          } = refreshQueue.shift();
          // Retry the original request with the new access token
          try {
            // eslint-disable-next-line no-await-in-loop
            const queueResponse = await fetch(queueUrl, queueOptions);
            resolve(queueResponse);
          } catch (error) {
            reject(error);
          }
        }
        return fetch(originalReqOptions.url, originalReqOptions.options);
      }
      // Handle refresh token failure (e.g., logout user)
      console.error('Token refresh failed');
      if (process.env.BROWSER) {
        return window?.location?.reload();
      }
    } catch (error) {
      console.error('Token refresh error:', error);
      return error;
    } finally {
      isRefreshing = false;
    }
  }
  // If a refresh request is already in progress, queue the unauthorized request
  return new Promise((resolve, reject) => {
    refreshQueue.push({
      url: originalReqOptions.url,
      options: originalReqOptions.options,
      resolve,
      reject,
    });
  });
};

export default handleUnauthorized;
