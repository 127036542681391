import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
import queryString from 'query-string';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { withCookies, Cookies } from 'react-cookie';
import withStyles from 'isomorphic-style-loader/withStyles';
import muiWithStyles from '@material-ui/core/styles/withStyles';
import NoSsr from '@material-ui/core/NoSsr';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Modal from '@material-ui/core/Modal';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';
import Collapse from '@material-ui/core/Collapse';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import GroupsIcon from '@material-ui/icons/GroupWorkOutlined';
import MainGroupInfoIcon from '@material-ui/icons/InfoOutlined';
import { bindActionCreators } from 'redux';

import UserAvatar from '../UserAvatar/UserAvatar';
import HelpButton from './HelpButton/HelpButton';

import s from './Navigation.scss';
import notificationAudio from '../../../public/open-ended.mp3';

import history from '../../history';

import ChangePassword from '../ChangePassword/ChangePassword';
import JoinMainGroup from '../JoinMainGroup/JoinMainGroup';
import ConfirmChangeEmail from '../ConfirmChangeEmail/ConfirmChangeEmail';
import Register from '../Register/Register';
import Link from '../Link/Link';
import Login from '../Login/Login';
import Notifications from './Notifications/Notifications';
import LicensesReachedSnackbar from './LicensesReachedSnackbar/LicensesReachedSnackbar';
import StudentsLimitReachedSnackbar from './StudentsLimitReachedSnackbar/StudentsLimitReachedSnackbar';
import DealAboutToExpireSnackbar from './DealAboutToExpireSnackbar/DealAboutToExpireSnackbar';
import SystemInfoSnackbar from './SystemInfoSnackbar/SystemInfoSnackbar';
import TrialAboutToExpireSnackbar from './TrialAboutToExpireSnackbar/TrialAboutToExpireSnackbar';
import {
  isAdmin,
  isTeacher,
  isEditor,
  isTrial,
  isFormHasUnsavedChanges,
  isWhitelabeledOrganizationAdmin,
  hasMedicalLicense,
  isWhitelabeledOrganization,
} from '../../helpers';
import isLoggedIn from '../../helpers/checkAuth';

import * as intlActions from '../../actions/intl';
import muiStyles from './muiStyles';
import messages from './messages';
import * as notificationsActions from '../../actions/notifications';
import * as searchActions from '../../actions/search';
import { resetRegistrationForm as resetRegistrationFormAction } from '../../actions/registration';
import warningMessages from '../../helpers/warningMessages';
import SkholeIcon05 from '../statelessComponents/Icons/SkholeIcon05';
import SkholeIcon06 from '../statelessComponents/Icons/SkholeIcon06';
import SkholeIcon07 from '../statelessComponents/Icons/SkholeIcon07';
import SkholeIcon26 from '../statelessComponents/Icons/SkholeIcon26';
import SkholeIcon46 from '../statelessComponents/Icons/SkholeIcon46';
import SkholeIcon49 from '../statelessComponents/Icons/SkholeIcon49';
import SkholeIcon50 from '../statelessComponents/Icons/SkholeIcon50';
import SkholeIcon54 from '../statelessComponents/Icons/SkholeIcon54';
import SkholeIcon55 from '../statelessComponents/Icons/SkholeIcon55';
import SkholeIcon08 from '../statelessComponents/Icons/SkholeIcon08';
import SkholeIcon47 from '../statelessComponents/Icons/SkholeIcon47';
import SkholeIcon01 from '../statelessComponents/Icons/SkholeIcon01';
import SkholeIcon24 from '../statelessComponents/Icons/SkholeIcon24';
import MultipleOrganizationsModal from '../MultipleOrganizationsModal/MultipleOrganizationsModal';
import CustomModal from '../Modals/Modal';
import { logoutUser } from '../../helpers/logout';
import VerifyTwoFaModal from '../VerifyTwoFaModal/VerifyTwoFaModal';
import { TWO_FA_ACTIONS } from '../../constants/twoFa';

const styles = theme => muiStyles(theme);

@injectIntl
@withCookies
@withStyles(s)
@muiWithStyles(styles)
@connect(
  state => ({
    auth: state.auth,
    loading: state.intl.loading,
    locale: state.intl.locale,
    notifications: state.notifications.notifications,
    isNotificationsMenuOpened: state.search.isNotificationsMenuOpened,
    allForms: state.form,
  }),
  dispatch =>
    bindActionCreators(
      {
        setLocale: intlActions.setLocale,
        ...notificationsActions,
        toggleNotificationsPopover: searchActions.toggleNotificationsPopover,
        resetRegistrationForm: resetRegistrationFormAction,
      },
      dispatch,
    ),
)
@connect()
export default class Navigation extends React.Component {
  static propTypes = {
    auth: PropTypes.object, // eslint-disable-line
    cookies: PropTypes.instanceOf(Cookies).isRequired,
    router: PropTypes.object, // eslint-disable-line
    intl: intlShape.isRequired,
    loading: PropTypes.bool.isRequired,
    setLocale: PropTypes.func.isRequired,
    resetRegistrationForm: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
    notifications: PropTypes.array.isRequired, // eslint-disable-line
    getNotifications: PropTypes.func.isRequired,
    toggleNotificationsPopover: PropTypes.func.isRequired,
    membershipIdVerificationEnabled: PropTypes.bool,
    isNotificationsMenuOpened: PropTypes.bool.isRequired,
    toggleMobileSearchView: PropTypes.func.isRequired,
    resetNotifications: PropTypes.func.isRequired,
    appendNotification: PropTypes.func.isRequired,
    classes: PropTypes.shape({
      groupMenuItemText: PropTypes.string,
      groupMenuItem: PropTypes.object, // eslint-disable-line
      popoverContent: PropTypes.object, // eslint-disable-line
      popover: PropTypes.object, // eslint-disable-line
      languagePopover: PropTypes.object, // eslint-disable-line
    }).isRequired,
    allForms: PropTypes.shape({}).isRequired,
    shownDialogs: PropTypes.shape({}).isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    membershipIdVerificationEnabled: false,
  };

  static contextTypes = {
    query: PropTypes.shape({}),
    path: PropTypes.string,
    cookiePrefix: PropTypes.string,
    organizationSlug: PropTypes.string,
    fetch: PropTypes.func,
    ws: PropTypes.func,
    initWs: PropTypes.func,
    whitelabeling: PropTypes.shape({}),
    token: PropTypes.string,
    dispatch: PropTypes.func, // eslint-disable-line
  };

  state = {
    shownDialogs: {
      [_.get(this, 'context.query.showDialog')]: true,
    },
    isGroupMenuOpened: false,
    isProfileMenuOpened: false,
    isLanguagesShowed: false,
    isItemsUnderMoreShown: false,
    isMedicalLicenseOrganization:
      _.get(this.props, 'auth.user.mainGroup') &&
      hasMedicalLicense(this.props.auth.user.mainGroup),
  };

  groupMenuAnchorEl = null;

  languagesAnchorEl = null;

  profileAnchorEl = null;

  notificationsAnchorEl = null;

  componentDidMount() {
    const { getNotifications, auth } = this.props;
    if (isLoggedIn(auth)) {
      getNotifications();
      this.initNotificationsWs();
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { shownDialogs } = this.state;
    const showDialog = _.get(nextContext, 'query.showDialog');

    if (isLoggedIn(_.get(nextProps, 'auth')) && showDialog === 'register') {
      this.logout(true);
    }

    if (_.get(this, 'context.query.showDialog') !== showDialog) {
      this.setState({
        shownDialogs: showDialog ? { showDialog: true } : {},
      });
    } else if (
      !_.get(this.state, 'shownDialogs.chooseOrganization') &&
      !nextContext.token &&
      nextProps.cookies.get(`${this.context.cookiePrefix}chooseMainGroupToken`)
    ) {
      this.setState(
        {
          shownDialogs: { ...(shownDialogs || {}), chooseOrganization: true },
          isMultipleOrganizationsLoading: true,
        },
        this.loadOrganizationsInfo,
      );
    } else if (
      !_.get(this.state, 'shownDialogs.validateTwoFa') &&
      !nextContext.token &&
      nextProps.cookies.get(`${this.context.cookiePrefix}isLoginOtpRequired`) &&
      !isLoggedIn(_.get(nextProps, 'auth'))
    ) {
      this.setState(
        {
          shownDialogs: { ...(shownDialogs || {}), validateTwoFa: true },
          isTwoFaInfoLoading: true,
        },
        this.loadTwoFaInfo,
      );
    }

    if (_.get(this, 'context.path') !== _.get(nextContext, 'path')) {
      this.handleCloseGroupMenu();
      this.handleCloseProfileMenu();
      this.handleCloseNotificationsMenu();
    }

    if (
      (isLoggedIn(this.props.auth) && !isLoggedIn(nextProps.auth)) ||
      (_.get(this, 'props.auth.user._id') &&
        _.get(nextProps, 'auth.user._id') &&
        _.get(this, 'props.auth.user._id') !==
          _.get(nextProps, 'auth.user._id'))
    ) {
      this.props.resetNotifications();
    }

    if (
      (!isLoggedIn(this.props.auth) && isLoggedIn(nextProps.auth)) ||
      (_.get(this, 'props.auth.user._id') &&
        _.get(nextProps, 'auth.user._id') &&
        _.get(this, 'props.auth.user._id') !==
          _.get(nextProps, 'auth.user._id'))
    ) {
      this.props.getNotifications();
    }
  }

  componentWillUnmount() {
    this.props.resetNotifications();
    if (!this.ws) {
      return;
    }

    this.ws.off('notification');
  }

  onLanguageChange = newLanguage => {
    const { setLocale, locale } = this.props;
    const { organizationSlug } = this.context;

    if (locale === newLanguage) {
      return;
    }
    setLocale({ locale: newLanguage, organizationSlug });
    this.showLanguages();
  };

  closeMultipleOrganizationsModal = () => {
    const { cookies, auth } = this.props;
    const { fetch } = this.context;
    if (isLoggedIn(auth)) {
      return this.toggleModal('chooseOrganization', false);
    }

    return fetch(`/api/auth/remove-choose-organizations-token`, {
      method: 'POST',
    }).then(() => {
      cookies.remove(`${this.context.cookiePrefix}chooseMainGroupToken`, {
        path: '/',
        domain: window.App.cookieDomain,
      });
      this.toggleModal('chooseOrganization', false);

      window.location = window.location.href.split('?')[0];
    });
  };

  closeValidateTwoFaModal = async () => {
    try {
      const { cookies, auth } = this.props;
      const { fetch } = this.context;
      if (isLoggedIn(auth)) {
        this.toggleModal('validateTwoFa', false);
        return;
      }

      await fetch(`/api/auth/otp/remove-token`, {
        method: 'DELETE',
      });

      cookies.remove(`${this.context.cookiePrefix}isLoginOtpRequired`, {
        path: '/',
        domain: window.App.cookieDomain,
      });
      this.toggleModal('validateTwoFa', false);

      window.location = window.location.href.split('?')[0];
    } catch (err) {
      console.error(err);
    }
  };

  onTwoFaSuccessfullyValidated = async twoFaConfirmationPayload => {
    const { cookies, locale } = this.props;
    const { fetch } = this.context;
    try {
      const { token, twoFaActionId } = twoFaConfirmationPayload;

      const res = await fetch(`/api/auth/otp/confirm-login`, {
        method: 'POST',
        body: JSON.stringify({
          twoFaConfirmation: { token, twoFaActionId },
        }),
      });

      const resJson = await res.json();

      if (!res.ok) {
        if (resJson.message) {
          throw new Error(resJson.message);
        }
        throw new Error(resJson);
      }

      cookies.remove(`${this.context.cookiePrefix}isLoginOtpRequired`, {
        path: '/',
        domain: window.App.cookieDomain,
      });

      if (resJson.token) {
        cookies.set(`${this.context.cookiePrefix}token`, resJson.token, {
          path: '/',
          maxAge: resJson.maxAge,
          domain: window.App.cookieDomain,
          secure: true,
        });
        window.location = window.location.href.split('?')[0];
        return;
      }

      if (resJson.chooseMainGroupToken) {
        cookies.set(
          `${this.context.cookiePrefix}chooseMainGroupToken`,
          resJson.chooseMainGroupToken,
          {
            path: '/',
            maxAge: resJson.maxAge,
            domain: window.App.cookieDomain,
            secure: true,
          },
        );
        this.toggleModal('validateTwoFa', false);
      }
    } catch (err) {
      console.error(err);
      window.location = `/${locale}`;
    }
  };

  initNotificationsWs = () => {
    const { appendNotification } = this.props;

    this.ws = this.context.initWs();

    this.ws.on('notification', notification => {
      appendNotification(notification);
      if (_.get(notification, 'type') === 'xlsx_export') {
        this.props.toggleNotificationsPopover(true);
      }
      this.playNotificationSound();
    });
  };

  playNotificationSound = () => {
    const notificationSoundEl = document.getElementById('notification_sound');
    if (notificationSoundEl) {
      notificationSoundEl.play();
    }
  };

  hasPendingChanges = () => {
    const hasUnsavedEditCourseForm = isFormHasUnsavedChanges({
      formName: 'editCourseForm',
      allForms: this.props.allForms,
    });

    if (hasUnsavedEditCourseForm) {
      // eslint-disable-next-line no-alert
      const confirm = window.confirm(
        this.props.intl.formatMessage(
          warningMessages.onWindowRedirectWarningMessage,
        ),
      );

      if (!confirm) {
        return true;
      }

      window.dispatchEvent(new Event('remove_beforeunload'));
    }

    return false;
  };

  logout = async saveQuery => {
    if (this.hasPendingChanges()) {
      return;
    }

    const { organizationSlug, query } = this.context;
    const { dispatch } = this.props;

    this.setState({
      isProfileMenuOpened: false,
    });

    await logoutUser({
      cookiePrefix: this.context.cookiePrefix,
      cookiesInstance: this.props.cookies,
      dispatch,
    });

    history.replace(
      `/${this.props.locale}${organizationSlug ? `/${organizationSlug}` : ''}${
        saveQuery ? `?${queryString.stringify(query)}` : ''
      }`,
    );
  };

  toggleModal = (name, value) => {
    const { shownDialogs } = this.state;
    shownDialogs[name] = value;

    this.setState({
      shownDialogs,
    });
  };

  backToAdmin = async () => {
    const { locale } = this.props;
    const { fetch } = this.context;

    if (this.hasPendingChanges()) {
      return;
    }

    try {
      await fetch(`/api/accounts/back-to-admin`, {
        method: 'POST',
      });
    } catch (err) {
      console.error(err);
    }

    window.location = `/${locale}`;
  };

  handleOpenGroupMenu = () => {
    this.setState({
      isGroupMenuOpened: true,
    });
  };

  handleCloseGroupMenu = () => {
    this.setState({
      isGroupMenuOpened: false,
      isItemsUnderMoreShown: false,
    });
  };

  handleOpenProfileMenu = () => {
    this.setState({
      isProfileMenuOpened: true,
    });
  };

  handleCloseProfileMenu = () => {
    this.setState({
      isProfileMenuOpened: false,
    });
  };

  handleOpenNotificationsMenu = () => {
    this.props.toggleNotificationsPopover(true);
  };

  handleCloseNotificationsMenu = () => {
    this.props.toggleNotificationsPopover(false);
  };

  showLanguages = () => {
    this.setState(prevState => ({
      isLanguagesShowed: !prevState.isLanguagesShowed,
    }));
  };

  handleDropdownClick = () => {
    this.setState(state => ({
      isItemsUnderMoreShown: !state.isItemsUnderMoreShown,
    }));
  };

  handleCloseRegisterModal = () => {
    const { resetRegistrationForm } = this.props;
    this.toggleModal('register', false);
    resetRegistrationForm();
  };

  handleLogout = () => this.logout();

  loadOrganizationsInfo = () => {
    const { auth } = this.props;
    const { shownDialogs } = this.props;
    if (isLoggedIn(auth)) {
      this.toggleModal('chooseOrganization', true);
      this.setState({
        shownDialogs: { ...(shownDialogs || {}), chooseOrganization: true },
        isMultipleOrganizationsLoading: true,
      });
    }
    fetch(`/api/auth/multiple-organizations`)
      .then(res =>
        res.json().then(accounts =>
          this.setState({
            multipleAccountOrganizations: accounts,
            isMultipleOrganizationsLoading: false,
          }),
        ),
      )
      .catch(() => {
        this.setState({ isMultipleOrganizationsLoading: false });
      });
  };

  loadTwoFaInfo = async () => {
    const { fetch } = this.context;

    try {
      const res = await fetch(`/api/auth/otp/auth`);
      const twoFaInfo = await res.json();
      this.setState({
        twoFaInfo,
        isTwoFaInfoLoading: false,
      });
    } catch (err) {
      this.setState({ isTwoFaInfoLoading: false });
    }
  };

  render() {
    const {
      auth,
      cookies,
      classes,
      locale,
      intl: { formatMessage },
      notifications,
      isNotificationsMenuOpened,
      toggleMobileSearchView,
    } = this.props;
    const { query, path, whitelabeling } = this.context;
    const {
      shownDialogs,
      isGroupMenuOpened,
      isProfileMenuOpened,
      isLanguagesShowed,
      isItemsUnderMoreShown,
      multipleAccountOrganizations,
      isMultipleOrganizationsLoading,
      isTwoFaInfoLoading,
      twoFaInfo,
    } = this.state;
    const tempAdminToken = cookies.get(
      `${this.context.cookiePrefix}tempAdminToken`,
    );

    // let notificationIcon = <div className={s.notificationsIcon} />;
    let notificationIcon = (
      <SkholeIcon47 color="#fff" className={s.notificationsIcon} />
    );

    let unreadNotifications = _.filter(
      notifications,
      ({ readDate }) => !readDate,
    ).length;
    if (unreadNotifications >= 10) {
      unreadNotifications = ' 9+';
    }
    if (isLoggedIn(auth) && unreadNotifications) {
      notificationIcon = (
        <Badge
          badgeContent={unreadNotifications}
          color="secondary"
          classes={{ badge: s.notificationsBadge }}
        >
          <SkholeIcon47 color="#fff" className={s.notificationsIcon} />
        </Badge>
      );
    }

    let mainGroupTooltip = isAdmin(auth)
      ? formatMessage(messages.adminLabel)
      : _.get(
          auth,
          'user.mainGroup.name',
          formatMessage(messages.groupNameLabel),
        );

    if (
      isTeacher(auth) &&
      _.get(auth, 'user.mainGroup.licencesNumber') &&
      _.get(auth, 'user.mainGroup.usedLicencesNumber')
    ) {
      mainGroupTooltip = `${mainGroupTooltip} ${_.get(
        auth,
        'user.mainGroup.usedLicencesNumber',
      )}/${_.get(auth, 'user.mainGroup.licencesNumber')}`;
    }

    if (isTrial(auth)) {
      let days = moment(_.get(auth, 'user.expirationDate')).diff(
        moment(),
        'days',
      );

      if (days < 1) {
        days = 1;
      }

      mainGroupTooltip = formatMessage(messages.trialUserTooltip, {
        days,
      });
    }

    const itemsUnderMore = [];

    if (isAdmin(auth) || isWhitelabeledOrganizationAdmin(auth)) {
      itemsUnderMore.push(
        <ListItem
          component={Link}
          to="/tags"
          button
          className={classes.groupMenuItem}
          data-test="navigation-tags-button"
        >
          <SkholeIcon49 className={s.tagsIcon} />
          <ListItemText
            primary={formatMessage(messages.tagsLabel)}
            className={classes.groupMenuItemText}
            disableTypography
          />
        </ListItem>,
      );
    }

    if (isEditor(auth)) {
      itemsUnderMore.push(
        <ListItem
          component={Link}
          to="/authors"
          button
          className={classes.groupMenuItem}
        >
          <SkholeIcon50 className={s.authorsIcon} />
          <ListItemText
            primary={formatMessage(messages.authorsLabel)}
            className={classes.groupMenuItemText}
            disableTypography
          />
        </ListItem>,
      );
    }

    if (isTeacher(auth) && !this.state.isMedicalLicenseOrganization) {
      itemsUnderMore.push(
        <ListItem
          component={Link}
          to="/quizzes"
          button
          id="navigation-quizzes-link"
          className={classes.groupMenuItem}
        >
          <SkholeIcon55 className={s.quizzesIcon} />
          <ListItemText
            primary={formatMessage(messages.quizzesLabel)}
            className={classes.groupMenuItemText}
            disableTypography
          />
        </ListItem>,
      );
    }

    return (
      <nav className={s.navFlex}>
        <div className={isTrial(auth) && s.mainGroupFlex}>
          {isLoggedIn(auth) && (
            <Tooltip title={mainGroupTooltip}>
              <Button
                classes={{
                  root: classNames(
                    s.groupMenuBtn,
                    isTrial(auth) &&
                      !isWhitelabeledOrganization(auth?.user?.mainGroup) &&
                      s.trialGroupMenuBtn,
                  ),
                  disabled: s.groupMenuBtn,
                }}
                buttonRef={node => {
                  this.groupMenuAnchorEl = node;
                }}
                disabled={isTrial(auth)}
                onClick={isTrial(auth) ? () => null : this.handleOpenGroupMenu}
                id="navigation-menu-btn"
                data-test={
                  isGroupMenuOpened ? 'navigation-menu-btn-active' : undefined
                }
              >
                {isTrial(auth) && formatMessage(messages.trialUser)}
                {isAdmin(auth) && formatMessage(messages.adminLabel)}
                {!isTrial(auth) &&
                  !isAdmin(auth) &&
                  formatMessage(messages.myGroupLabel)}
              </Button>
            </Tooltip>
          )}
          {isTrial(auth) && !isWhitelabeledOrganization(auth?.user?.mainGroup) && (
            <Button
              href={formatMessage(messages.trialOrderBtnHref)}
              target="_blank"
              className={s.trialOrderButton}
            >
              {formatMessage(messages.trialOrderBtn)}
            </Button>
          )}
        </div>
        {isLoggedIn(auth) && (
          <Popover
            open={isGroupMenuOpened}
            anchorEl={this.groupMenuAnchorEl}
            anchorReference="anchorEl"
            anchorPosition={{ top: 200, left: 400 }}
            onClose={this.handleCloseGroupMenu}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            classes={{
              paper: classNames(classes.popover, s.userPopover),
            }}
          >
            <div className={classes.popoverContent}>
              <List component="nav" classes={{ root: s.listForSmallSizes }}>
                {isTeacher(auth) && !this.state.isMedicalLicenseOrganization && (
                  <ListItem
                    component={Link}
                    to="/users"
                    button
                    id="navigation-users-link"
                    className={classes.groupMenuItem}
                  >
                    <SkholeIcon05 className={s.usersIcon} />
                    <ListItemText
                      primary={formatMessage(messages.usersLabel)}
                      className={classes.groupMenuItemText}
                      disableTypography
                    />
                  </ListItem>
                )}
                {isTeacher(auth) &&
                  !isAdmin(auth) &&
                  !this.state.isMedicalLicenseOrganization && (
                    <ListItem
                      component={Link}
                      to={`/groups/${_.get(auth, 'user.mainGroup._id', '')}`}
                      button
                      id="navigation-groups-link"
                      className={classes.groupMenuItem}
                    >
                      <GroupsIcon className={s.icon} />
                      <ListItemText
                        primary={formatMessage(messages.groupsLabel)}
                        className={classes.groupMenuItemText}
                        disableTypography
                      />
                    </ListItem>
                  )}
                {(isAdmin(auth) || isWhitelabeledOrganizationAdmin(auth)) && (
                  <ListItem
                    component={Link}
                    to="/groups"
                    button
                    id="navigation-groups-link"
                    className={classes.groupMenuItem}
                  >
                    <GroupsIcon className={s.icon} />
                    <ListItemText
                      primary={formatMessage(messages.organizationsLabel)}
                      className={classes.groupMenuItemText}
                      disableTypography
                    />
                  </ListItem>
                )}
                {isLoggedIn(auth) && (
                  <ListItem
                    component={Link}
                    to={isTeacher(auth) ? `/group-tasks` : `/tasks`}
                    button
                    id="navigation-tasks-link"
                    className={classes.groupMenuItem}
                  >
                    <SkholeIcon54 className={s.tasksIcon} />
                    <ListItemText
                      primary={formatMessage(messages.tasksLabel)}
                      className={classes.groupMenuItemText}
                      disableTypography
                    />
                  </ListItem>
                )}
                <ListItem
                  component={Link}
                  to="/statistics/courses"
                  button
                  className={classes.groupMenuItem}
                >
                  <SkholeIcon07 className={s.statisticIcon} />
                  <ListItemText
                    primary={formatMessage(messages.statisticsLabel)}
                    className={classes.groupMenuItemText}
                    disableTypography
                  />
                </ListItem>
                {!this.state.isMedicalLicenseOrganization &&
                  !_.get(whitelabeling, 'hideClassroom') && (
                    <ListItem
                      component={Link}
                      to="/classroom/group-discussions"
                      button
                      className={classes.groupMenuItem}
                    >
                      <SkholeIcon06 className={s.classroomIcon} />
                      <ListItemText
                        primary={formatMessage(messages.classroomLabel)}
                        className={classes.groupMenuItemText}
                        disableTypography
                      />
                    </ListItem>
                  )}
                {isAdmin(auth) && (
                  <ListItem
                    component={Link}
                    to="/admin-tools"
                    button
                    className={classes.groupMenuItem}
                  >
                    <SkholeIcon24 className={s.adminToolsIcon} />
                    <ListItemText
                      primary={formatMessage(messages.adminToolsLabel)}
                      className={classes.groupMenuItemText}
                      disableTypography
                    />
                  </ListItem>
                )}
                {isTeacher(auth) && !isAdmin(auth) && (
                  <ListItem
                    component={Link}
                    to={`/groups/${_.get(auth, 'user.mainGroup._id', '')}/info`}
                    button
                    id="navigation-groups-link"
                    className={classes.groupMenuItem}
                  >
                    <MainGroupInfoIcon className={s.icon} />
                    <ListItemText
                      primary={formatMessage(messages.organizationInfoLabel)}
                      className={classes.groupMenuItemText}
                      disableTypography
                    />
                  </ListItem>
                )}
                {!!itemsUnderMore.length && (
                  <>
                    {!isItemsUnderMoreShown && (
                      <ListItem
                        button
                        onClick={this.handleDropdownClick}
                        className={classes.groupMenuItem}
                        data-test="navigation-show-more-button"
                      >
                        {isItemsUnderMoreShown ? (
                          <ExpandLess
                            color="primary"
                            classes={{ root: s.iconSize }}
                          />
                        ) : (
                          <ExpandMore
                            color="primary"
                            classes={{ root: s.iconSize }}
                          />
                        )}
                        <ListItemText
                          disableTypography
                          inset={false}
                          className={classes.groupMenuItemText}
                        >
                          <div className={s.moreText}>
                            {formatMessage(messages.moreOptions)}
                          </div>
                        </ListItemText>
                      </ListItem>
                    )}
                    {isGroupMenuOpened && (
                      <Collapse
                        in={isItemsUnderMoreShown}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          {itemsUnderMore}
                        </List>
                      </Collapse>
                    )}
                  </>
                )}
              </List>
            </div>
          </Popover>
        )}
        {/* Small screens visible button */}
        {!isLoggedIn(auth) && (
          <ButtonBase
            onClick={toggleMobileSearchView}
            className={s.mobileSearchButton}
          >
            {/* <div className={s.mobileSearchIcon} /> */}
            <SkholeIcon01 color="#fff" className={s.mobileSearchIcon} />
          </ButtonBase>
        )}
        {!isLoggedIn(auth) && (
          <Button
            color="primary"
            onClick={this.showLanguages}
            className={s.languagesBtn}
            buttonRef={node => {
              this.languagesAnchorEl = node;
            }}
            aria-label={formatMessage(messages.languagesBtn)}
          >
            <SkholeIcon46 color="#fff" className={s.languageIconWhite} />
            <span className={s.mediaQueryWrapper}>
              <FormattedMessage {...messages.languagesBtn} />
            </span>
          </Button>
        )}
        {!isLoggedIn(auth) && (
          <div className={s.loginWrapper}>
            <Button
              id="navigation-login-btn"
              color="secondary"
              onClick={() => this.toggleModal('login', true)}
              variant="contained"
              className={classNames(s.linkBtn, s.loginBtn)}
              aria-label={formatMessage(messages.login)}
            >
              <FormattedMessage {...messages.login} />
            </Button>
            <Button
              id="navigation-register-btn"
              color="primary"
              onClick={() => this.toggleModal('register', true)}
              className={classNames(s.linkBtn, s.registerBtn)}
              aria-label={formatMessage(messages.signup)}
            >
              <FormattedMessage {...messages.signup} />
            </Button>
          </div>
        )}
        {isLoggedIn(auth) && (
          <ButtonBase
            onClick={toggleMobileSearchView}
            className={s.mobileSearchButton}
          >
            {/* <div className={s.mobileSearchIcon} /> */}
            <SkholeIcon01 color="#fff" className={s.mobileSearchIcon} />
          </ButtonBase>
        )}
        {isLoggedIn(auth) && (
          <HelpButton className={s.helpIconButton} key={path} />
        )}
        {isLoggedIn(auth) && (
          <Tooltip title={formatMessage(messages.notifications)}>
            <ButtonBase
              className={s.notificationsButton}
              buttonRef={node => {
                this.notificationsAnchorEl = node;
              }}
              onClick={this.handleOpenNotificationsMenu}
              id="navigation-notifications-btn"
            >
              {notificationIcon}
            </ButtonBase>
          </Tooltip>
        )}
        {isLoggedIn(auth) && (
          <Tooltip title={formatMessage(messages.profile)}>
            <UserAvatar
              className={s.avatar}
              component={ButtonBase}
              buttonRef={node => {
                this.profileAnchorEl = node;
              }}
              onClick={this.handleOpenProfileMenu}
              user={auth.user}
              id="navigation-profile-btn"
            />
          </Tooltip>
        )}
        {!isLoggedIn(auth) && (
          <Popover
            open={isLanguagesShowed}
            anchorEl={this.languagesAnchorEl}
            anchorReference="anchorEl"
            anchorPosition={{ top: 200, left: 400 }}
            onClose={this.showLanguages}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            classes={{ paper: classes.languagePopover }}
          >
            <div className={classes.popoverContent}>
              <div className={classNames(s.languageBlock, s.paddingTop)}>
                <Button
                  className={classNames(
                    s.languageBtn,
                    locale !== 'en-US' && s.languageBtnRoot,
                  )}
                  onClick={() => this.onLanguageChange('en-US')}
                >
                  <FormattedMessage {...messages.enLabel} />
                </Button>
                <Button
                  className={classNames(
                    s.languageBtn,
                    locale !== 'fi-FI' && s.languageBtnRoot,
                  )}
                  onClick={() => this.onLanguageChange('fi-FI')}
                >
                  <FormattedMessage {...messages.fiLabel} />
                </Button>
                <Button
                  className={classNames(
                    s.languageBtn,
                    locale !== 'sv-SE' && s.languageBtnRoot,
                  )}
                  onClick={() => this.onLanguageChange('sv-SE')}
                >
                  <FormattedMessage {...messages.seLabel} />
                </Button>
              </div>
            </div>
          </Popover>
        )}
        {isLoggedIn(auth) && (
          <Popover
            open={isProfileMenuOpened}
            anchorEl={this.profileAnchorEl}
            anchorReference="anchorEl"
            onClose={this.handleCloseProfileMenu}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            classes={{
              paper: classNames(classes.popover, s.profilePopover),
            }}
          >
            <div className={classes.popoverContent}>
              <List component="nav">
                <ListItem
                  component={Link}
                  to="/profile"
                  button
                  className={classes.groupMenuItem}
                >
                  <SkholeIcon26 className={s.profileIcon} />
                  <ListItemText
                    primary={formatMessage(messages.profileLabel)}
                    className={classes.groupMenuItemText}
                    disableTypography
                  />
                </ListItem>
                {_.get(auth, 'user.isInMultipleOrganizations') && (
                  <ListItem
                    onClick={this.loadOrganizationsInfo}
                    button
                    className={classes.groupMenuItem}
                  >
                    <GroupsIcon className={s.icon} />
                    <ListItemText
                      primary={formatMessage(
                        messages.organizationWorkspacesBtn,
                      )}
                      className={classes.groupMenuItemText}
                      disableTypography
                    />
                  </ListItem>
                )}
                <ListItem
                  component={Link}
                  to="/statistics/courses"
                  button
                  className={classes.groupMenuItem}
                >
                  <SkholeIcon07 className={s.statisticIcon} />
                  <ListItemText
                    primary={formatMessage(messages.myStatisticsLabel)}
                    className={classes.groupMenuItemText}
                    disableTypography
                  />
                </ListItem>
                <ListItem
                  onClick={this.showLanguages}
                  button
                  className={classes.groupMenuItem}
                >
                  <SkholeIcon46 className={s.languageIcon} />
                  <ListItemText
                    primary={formatMessage(messages.languagesBtn)}
                    className={classes.groupMenuItemText}
                    disableTypography
                  />
                </ListItem>
                {isLanguagesShowed && (
                  <div className={s.languageBlock}>
                    <Button
                      className={classNames(
                        s.languageBtn,
                        locale !== 'en-US' && s.languageBtnRoot,
                      )}
                      onClick={() => this.onLanguageChange('en-US')}
                    >
                      <FormattedMessage {...messages.enLabel} />
                    </Button>
                    <Button
                      className={classNames(
                        s.languageBtn,
                        locale !== 'fi-FI' && s.languageBtnRoot,
                      )}
                      onClick={() => this.onLanguageChange('fi-FI')}
                    >
                      <FormattedMessage {...messages.fiLabel} />
                    </Button>
                    <Button
                      className={classNames(
                        s.languageBtn,
                        locale !== 'sv-SE' && s.languageBtnRoot,
                      )}
                      onClick={() => this.onLanguageChange('sv-SE')}
                    >
                      <FormattedMessage {...messages.seLabel} />
                    </Button>
                  </div>
                )}
                <Divider classes={{ root: s.divider }} />
                <ListItem
                  component="a"
                  href="https://support.skhole.fi"
                  target="_blank"
                  button
                  className={classes.groupMenuItem}
                >
                  <HelpOutlineIcon
                    color="primary"
                    className={s.customerServiceIcon}
                  />
                  <ListItemText
                    primary={formatMessage(messages.customerService)}
                    className={classes.groupMenuItemText}
                    disableTypography
                  />
                </ListItem>
                <ListItem
                  onClick={this.handleLogout}
                  button
                  className={classes.groupMenuItem}
                >
                  <SkholeIcon08 className={s.logOutIcon} />
                  <ListItemText
                    primary={formatMessage(messages.logout)}
                    className={classes.groupMenuItemText}
                    disableTypography
                  />
                </ListItem>
                {tempAdminToken && (
                  <ListItem
                    onClick={this.backToAdmin}
                    button
                    className={classes.groupMenuItem}
                  >
                    <ListItemText
                      primary={formatMessage(messages.backToAdmin)}
                      className={classes.groupMenuItemText}
                      disableTypography
                    />
                  </ListItem>
                )}
              </List>
            </div>
          </Popover>
        )}
        {isLoggedIn(auth) && (
          <Popover
            open={isNotificationsMenuOpened}
            anchorEl={this.notificationsAnchorEl}
            anchorReference="anchorEl"
            onClose={this.handleCloseNotificationsMenu}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            classes={{
              paper: classNames(classes.popover, s.notificationsPopover),
            }}
          >
            <div
              className={classNames(
                classes.popoverContent,
                s.notificationsContent,
              )}
            >
              <Notifications onClose={this.handleCloseNotificationsMenu} />
            </div>
          </Popover>
        )}
        <div>
          <Modal
            aria-labelledby="login-modal"
            aria-describedby="login-modal"
            open={!isLoggedIn(auth) && !!shownDialogs.login}
            onClose={() => this.toggleModal('login', false)}
            disableBackdropClick
          >
            <Login
              onClose={() => this.toggleModal('login', false)}
              openSignUp={() => this.toggleModal('register', true)}
              query={query}
            />
          </Modal>
          <Modal
            aria-labelledby="register-modal"
            aria-describedby="register-modal"
            open={!isLoggedIn(auth) && !!shownDialogs.register}
            onClose={this.handleCloseRegisterModal}
            disableBackdropClick
          >
            <Register
              onClose={this.handleCloseRegisterModal}
              openSignIn={() => this.toggleModal('login', true)}
              query={query}
              fetch={this.context.fetch}
              membershipIdVerificationEnabled={_.get(
                whitelabeling,
                'membershipIdVerificationEnabled',
              )}
              integrationPersonIdVerificationEnabled={_.get(
                whitelabeling,
                'integrationPersonIdVerificationEnabled',
              )}
            />
          </Modal>
          <Modal
            open={!isLoggedIn(auth) && !!shownDialogs.changePassword}
            onClose={() => this.toggleModal('changePassword', false)}
            disableBackdropClick
          >
            <ChangePassword
              onClose={() => this.toggleModal('changePassword', false)}
              openSignIn={() => this.toggleModal('login', true)}
              query={query}
            />
          </Modal>
          <Modal
            open={!!shownDialogs.joinMainGroup}
            onClose={() => this.toggleModal('joinMainGroup', false)}
            disableBackdropClick
          >
            <JoinMainGroup
              onClose={() => this.toggleModal('joinMainGroup', false)}
              query={query}
            />
          </Modal>
          <Modal
            open={!!shownDialogs.confirmChangeEmail}
            onClose={() => this.toggleModal('confirmChangeEmail', false)}
            disableBackdropClick
          >
            <ConfirmChangeEmail
              onClose={() => this.toggleModal('confirmChangeEmail', false)}
              query={query}
            />
          </Modal>

          <CustomModal
            isOpen={!!shownDialogs.chooseOrganization}
            aria-describedby="choose-org-modal"
            isLoaderOn={isMultipleOrganizationsLoading}
            width="40%"
          >
            <MultipleOrganizationsModal
              multipleAccountOrganizations={multipleAccountOrganizations}
              cookies={cookies}
              locale={locale}
              onClose={this.closeMultipleOrganizationsModal}
              auth={auth}
            />
          </CustomModal>
          <CustomModal
            isOpen={!!shownDialogs.validateTwoFa}
            aria-describedby="validate-two-fa"
            isLoaderOn={isTwoFaInfoLoading}
            width="40%"
          >
            <VerifyTwoFaModal
              onSubmit={this.onTwoFaSuccessfullyValidated}
              onClose={this.closeValidateTwoFaModal}
              onSubmitFailedDueToExpiration={this.closeValidateTwoFaModal}
              action={TWO_FA_ACTIONS.LOGIN}
              // isLoading={isTurningOff2Fa}
              isLoading={false}
              verificationAccount={
                twoFaInfo
                  ? `${twoFaInfo.firstName} ${twoFaInfo.lastName} (${twoFaInfo.email})`
                  : ''
              }
            />
          </CustomModal>
        </div>
        <audio // eslint-disable-line jsx-a11y/media-has-caption
          id="notification_sound"
          style={{ display: 'none' }}
        >
          <source src={notificationAudio} type="audio/mpeg" />
        </audio>
        {isTeacher(auth) && !isAdmin(auth) && <LicensesReachedSnackbar />}
        {isTeacher(auth) && !isAdmin(auth) && <StudentsLimitReachedSnackbar />}
        {isTeacher(auth) && !isAdmin(auth) && <DealAboutToExpireSnackbar />}
        {isTrial(auth) && <TrialAboutToExpireSnackbar />}
        <NoSsr>
          <SystemInfoSnackbar path={this.context.path} />
        </NoSsr>
      </nav>
    );
  }
}
