import {
  GET_QUIZZES,
  GET_QUIZZES_SUCCESS,
  GET_QUIZZES_FAIL,
  RESET_QUIZZES,
} from '../constants';

const initialState = { loading: false, loaded: false, quizzes: [], total: 0 };

export default function quizzes(state = initialState, action) {
  switch (action.type) {
    case GET_QUIZZES:
      return {
        ...state,
        loading: true,
      };
    case GET_QUIZZES_SUCCESS:
      return {
        ...state,
        quizzes: action.result.json,
        total: action.result.total,
        loading: false,
        loaded: true,
      };
    case GET_QUIZZES_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case RESET_QUIZZES:
      return initialState;
    default:
      return state;
  }
}
