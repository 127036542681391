import React from 'react';
import { Icon } from './index';

const SkholeIcon26 = Icon(
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 40 40"
    style={{ enableBackground: 'new 0 0 40 40' }}
    xmlSpace="preserve"
  >
    <path
      className="st1"
      d="M25.7,20.1c2.3-1.7,3.7-4.4,3.7-7.5c0-5.2-4.2-9.5-9.5-9.5s-9.5,4.2-9.5,9.5c0,3.1,1.5,5.8,3.7,7.5
      C5.4,22.1,4.6,28.7,4.6,35c0,1.5,1.3,1.5,5.6,1.7c2.6,0.1,6.1,0.1,9.8,0.1c15.4,0,15.4-0.4,15.4-1.8C35.4,28.7,34.6,22.1,25.7,20.1z
       M13,12.6c0-3.8,3.1-7,7-7s7,3.1,7,7c0,3.8-3.1,7-7,7S13,16.5,13,12.6z M7.1,34C7.2,27.4,8.6,22.1,20,22.1S32.8,27.4,32.9,34
      C28.8,34.5,11.2,34.5,7.1,34z"
    />
  </svg>,
);

export default SkholeIcon26;
