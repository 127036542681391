import React from 'react';
import { Icon } from './index';

const SkholeIcon54 = Icon(
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 40 40"
    style={{ enableBackground: 'new 0 0 40 40' }}
    xmlSpace="preserve"
  >
    <g>
      <path
        className="st1"
        d="M31.8,3.8H8.2c-1.6,0-2.8,1.3-2.8,2.8v26.7c0,1.6,1.3,2.8,2.8,2.8h23.5c1.6,0,2.8-1.3,2.8-2.8V6.7
          C34.6,5.1,33.3,3.8,31.8,3.8z M32.1,33.3c0,0.2-0.2,0.3-0.3,0.3H8.2c-0.2,0-0.3-0.2-0.3-0.3V6.7c0-0.2,0.2-0.3,0.3-0.3h23.5
          c0.2,0,0.3,0.2,0.3,0.3V33.3z"
      />
      <path
        className="st1"
        d="M27.9,10.7h-6.1c-0.7,0-1.2,0.6-1.2,1.2s0.6,1.2,1.2,1.2h6.1c0.7,0,1.2-0.6,1.2-1.2S28.6,10.7,27.9,10.7z"
      />
      <path
        className="st1"
        d="M17.3,8.9c-0.6-0.4-1.4-0.2-1.7,0.4L13.8,12L13,11.1c-0.5-0.5-1.2-0.6-1.8-0.1c-0.5,0.5-0.6,1.2-0.1,1.8
          l1.9,2.1c0.2,0.3,0.6,0.4,0.9,0.4c0,0,0.1,0,0.1,0c0.4,0,0.7-0.2,1-0.6l2.7-4.2C18.1,10,17.9,9.3,17.3,8.9z"
      />
      <path
        className="st1"
        d="M27.9,18.6h-6.1c-0.7,0-1.2,0.6-1.2,1.2s0.6,1.2,1.2,1.2h6.1c0.7,0,1.2-0.6,1.2-1.2S28.6,18.6,27.9,18.6z"
      />
      <path
        className="st1"
        d="M17.3,16.9c-0.6-0.4-1.4-0.2-1.7,0.4L13.8,20L13,19.1c-0.5-0.5-1.2-0.6-1.8-0.1c-0.5,0.5-0.6,1.2-0.1,1.8
          l1.9,2.1c0.2,0.3,0.6,0.4,0.9,0.4c0,0,0.1,0,0.1,0c0.4,0,0.7-0.2,1-0.6l2.7-4.2C18.1,18,17.9,17.2,17.3,16.9z"
      />
      <path
        className="st1"
        d="M27.9,26.6h-6.1c-0.7,0-1.2,0.6-1.2,1.2s0.6,1.2,1.2,1.2h6.1c0.7,0,1.2-0.6,1.2-1.2S28.6,26.6,27.9,26.6z"
      />
      <path
        className="st1"
        d="M17.3,24.9c-0.6-0.4-1.4-0.2-1.7,0.4L13.8,28L13,27.1c-0.5-0.5-1.2-0.6-1.8-0.1c-0.5,0.5-0.6,1.2-0.1,1.8
          l1.9,2.1c0.2,0.3,0.6,0.4,0.9,0.4c0,0,0.1,0,0.1,0c0.4,0,0.7-0.2,1-0.6l2.7-4.2C18.1,26,17.9,25.2,17.3,24.9z"
      />
    </g>
  </svg>,
);

export default SkholeIcon54;
