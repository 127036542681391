// Create a theme instance.
const createMuiTheme = {
  colors: {
    white: '#fff',
    primary: '#DC143C', // #0034a0
  },
  palette: {
    primary: {
      light: '#4369b9',
      main: '#DC143C',
      dark: '#0d2b5c',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff9080',
      main: '#ff9080',
      dark: '#ff9080',
      contrastText: '#fff',
    },
    error: {
      light: '#FF9080',
      main: '#FF9080',
      dark: '#FF9080',
      contrastText: '#fff',
    },
  },
  typography: {
    // Use the system font over Roboto.
    fontFamily: 'jaf-domus,sans-serif',
    // display4 => h1
    // display3 => h2
    // display2 => h3
    // display1 => h4
    // headline => h5
    // title => h6
    // subheading => subtitle1
    h1: {
      color: '#DC143C',
    },
    h2: {
      color: '#DC143C',
    },
    h3: {
      color: '#DC143C',
    },
    h4: {
      color: '#DC143C',
      fontSize: '2.5rem',
      fontWeight: 300,
      lineHeight: '1.15em',
    },
    h5: {
      color: '#DC143C',
      fontWeight: 300,
    },
    h6: {
      color: '#DC143C',
      fontSize: '1.8125rem',
      lineHeight: '1.38em',
      fontWeight: 300,
    },
    subtitle1: {
      color: '#DC143C',
      // fontSize: '1.5rem',
      // lineHeight: '1.2em',
      fontWeight: 300,
      lineHeight: '1.3rem',
    },
    body2: {
      color: '#DC143C',
      fontSize: '1.06rem',
      lineHeight: '1.36em',
    },
    body1: {
      color: '#DC143C',
      fontSize: '0.94rem',
    },
    button: {
      textTransform: 'none',
      fontWeight: 600,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 992,
      lg: 1440,
    },
  },
  overrides: {
    MuiInputBase: {
      root: {
        fontSize: '1rem',
        fontWeight: 300,
      },
      input: {
        height: 'auto',
      },
      inputMultiline: {
        height: '100%',
      },
    },
    MuiFormLabel: {
      root: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '1rem',
        fontWeight: 300,
      },
    },
    MuiSnackbarContent: {
      root: {
        fontSize: '0.94rem',
      },
      action: {
        marginRight: 0,
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: '#fff',
        '&.Mui-focused, &:hover': {
          backgroundColor: '#fff',
        },
      },
    },
    // courses/Filter.js
    MuiMenuItem: {
      root: {
        paddingTop: 12,
        paddingBottom: 12,
      },
    },
    MuiTableCell: {
      head: {
        fontSize: '0.75rem',
        color: 'rgba(0, 0, 0, 0.54)',
      },
    },
    MuiTableRow: {
      root: {
        '&$selected, &$selected:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
      },
    },
  },
};
export default createMuiTheme;
