import _each from 'lodash/each';
import _set from 'lodash/set';

const themeColor = {
  primaryColor: [
    'colors.primary',
    'palette.primary.main',
    'typography.h1.color',
    'typography.h2.color',
    'typography.h3.color',
    'typography.h4.color',
    'typography.h5.color',
    'typography.h6.color',
    'typography.subtitle1.color',
    'typography.body2.color',
    'typography.body1.color',
  ],
  primaryDarkColor: ['palette.primary.dark'],
  primaryLightColor: ['palette.primary.light'],
  secondaryColor: [
    'palette.secondary.main',
    'palette.secondary.light',
    'palette.secondary.dark',
  ],
  secondaryLightColor: ['palette.secondary.light'],
};

const materialUiThemeColorFromSlug = (objectToSetColor, colorObject) => {
  _each(themeColor, (keys, color) => {
    _each(keys, key => {
      _set(objectToSetColor, key, colorObject[color]);
    });
  });
};

export default materialUiThemeColorFromSlug;
