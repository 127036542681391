import React, { useContext } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';
import {
  FormattedMessage,
  FormattedHTMLMessage,
  defineMessages,
  injectIntl,
  intlShape,
} from 'react-intl';
import { connect } from 'react-redux';

import ContextProvider from '../../context';
import s from './RestrictedAccessModal.scss';

const messages = defineMessages({
  button: {
    id: 'CourseDetailsHeader.RestrictedAccessModal.button',
    defaultMessage: 'Visit {buyButtonLabel}',
    description:
      'Button text on restricted access modal on course details page',
  },
  text: {
    id: 'CourseDetailsHeader.RestrictedAccessModal.text',
    defaultMessage:
      'Want to view this content?<br />Get {serviceNamePossessive} for your<br /> organization now!',
    description: 'Text on restricted access modal on course details page',
  },
  serviceNamePossessive: {
    id: 'CourseDetailsHeader.defaultServiceNamePossessive',
    defaultMessage: 'Skhole',
  },
});

const RestrictedAccessModal = ({ onClose, lang, intl: { formatMessage } }) => {
  const { whitelabeling } = useContext(ContextProvider);
  const serviceNamePossessive = _.get(
    whitelabeling,
    `serviceNamePossessive.${lang}`,
    formatMessage(messages.serviceNamePossessive),
  );
  const siteUrl = _.get(whitelabeling, 'siteUrl', 'https://www.skhole.fi');
  const buyUrl = _.get(whitelabeling, 'buyUrl', siteUrl);
  const siteLabel = _.get(whitelabeling, 'siteLabel', 'www.skhole.fi');
  const buyLabel = _.get(whitelabeling, 'buyUrl', siteLabel);
  const buyButtonLabel = _.get(
    whitelabeling,
    `buyButtonLabel.${lang}`,
    buyLabel,
  );

  return (
    <div className={s.paper} tabIndex="-1">
      <Typography className={s.text} variant="subtitle1">
        <FormattedHTMLMessage
          {...messages.text}
          values={{ serviceNamePossessive }}
        />
      </Typography>
      <Button
        variant="outlined"
        color="secondary"
        component="a"
        href={buyUrl}
        target="_blank"
        className={s.button}
      >
        {buyButtonLabel !== buyLabel ? (
          buyButtonLabel
        ) : (
          <FormattedMessage {...messages.button} values={{ buyButtonLabel }} />
        )}
      </Button>
      <div className={s.closeModalBtn}>
        <IconButton onClick={onClose}>
          <CloseIcon className={s.closeIcon} />
        </IconButton>
      </div>
    </div>
  );
};

RestrictedAccessModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(
  withStyles(s)(
    connect(state => ({ lang: state.intl.locale }))(RestrictedAccessModal),
  ),
);
