import createBrowserHistory from 'history/createBrowserHistory';

// Navigation manager, e.g. history.push('/home')
// https://github.com/mjackson/history
const historyUpdate =
  process.env.BROWSER &&
  createBrowserHistory({
    getUserConfirmation: (message, callback) => {
      const isAccepted = window.confirm(message); // eslint-disable-line no-alert
      callback(isAccepted);
    },
  });

export default historyUpdate;
