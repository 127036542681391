import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import Avatar from '@material-ui/core/Avatar';

import Link from '../Link';

@connect(state => ({
  lang: state.intl.locale,
}))
export default class UserAvatar extends React.Component {
  static propTypes = {
    user: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      image: PropTypes.shape({
        path: PropTypes.string,
      }),
    }).isRequired,
    lang: PropTypes.string.isRequired,
  };

  static contextTypes = {
    cloudfrontUrl: PropTypes.string,
  };

  render() {
    const { user, lang, ...rest } = this.props;
    return (
      <Avatar
        style={
          _.get(user, 'image.path')
            ? {
                backgroundImage: `url(${this.context.cloudfrontUrl}/${_.get(
                  user,
                  'image.path',
                )})`,
              }
            : {}
        }
        alt={`${user.lastName} ${user.firstName}`}
        component={Link}
        to={`/profiles/${user._id}`}
        {..._.omit(rest, ['dispatch'])}
      >
        {!_.get(user, 'image.path')
          ? `${_.get(user, 'lastName[0]')} ${_.get(
              user,
              'firstName[0]',
            )}`.toUpperCase()
          : ''}
      </Avatar>
    );
  }
}
