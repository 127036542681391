import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

const messages = defineMessages({
  required: { id: 'General.Required', defaultMessage: 'Required' },
  wrongFormat: { id: 'General.WrongFormat', defaultMessage: 'Wrong format' },
});

const validate = values => {
  const errors = {};
  if (!values.email || !values.email.trim()) {
    errors.email = <FormattedMessage {...messages.required} />;
  }
  if (
    values.email &&
    !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test( // eslint-disable-line
      values.email,
    )
  ) {
    errors.email = <FormattedMessage {...messages.wrongFormat} />;
  }

  return errors;
};

export default validate;
