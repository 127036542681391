// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mjzJ1 > div {\n  flex-wrap: nowrap;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "mjzJ1"
};
module.exports = ___CSS_LOADER_EXPORT___;
