import moment from 'moment';

import {
  GET_MAIN_GROUP,
  GET_MAIN_GROUP_SUCCESS,
  GET_MAIN_GROUP_FAIL,
  RESET_MAIN_GROUP,
  SET_DEFAULT_SUB_ORGANIZATION_TYPE,
} from '../constants';

const initialState = {
  loading: false,
  group: { type: 'school', lang: 'fi-FI', isPipedriveSyncDisabled: true },
};

export default function editMainGroup(state = initialState, action) {
  switch (action.type) {
    case GET_MAIN_GROUP:
      return {
        ...state,
        loading: true,
      };
    case GET_MAIN_GROUP_SUCCESS: {
      const group = action.result.json;

      if (group && group.expirationDate) {
        group.expirationDate = moment(group.expirationDate).format(
          'YYYY-MM-DD',
        );
      }

      return {
        ...state,
        group,
        loading: false,
      };
    }
    case GET_MAIN_GROUP_FAIL:
      return {
        ...state,
        loading: false,
      };
    case RESET_MAIN_GROUP:
      return initialState;
    case SET_DEFAULT_SUB_ORGANIZATION_TYPE:
      return {
        ...state,
        group: { ...state.group, type: action.organizationType },
      };
    default:
      return state;
  }
}
