import React from 'react';
import { Icon } from './index';

const SkholeIcon24 = Icon(
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 40 40"
    style={{ enableBackground: 'new 0 0 40 40' }}
    xmlSpace="preserve"
  >
    <path
      className="st1"
      d="M20,13.3c-3.6,0-6.6,3-6.6,6.7s3,6.7,6.6,6.7c3.6,0,6.6-3,6.6-6.7S23.6,13.3,20,13.3z M20,24.4
			c-2.4,0-4.3-2-4.3-4.4s1.9-4.4,4.3-4.4c2.4,0,4.3,2,4.3,4.4S22.4,24.4,20,24.4z M34.7,21.7l-1.8-0.6c0-0.4,0.1-0.8,0.1-1.1
			s0-0.7-0.1-1.1l1.8-0.6c0.3-0.1,0.5-0.3,0.7-0.6c0.1-0.3,0.2-0.6,0.1-0.9l-1.1-3.5c-0.2-0.6-0.8-0.9-1.4-0.7l-1.8,0.6
			c-0.4-0.6-0.8-1.2-1.3-1.8l1.1-1.6c0.2-0.2,0.3-0.6,0.2-0.9c0-0.3-0.2-0.6-0.5-0.8L27.7,6c-0.2-0.2-0.5-0.3-0.8-0.2
			c-0.3,0-0.6,0.2-0.7,0.5L25,7.8c-0.7-0.3-1.4-0.5-2.1-0.7v-2c0-0.6-0.5-1.2-1.1-1.2h-3.6c-0.6,0-1.1,0.5-1.1,1.2v2
			c-0.7,0.2-1.4,0.4-2.1,0.7l-1.1-1.6c-0.2-0.2-0.4-0.4-0.7-0.5c-0.3,0-0.6,0-0.8,0.2L9.3,8.1C9.1,8.3,8.9,8.6,8.9,8.9
			c0,0.3,0,0.6,0.2,0.9l1.1,1.6c-0.5,0.6-0.9,1.2-1.3,1.8l-1.8-0.6c-0.6-0.2-1.2,0.1-1.4,0.7l-1.1,3.5c-0.1,0.3-0.1,0.6,0.1,0.9
			C4.8,18,5,18.2,5.3,18.3l1.8,0.6c0,0.4-0.1,0.8-0.1,1.1s0,0.7,0.1,1.1l-1.8,0.6C5,21.8,4.8,22,4.6,22.3c-0.1,0.3-0.2,0.6-0.1,0.9
			l1.1,3.5c0.2,0.6,0.8,0.9,1.4,0.7l1.8-0.6c0.4,0.6,0.8,1.2,1.3,1.8l-1.1,1.6c-0.2,0.2-0.2,0.6-0.2,0.9c0,0.3,0.2,0.6,0.5,0.8
			l2.9,2.2c0.2,0.2,0.6,0.3,0.8,0.2c0.3,0,0.6-0.2,0.7-0.5l1.1-1.6c0.7,0.3,1.4,0.5,2.1,0.7v2c0,0.6,0.5,1.2,1.1,1.2h3.6
			c0.6,0,1.1-0.5,1.1-1.2v-2c0.7-0.2,1.4-0.4,2.1-0.7l1.1,1.6c0.2,0.2,0.4,0.4,0.7,0.5c0.3,0,0.6,0,0.8-0.2l2.9-2.2
			c0.2-0.2,0.4-0.5,0.5-0.8c0-0.3,0-0.6-0.2-0.9l-1.1-1.6c0.5-0.6,0.9-1.2,1.3-1.8l1.8,0.6c0.6,0.2,1.2-0.1,1.4-0.7l1.1-3.5
			c0.1-0.3,0.1-0.6-0.1-0.9C35.2,22,35,21.8,34.7,21.7z M32.5,24.9l-1.7-0.5c-0.5-0.2-1.1,0.1-1.4,0.6c-0.5,1.1-1.2,2-2,2.8
			c-0.4,0.4-0.4,1-0.1,1.5l1,1.4l-1.1,0.8l-1-1.4c-0.3-0.5-0.9-0.6-1.4-0.3c-1,0.5-2.1,0.9-3.2,1.1c-0.6,0.1-1,0.6-1,1.1v1.8h-1.4
			v-1.8c0-0.6-0.4-1.1-1-1.1c-1.1-0.2-2.2-0.5-3.2-1.1c-0.5-0.3-1.1-0.1-1.4,0.3l-1,1.4l-1.1-0.8l1-1.4c0.3-0.5,0.3-1.1-0.1-1.5
			c-0.8-0.8-1.5-1.8-2-2.8c-0.3-0.5-0.8-0.8-1.4-0.6l-1.7,0.5l-0.4-1.3L8.7,23c0.5-0.2,0.9-0.7,0.8-1.3c-0.1-0.6-0.1-1.2-0.1-1.7
			c0-0.5,0-1.1,0.1-1.7c0.1-0.6-0.2-1.1-0.8-1.3l-1.7-0.6l0.4-1.3l1.7,0.5c0.5,0.2,1.1-0.1,1.4-0.6c0.5-1.1,1.2-2,2-2.8
			c0.4-0.4,0.4-1,0.1-1.5l-1-1.4l1.1-0.8l1,1.4c0.3,0.5,0.9,0.6,1.4,0.3c1-0.5,2.1-0.9,3.2-1.1c0.6-0.1,1-0.6,1-1.1V6.3h1.4v1.8
			c0,0.6,0.4,1.1,1,1.1c1.1,0.2,2.2,0.5,3.2,1.1c0.5,0.3,1.1,0.1,1.4-0.3l1-1.4l1.1,0.8l-1,1.4c-0.3,0.5-0.3,1.1,0.1,1.5
			c0.8,0.8,1.5,1.8,2,2.8c0.3,0.5,0.8,0.8,1.4,0.6l1.7-0.5l0.4,1.3L31.3,17c-0.5,0.2-0.9,0.7-0.8,1.3c0.1,0.6,0.1,1.2,0.1,1.7
			c0,0.5,0,1.1-0.1,1.7c-0.1,0.6,0.2,1.1,0.8,1.3l1.7,0.6L32.5,24.9z"
    />
  </svg>,
);

export default SkholeIcon24;
