import {
  GET_TASK_RESULTS,
  GET_TASK_RESULTS_SUCCESS,
  GET_TASK_RESULTS_FAIL,
  RESET_TASK_RESULTS,
} from '../constants';

const initialState = {
  loading: false,
  loaded: false,
  taskResults: [],
  total: 0,
};

export default function taskResults(state = initialState, action) {
  switch (action.type) {
    case GET_TASK_RESULTS:
      return {
        ...state,
        loading: true,
      };
    case GET_TASK_RESULTS_SUCCESS:
      return {
        ...state,
        taskResults: action.result.json,
        total: action.result.total,
        loading: false,
        loaded: true,
      };
    case GET_TASK_RESULTS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case RESET_TASK_RESULTS:
      return initialState;
    default:
      return state;
  }
}
