import { TOGGLE_TOUR } from '../constants';

const initialState = { isShown: {} };

export default function attemptsResults(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_TOUR:
      return {
        ...state,
        isShown: {
          ...state.isShown,
          [action.name]: action.isShown,
        },
      };
    default:
      return state;
  }
}
