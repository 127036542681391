import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { withCookies, Cookies } from 'react-cookie';

import { connect } from 'react-redux';

import withStyles from 'isomorphic-style-loader/withStyles';

import { Interweave } from 'interweave';
import s from './SystemInfoSnackbar.scss';

@withStyles(s)
@withCookies
@connect(state => ({
  auth: state.auth,
  lang: state.intl.locale,
}))
export default class SystemInfoSnackbar extends React.Component {
  static propTypes = {
    auth: PropTypes.object,  // eslint-disable-line
    cookies: PropTypes.instanceOf(Cookies).isRequired,
    path: PropTypes.string.isRequired,
  };

  state = {
    isShown: false,
  };

  componentDidMount() {
    if (!this.getCookieName()) {
      return;
    }

    /* eslint-disable react/no-did-mount-set-state */
    this.setState({
      isShown: !this.props.cookies.get(this.getCookieName()),
    });
    /* eslint-enable react/no-did-mount-set-state */
  }

  componentDidUpdate(prevProps) {
    if (this.props.path !== prevProps.path && this.getMessage()) {
      if (!this.props.cookies.get(this.getCookieName())) {
        /* eslint-disable react/no-did-update-set-state */
        this.setState({ isShown: true });
        /* eslint-enable react/no-did-update-set-state */
        return;
      }

      /* eslint-disable react/no-did-update-set-state */
      this.setState({ isShown: false });
      /* eslint-enable react/no-did-update-set-state */
      this.props.cookies.set(this.getCookieName(), true, { path: '/' });
    }
  }

  getMessage = () => window.systemInfo;

  getCookieName = () => window.systemInfoCookieName;

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ isShown: false });
    this.props.cookies.set(this.getCookieName(), true, { path: '/' });
  };

  render() {
    const { isShown } = this.state;
    const message = this.getMessage();

    if (!message) {
      return null;
    }

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        autoHideDuration={10000}
        open={isShown}
        onClose={this.handleClose}
        message={<Interweave tagName="div" content={message} />}
        classes={{ root: s.root }}
        action={
          <div className={s.actions}>
            <IconButton color="inherit" onClick={this.handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
        }
      />
    );
  }
}
