import _ from 'lodash';
import {
  HOME_GET_LESSONS,
  HOME_GET_LESSONS_SUCCESS,
  HOME_GET_LESSONS_FAIL,
  HOME_RESET_LESSONS,
} from '../constants';

const initialState = { loading: false, loaded: false, lessons: [], total: 0 };

export default function chooseLesson(state = initialState, action) {
  switch (action.type) {
    case HOME_GET_LESSONS:
      return {
        ...state,
        loading: true,
      };
    case HOME_GET_LESSONS_SUCCESS:
      return {
        ...state,
        lessons: action.doNotAppend
          ? action.result.json
          : _.uniqBy(state.lessons.concat(action.result.json), '_id'),
        total: action.result.total,
        loading: false,
        loaded: true,
      };
    case HOME_GET_LESSONS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case HOME_RESET_LESSONS:
      return initialState;
    default:
      return state;
  }
}
