import { defineMessages } from 'react-intl';

export default defineMessages({
  editorButton: {
    id: 'ImageAdd.editorButton',
    defaultMessage: 'Upload image',
  },
  dialogTitle: {
    id: 'ImageAdd.dialogTitle',
    defaultMessage: 'Upload image',
  },
  allowedFormats: {
    id: 'ImageAdd.allowedFormats',
    defaultMessage: 'Allowed formats: <b>{formats}</b>',
  },
  maximumFileSize: {
    id: 'ImageAdd.maximumFileSize',
    defaultMessage: 'Maximum file size: <b>{size}</b>',
  },
  closeDialogButton: {
    id: 'ImageAdd.closeDialogButton',
    defaultMessage: 'Oops, no thanks',
  },
  uploadButton: {
    id: 'ImageAdd.uploadButton',
    defaultMessage: 'Upload',
  },
  maximumFileSizeError: {
    id: 'ImageAd.maximumFileSizeError',
    defaultMessage: 'File size should not be more than <b>{size}</b>',
  },
  allowedTypesError: {
    id: 'ImageAdd.allowedTypesError',
    defaultMessage: 'Only <b>{types}</b> are allowed',
  },
  uploadedMessage: {
    id: 'ImageAdd.uploadedMessage',
    defaultMessage: 'Image is uploaded',
  },
});
