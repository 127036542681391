import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { connect } from 'react-redux';
import OnlineProvider from '../OnlineProvider/OnlineProvider';
import s from './GeneralLayout.css';

@withStyles(s)
@connect(state => ({
  loading: state.intl.loading,
}))
export default class GeneralLayout extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    loading: PropTypes.bool.isRequired,
  };

  static contextTypes = {
    path: PropTypes.string,
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.loading !== nextProps.loading && document) {
      if (nextProps.loading) {
        document.querySelector('body').classList.add('disabledBody');
      } else {
        document.querySelector('body').classList.remove('disabledBody');
      }
    }
  }

  render() {
    const { loading, children } = this.props;
    return (
      <div className={s.root}>
        <OnlineProvider path={this.context.path}>
          <div className={loading ? s.overlay : ''} />
          {children}
        </OnlineProvider>
      </div>
    );
  }
}
