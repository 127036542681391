import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

const messages = defineMessages({
  required: { id: 'General.Required', defaultMessage: 'Required' },
  codeFormat: {
    id: 'General.CodeMustConsistOf6Chars',
    defaultMessage: 'Code must match format XXXXXX (6 chars)',
  },
});

export default values => {
  const errors = {};

  if (!values.code || !values.code.trim()) {
    errors.code = <FormattedMessage {...messages.required} />;
  }
  if (!values.invitationToken && values.code && values.code.length !== 6) {
    errors.code = <FormattedMessage {...messages.codeFormat} />;
  }

  return errors;
};
