import publicIp from './public-ip';

const isOnline = async options => {
  const opts = {
    timeout: 5000,
    version: 'v4',
    ...options,
  };

  try {
    await publicIp(opts);
    return true;
  } catch (err) {
    return false;
  }
};

export default isOnline;
