import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

@connect(store => ({
  auth: store.auth,
}))
export default class LoggerProvider extends React.Component {
  static propTypes = {
    logger: PropTypes.shape({
      error: PropTypes.func,
    }).isRequired,
    children: PropTypes.node.isRequired,
    auth: PropTypes.shape({}).isRequired,
  };

  componentDidCatch(error, info) {
    const user = _.pick(_.get(this.props.auth, 'user'), ['_id', 'email']);
    const mainGroup = _.get(user, 'mainGroup.name');
    if (mainGroup) {
      user.mainGroup = mainGroup;
    }

    this.props.logger.error(error.message, {
      meta: {
        componentStack: info,
        user,
      },
    });
  }

  render() {
    return this.props.children;
  }
}
