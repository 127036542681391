import {
  GET_LESSONS,
  GET_LESSONS_SUCCESS,
  GET_LESSONS_FAIL,
  RESET_LESSONS,
} from '../constants';

const initialState = { loading: false, loaded: false, lessons: [], total: 0 };

export default function chooseLesson(state = initialState, action) {
  switch (action.type) {
    case GET_LESSONS:
      return {
        ...state,
        loading: true,
      };
    case GET_LESSONS_SUCCESS:
      return {
        ...state,
        lessons: action.result.json,
        total: action.result.total,
        loading: false,
        loaded: true,
      };
    case GET_LESSONS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case RESET_LESSONS:
      return initialState;
    default:
      return state;
  }
}
