import {
  GET_USERS_ACTIVITY_STATISTICS,
  GET_USERS_ACTIVITY_STATISTICS_SUCCESS,
  GET_USERS_ACTIVITY_STATISTICS_FAIL,
  RESET_GET_USERS_ACTIVITY_STATISTICS,
} from '../constants';

const initialState = {
  loading: false,
  loaded: false,
  items: [],
  total: 0,
};

export default function usersActivityStatistics(state = initialState, action) {
  switch (action.type) {
    case GET_USERS_ACTIVITY_STATISTICS:
      return {
        ...state,
        loading: true,
      };
    case GET_USERS_ACTIVITY_STATISTICS_SUCCESS:
      return {
        ...state,
        items: action.result.json,
        total: action.result.total,
        loading: false,
        loaded: true,
      };
    case GET_USERS_ACTIVITY_STATISTICS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case RESET_GET_USERS_ACTIVITY_STATISTICS:
      return initialState;
    default:
      return state;
  }
}
