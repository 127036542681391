import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

const messages = defineMessages({
  codeIsInvalid: {
    id: 'AddToDifferentMainGroupModal.ThisCodeIsInvalid',
    defaultMessage: 'Sign up code is not found, consult your group leader',
  },
});

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export default (values, dispatch, { fetch }) =>
  sleep(1000).then(() => {
    if (!values || !values.code) return false;

    return fetch(`/api/auth/is-code-valid?code=${values.code}`, {
      method: 'GET',
    })
      .then(response => response.json())
      .then(res => {
        if (res && res.message) {
          // eslint-disable-next-line no-throw-literal
          throw { code: res.message };
        }
        if (res && res.isExist === false) {
          // eslint-disable-next-line no-throw-literal
          throw { code: <FormattedMessage {...messages.codeIsInvalid} /> };
        }
      });
  });
