import {
  GET_AUTHOR,
  GET_AUTHOR_SUCCESS,
  GET_AUTHOR_FAIL,
  RESET_AUTHOR,
  SET_AUTHOR,
} from '../constants';

export default function editAuthor(
  state = { loading: false, author: {} },
  action,
) {
  switch (action.type) {
    case GET_AUTHOR:
      return {
        ...state,
        loading: true,
      };
    case GET_AUTHOR_SUCCESS: {
      return {
        ...state,
        author: action.result.json,
        loading: false,
      };
    }
    case GET_AUTHOR_FAIL:
      return {
        ...state,
        loading: false,
      };
    case RESET_AUTHOR:
      return {
        ...state,
        author: {},
      };
    case SET_AUTHOR:
      return {
        ...state,
        author: action.author,
      };
    default:
      return state;
  }
}
