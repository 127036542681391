import moment from 'moment';
import {
  EDIT_TASK__GET_TASK,
  EDIT_TASK__GET_TASK_SUCCESS,
  EDIT_TASK__GET_TASK_FAIL,
  RESET_TASK,
  SET_TASK_START_LOADING,
  SET_TASK_STOP_LOADING,
  SET_TASK,
} from '../constants';

const initialState = {
  loading: false,
  isCoping: false,
  task: {
    type: 'exam',
    assigneeType: 'mainGroup',
    startDate: moment().format('YYYY-MM-DDTHH:mm'),
    endDate: moment()
      .add(7, 'days')
      .format('YYYY-MM-DDTHH:mm'),
    pass: 80,
    maxQuizTries: 0,
    assignmentMaxScore: 100,
    assignmentMaximumChars: 1500,
    assignmentDeliverableType: 'text',
    reminder: 0,
  },
  error: null,
};

export default function editTask(state = initialState, action) {
  switch (action.type) {
    case EDIT_TASK__GET_TASK:
      return {
        ...state,
        loading: true,
      };
    case EDIT_TASK__GET_TASK_SUCCESS: {
      const task = action.result.json;

      if (task && task.startDate) {
        task.startDate = moment(task.startDate).format('YYYY-MM-DDTHH:mm');
      }
      if (task && task.endDate) {
        task.endDate = moment(task.endDate).format('YYYY-MM-DDTHH:mm');
      }
      return {
        ...state,
        task,
        loading: false,
      };
    }
    case EDIT_TASK__GET_TASK_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case SET_TASK: {
      const { task } = action;

      if (task && task.startDate) {
        task.startDate = moment(task.startDate).format('YYYY-MM-DDTHH:mm');
      }
      if (task && task.endDate) {
        task.endDate = moment(task.endDate).format('YYYY-MM-DDTHH:mm');
      }
      return {
        ...state,
        task,
      };
    }
    case SET_TASK_START_LOADING: {
      return {
        ...state,
        isCoping: true,
      };
    }
    case SET_TASK_STOP_LOADING: {
      return {
        ...state,
        isCoping: false,
      };
    }
    case RESET_TASK:
      return initialState;
    default:
      return state;
  }
}
