/* eslint-disable react/prop-types */

import React from 'react';
import debounce from 'debounce-promise';
// import PropTypes from 'prop-types';
// import { fieldInputPropTypes } from 'redux-form';
import withMuiStyles from '@material-ui/core/styles/withStyles';
import cx from 'classnames';

// import { ValueType } from 'react-select/src/types';
// import { SelectComponentsConfig } from 'react-select/src/components/index';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';

import AsyncSelect from 'react-select/async';
import {
  styles,
  Option,
  DropdownIndicator,
  Control,
  ValueContainer,
  SingleValue,
  MultiValue,
  NoOptionsMessage,
  Placeholder,
  Menu,
} from '../Autocomplete/Autocomplete';

export const messages = defineMessages({
  defaultTotalInputPlaceholder: {
    id: 'AsyncAutocomplete.defaultTotalInputPlaceholder',
    defaultMessage: 'Start typing to find {name}... ({total} {name}`s) ',
    description: 'Start typing to find... ({total} items) ',
  },
  noResultFound: {
    id: 'AsyncAutocomplete.noResultFound',
    defaultMessage: 'No results found',
    description: 'No results found displayed in async autocomplete form',
  },
  selectLabel: {
    id: 'AsyncAutocomplete.selectLabel',
    defaultMessage: 'Select ...',
    description:
      'Select ... displayed in async autocomplete form to select item',
  },
  itemsLabel: {
    id: 'AsyncAutocomplete.itemsLabel',
    defaultMessage: ' ({items} items)',
    description:
      '"Items" label displayed in async autocomplete form right after amount of total items',
  },
  startTypingNameHere: {
    id: 'AsyncAutocomplete.startTypingNameHere',
    defaultMessage: 'Start typing name here...',
    description:
      '"Start typing name here" label displayed in async autocomplete as default placeholder',
  },
  loading: {
    id: 'AsyncAutocomplete.loading',
    defaultMessage: 'Loading...',
  },
});

const noOptionsMessage = () => <FormattedMessage {...messages.noResultFound} />;

@withMuiStyles(styles)
@injectIntl
export default class AsyncAutocomplete extends React.Component {
  static defaultProps = {
    debounceInterval: 300,
  };

  // static propTypes = {
  //   changeSearchField: PropTypes.func,
  //   margin: PropTypes.oneOf('none', 'normal'),
  //   placeholder: PropTypes.oneOfType(PropTypes.node, PropTypes.string),
  //   inputPlaceholder: PropTypes.oneOfType(PropTypes.node, PropTypes.string),
  //   styles: PropTypes.shape({}),
  //   components: PropTypes.shape({}),
  //   value: PropTypes.shape({}),
  //   // components: SelectComponentsConfig,
  //   // ...Props,
  //   ...StyledComponentProps,
  //   ...fieldInputPropTypes,
  // };

  // static defaultProps = {
  //   changeSearchField: () => null,
  //   value: null,
  //   margin: 'none',
  //   placeholder: <FormattedMessage {...messages.selectLabel} />,
  //   inputPlaceholder: '',
  //   styles: {},
  //   components: {},
  // };

  constructor(props) {
    super(props);

    const { loadOptions = () => null, debounceInterval } = props;

    if (!debounceInterval) {
      this.loadOptionsDebounced = loadOptions;
      return;
    }

    this.loadOptionsDebounced = debounce(loadOptions, debounceInterval);
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.input.value && this.props.input.value) {
      this.loadOptionsDebounced('');
    }
  }

  onInputChange = value => {
    if (!value && this.props.searchValue) {
      this.loadOptionsDebounced('');
    }

    if (this.props.changeSearchField) {
      this.props.changeSearchField(value);
    }
    return value;
  };

  render() {
    const {
      id,
      classes = {},
      input,
      beforeChange,
      margin = 'none',
      placeholder = <FormattedMessage {...messages.selectLabel} />,
      inputProps = {},
      InputProps = {},
      inputPlaceholder = '',
      InputLabelProps = {},
      meta: { error, touched },
      helperText = '',
      loadOptions = () => null, // eslint-disable-line no-unused-vars
      debounceInterval, // eslint-disable-line no-unused-vars
      searchValue = '',
      components = {},
      loadOptionsTotal = 0,
      intl: { formatMessage },
      noneDefaultPlaceholder = false,
      textFieldProps = {},
      ...other
    } = this.props;

    let placeholderVal = inputPlaceholder;
    if (!noneDefaultPlaceholder) {
      const defaultPlaceholder =
        inputPlaceholder || formatMessage(messages.startTypingNameHere);

      placeholderVal = `${defaultPlaceholder} ${formatMessage(
        messages.itemsLabel,
        {
          items: loadOptionsTotal,
        },
      )}`;
    }

    return (
      <div className={cx(classes.root, 'react-select__root')}>
        <AsyncSelect
          loadOptions={this.loadOptionsDebounced}
          classes={classes}
          placeholder={placeholderVal}
          inputValue={searchValue}
          onInputChange={this.onInputChange}
          noOptionsMessage={noOptionsMessage}
          // ref={asyncRef}
          value={input.value}
          onChange={(value, event) => {
            if (beforeChange) {
              const newValue = beforeChange(value ?? [], event);
              input.onChange(newValue);
              input.onBlur(newValue);
              return;
            }
            input.onChange(value ?? []);
            input.onBlur(value ?? []);
          }}
          onBlur={event => event.preventDefault()}
          textFieldProps={{
            label: placeholder,
            helperText: (touched && error) || helperText,
            error: !!(touched && error),
            InputLabelProps: {
              shrink: true,
              ...InputLabelProps,
            },
            margin,
            ...textFieldProps,
          }}
          InputProps={{
            id,
            ...InputProps,
          }}
          inputProps={inputProps}
          maxMenuHeight={250}
          components={{
            DropdownIndicator,
            IndicatorSeparator: () => null,
            Option,
            Control,
            NoOptionsMessage,
            Placeholder,
            SingleValue,
            MultiValue,
            ValueContainer,
            Menu,
            ...components,
          }}
          styles={{
            input: base => ({
              ...base,
              color: '#fff',
            }),
            menu: base => ({
              ...base,
              borderRadius: 0,
            }),
            placeholder: base => ({
              ...base,
              color: '#fff',
            }),
            singleValue: base => ({
              ...base,
              color: '#fff',
            }),
            valueContainer: base => ({
              ...base,
              color: '#fff',
            }),
            ...this.props.styles,
          }}
          loadingMessage={() => formatMessage(messages.loading)}
          margin
          {...other}
        />
      </div>
    );
  }
}
