import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import MUITextField from '@material-ui/core/TextField';

const TextField = ({ input, meta: { touched, invalid, error }, ...custom }) => {
  const handleBlur = useCallback(
    e => {
      const normalizedValue = e.target.value.normalize('NFC');
      input.onBlur(normalizedValue);
    },
    [input.onBlur],
  );

  return (
    <MUITextField
      {...input}
      {...custom}
      error={touched && invalid}
      helperText={(touched && error) || custom.helperText}
      onBlur={handleBlur}
    />
  );
};

TextField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    invalid: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  }).isRequired,
};

export default TextField;
