import {
  VIEW_QUIZ__GET_QUIZ,
  VIEW_QUIZ__GET_QUIZ_SUCCESS,
  VIEW_QUIZ__GET_QUIZ_FAIL,
  START_QUIZ__GET_QUIZ,
  START_QUIZ__GET_QUIZ_SUCCESS,
  START_QUIZ__GET_QUIZ_FAIL,
  RESET_QUIZ,
} from '../constants';

const initialState = { loading: false, quiz: {} };

export default function viewQuiz(state = initialState, action) {
  switch (action.type) {
    case VIEW_QUIZ__GET_QUIZ:
      return {
        ...state,
        loading: true,
      };
    case VIEW_QUIZ__GET_QUIZ_SUCCESS:
      return {
        ...state,
        quiz: action.result.json,
        loading: false,
      };
    case VIEW_QUIZ__GET_QUIZ_FAIL:
      return {
        ...state,
        loading: false,
      };
    case START_QUIZ__GET_QUIZ:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case START_QUIZ__GET_QUIZ_SUCCESS:
      return {
        ...state,
        quiz: action.result.json,
        loading: false,
      };
    case START_QUIZ__GET_QUIZ_FAIL:
      return {
        ...state,
        error: action.error,
        quiz: initialState.quiz,
        loading: false,
      };
    case RESET_QUIZ:
      return initialState;
    default:
      return state;
  }
}
